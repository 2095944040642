import type { lightType } from "@/global/types";
import { useEditStore } from "@/stores/edit-store";

class ZoneSmlTypeLight{
  consumpPower?: number;
  lightModeCode?: string;
  lightQty?: number;
  lightRate?: number;
  lightSeq = 0;
  lightTypeCode?: string;
  simulTypeCode?: string;
  siteSeq?: number;
  standbyPower?: number;
  useEndTime?: string;
  useStartTime?: string;
  zoneSeq?: number;

  constructor(){
    this.siteSeq = useEditStore().selectSpaceInfo?.siteSeq;
    this.zoneSeq = useEditStore().selectSpaceInfo?.zoneSeq;
  }
}

export default ZoneSmlTypeLight;
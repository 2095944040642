<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useEditStore } from "@/stores/edit-store";
import { ViewType } from "@/global/enums";
import * as THREE from "three";
import type { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const editStore = useEditStore();

const object = computed(() => {
  return editStore.selectModelInfo as THREE.Object3D;
});

const camera = computed(() => {
  return editStore.THREECamera as THREE.PerspectiveCamera;
});

const controls = computed(() => {
  return editStore.CameraControl as OrbitControls;
});

const box3 = computed(() => {
  return new THREE.Box3().setFromObject(object.value);
});
const vector = computed(() => {
  return box3.value.getCenter(new THREE.Vector3());
});
const sizeData = computed(() => {
  return box3.value.getSize(new THREE.Vector3()).length();
});



function setViewType(type: ViewType){
  editStore.setViewType(type);
}

function TopView(){
  controls.value.reset();
  camera.value.rotation.set(0, 0, 0);
  camera.value.position.set(0, sizeData.value / 2, 0);
  camera.value.lookAt(vector.value);
  camera.value.updateProjectionMatrix();
}

function bottomView(){
  controls.value.reset();
  camera.value.rotation.set(0, 0, 0);
  camera.value.position.set(0, -sizeData.value / 2, 0);
  camera.value.lookAt(vector.value);
  camera.value.updateProjectionMatrix();

}

function frontView(){
  controls.value.reset();
  camera.value.rotation.set(0, 0, 0);
  camera.value.position.set(0, 0, sizeData.value / 2);
  camera.value.lookAt(vector.value);
  camera.value.updateProjectionMatrix();

}

function backView(){
  controls.value.reset();
  camera.value.rotation.set(0, 0, 0);
  camera.value.position.set(0, 0, -sizeData.value / 2);
  camera.value.lookAt(vector.value);
  camera.value.updateProjectionMatrix();

}

function leftView(){
  controls.value.reset();
  camera.value.rotation.set(0, 0, 0);
  camera.value.position.set(-sizeData.value, 0, 0);
  camera.value.lookAt(vector.value);
  camera.value.updateProjectionMatrix();
}

function rightView(){
  controls.value.reset();
  camera.value.rotation.set(0, 0, 0);
  camera.value.position.set(sizeData.value / 2, 0, 0);
  camera.value.lookAt(vector.value);
  camera.value.updateProjectionMatrix();
}
</script>

<template>
  <div class="FooterComponent">
    <q-bar class="ContentFooter">
      <q-btn @click="setViewType(ViewType.SecondDimension)">
        <q-icon :name="'img:ico_2D.png'" />
        <label>2D</label>
      </q-btn>
      <q-btn @click="setViewType(ViewType.ThirdDimension)">
        <q-icon :name="'img:ico_3D.png'" />
        <label>3D</label>
      </q-btn>

      <!-- <q-space />

      <div class="Footer">
        <q-btn @click="editStore.btnZoomIn">
          <q-icon name="zoom_in" color="grey"></q-icon>
        </q-btn>
        <q-btn @click="editStore.btnZoomOut">
          <q-icon name="zoom_out" color="grey"></q-icon>
        </q-btn>
      </div>
     -->
<!-- 

      <q-btn dense flat size="15px" @click="editStore.btnZoomIn" icon="zoom_in" :dark="false" color="dark"></q-btn>
      <q-btn dense flat size="15px" @click="editStore.btnZoomOut" icon="zoom_out" :dark="false" color="dark"></q-btn> -->

      <q-space />

      <div class="Footer">
        <q-btn @click="frontView">
          <q-icon :name="'img:ico_front.png'" />
        </q-btn>
        <q-btn @click="leftView">
          <q-icon :name="'img:ico_left.png'" />
        </q-btn>
        <q-btn @click="backView">
          <q-icon :name="'img:ico_back.png'" />
        </q-btn>
        <q-btn @click="rightView">
          <q-icon :name="'img:ico_right.png'" />
        </q-btn>
        <q-btn @click="TopView">
          <q-icon :name="'img:ico_top.png'" />
        </q-btn>
        <q-btn @click="bottomView">
          <q-icon :name="'img:ico_bottom.png'" />
        </q-btn>
      </div>
    </q-bar>
  </div>
</template>

<style lang="scss">
.FooterComponent {
  .q-bar--standard {
    padding: 0 0;
    height: 30px;
  }

  .q-btn--rectangle {
    border-radius: 0px;
  }

  .ContentFooter {
    height: 30px;
    background: #f2f4fb;
    border-right: 1px solid #c1c1c1;

    .q-btn {
      height: 30px;
      padding: 0;
      width: 80px;
      padding-right: 5px;
    }
    .q-icon {
      height: 30px;
      width: 30px;
    }

    label {
      color: #404040;
      font-size: 14px;
      margin-top: 2px;
    }
  }

  .ContentFooter .Footer {
    .q-btn {
      border-right: 0px;
      width: 30px;
      padding: 0px;
      height: 30px;
    }
  }
}
</style>

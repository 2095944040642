<script setup lang="ts">
import { computed, ref, type PropType, onUpdated, nextTick, onBeforeMount } from "vue";
import type { SimulationLedParameter, SimulationTableDataForm, SimulationChartDataType } from "@/global/types";
import type { ApexOptions } from "apexcharts";
import type ApexCharts from "apexcharts";
import type { QTableColumn } from "quasar";
import CommonUtils from "@/utils/common-util";

const props = defineProps({
  chartData: Array<SimulationChartDataType>,
  mbeData: Number,
  cvrmseData: Number,
  diffData: Array<number>,
  allData: Array<number>,
  label: Array<string>,
  colorValue: Array<string>
});
    
const simulationChart = ref({} as ApexCharts);

const chartLabel = computed(() => {
  const result = [] as string[];

  for(let i = 0; i < (props.label as string[]).length; i++){
    if((props.label as string[])[i] !== "합계") result.push((props.label as string[])[i] as string);
  }

  return result;
});


defineExpose({simulationChart});

const chartData = computed(() => {
  return props.chartData;
});



console.log(props.chartData);

// onUpdated(() => {
//   nextTick(() => {
//     updateChart(chartData.value as SimulationChartDataType[]);
//   });
// });

const chartOption = computed(() => {
  return {
    colors: props.colorValue,
    chart: {
      type: "bar",
      height: 350,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    title: {
      text: "월별 조명 에너지 소요량[kWh]",
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#404040"
      }
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetY: -30,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["fff"]
    },
    xaxis: {
      categories: chartLabel.value
    },
    fill: {
      opacity: 1
    }
  };
});
                          
const column = computed(() => {
  return tableColumn();
  // return tmpColumnData as QTableColumn[];
});
const tableData = computed(() => {
  if(props.diffData !== undefined && props.allData !== undefined){
    return tableDataSet();
  }
  return [];
  // return tmpTableData;
});

console.log(chartData, chartOption, column, tableData);
                       
function tableColumn(){
  const column = [] as QTableColumn[];
                                                                      
  column.push({
    name: "구분",
    label: "구분",
    align: "center",
    field: "구분",
                                                                        
  });
  for(let i = 0; i < (props.label as string[]).length; i++){
    column.push({
      name: (props.label as string[])[i] as string,
      label: (props.label as string[])[i] as string,
      align: "center",
      field: (props.label as string[])[i] as string,
    });
  }
                                                                      
                                                                      
  return column;
}
                                                                    
function tableDataSet(){
  const tableData = [] as SimulationTableDataForm[];
                                                                      
  if(chartData.value !== undefined){
    for(let i = 0; i < chartData.value.length; i++){
      tableData.push({"구분": chartData.value[i]?.name});
      for(let j = 1; j < column.value.length; j++){
        tableData[i][column.value[j].field as string] = chartData.value[i]?.data[j-1]?.toFixed(2);
      }  
    }
  }
                                                                      
  const diff = {} as {[x:string] : string | number};
  for(let i = 0; i < column.value.length; i++){
    diff[column.value[i].field as string] = (props.diffData as number[])[i-1]?.toFixed(2);
  }
                                                                      
  diff["구분"] = "차이";
                                                                      
  tableData.push(diff);
                                                                      
  for(let i = 0; i < tableData.length; i++){
    tableData[i]["합계"] = (props.allData as number[])[i]?.toFixed(2);
  }
                                                                      
  return tableData;                                                                 
}
                                                                    
                                                                    
// const series = ref([{
//   name: "Net Profit",
//   data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
// }, {
//   name: "Revenue",
//   data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
// }, {
//   name: "Free Cash Flow",
//   data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
// }]);
                                                                          
// const chartOptions = ref({
//   chart: {
//     type: "bar",
//     height: 350
//   },
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: "55%",
//       endingShape: "rounded"
//     },
//   },
//   dataLabels: {
//     enabled: false
//   },
//   stroke: {
//     show: true,
//     width: 2,
//     colors: ["transparent"]
//   },
//   xaxis: {
//     categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
//   },
//   yaxis: {
//     title: {
//       text: "$ (thousands)"
//     }
//   },
//   fill: {
//     opacity: 1
//   },
//   tooltip: {
//     y: {
//       formatter: function (val: string) {
//         return "$ " + val + " thousands";
//       }
//     }
//   }
// });

                                                                                               
</script>
                                                                                                  
<template>

  <div class="SimulationGraphComponent" v-if="chartData && chartData.length">
    <div class="ChartArea">
      <apexchart ref="simulationChart" type="bar" height="350" :options="chartOption" :series="chartData"></apexchart>
      <!-- <div v-if="chartData.length <= 0" class="EmptyChart">
          데이터가 존재하지 않습니다.
      </div> -->
    </div>
    <q-separator class="q-mb-md" :dark="false" size="1px" color="#c1c1c1"></q-separator>
    <div class="tableArea">
      <q-table 
        v-if="tableData.length > 0" 
        row-key="name" :columns="column" 
        :rows="tableData" hide-pagination 
        :dark="false" 
        separator="cell" 
        square 
        table-class="graphTable" 
        table-header-class="graphTableHeader" 
        dense
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td class="graphTableTD" v-for="data in Object.keys(props.row)" :key="`${data}`" :props="props" :style="{backgroundColor: data !== '구분' ? 'transparent' : '#f2f4fb', color: data === '구분' ? '#000000' : 'black', fontSize: '12px'}">
              {{ props.row[data] }}
            </q-td>
          </q-tr>
          
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="1" class="bottomRowTd subjectTd">
              MBE
            </q-td>
            <q-td :colspan="Math.ceil((column.length - 1) / 2)" class="bottomRowTd contentTd">
              {{props.mbeData?.toFixed(2)}}
            </q-td>
            <q-td colspan="1" class="bottomRowTd subjectTd">
              CVRMSE
            </q-td>
            <q-td :colspan="Math.floor((column.length - 1) / 2)" class="bottomRowTd contentTd">
              {{props.cvrmseData?.toFixed(2)}}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<style lang="scss">
.SimulationGraphComponent{
  padding: 5px 0px 5px 0px;
  .EmptyChart{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    background-color: #40404033;
  }
  .tableArea{

    .graphTable{
      font-size: 10px;
    }
    
    .graphTableHeader{
      background-color: #f2f4fb;
      color: #404040;   
      th:first-child{
        padding: 4px 8px;
      } 
      th:last-child{
        padding: 4px 8px;
      }
    }
    .graphTableTD{
      padding: 4px 8px !important;
    }
    .bottomRowTd{
      text-align: center;

    }
    
    .subjectTd{
      background-color: #f2f4fb;
      color: #404040;

    }
    
    .contentTd{
      color: #404040;
    }

  }
}
</style>
import { defineStore } from "pinia";
import Menu from "@/models/menu";

import CommonUtils from "@/utils/common-util";

import menuObj from "@/settings/menu.json";
import topbarMenuObj from "@/settings/topbar-menu.json";
import { Depth2_Kind, ZoneTypeCode } from "@/global/enums";
import type { MenuKind, } from "@/global/types";
import { useEditStore } from "./edit-store";
import type Zone from "@/models/entity/zone";
import { useSceneStore } from "./scene-store";
import type CtrlGrp from "@/models/entity/ctrl-grp";
import router from "@/router";

export const useMenuStore = defineStore("menuStore", {
  state: () => ({
    //selectedMainMenu: null as (Menu | null),
    isMainLeftOn: false as boolean,
    selectedMenu: null as (Menu | null),
    isLightSet: false as boolean,
    lastSelectedTopBarMenu: null as (Menu | null),
    menuList: CommonUtils.jsonDeserialize(Menu, JSON.stringify(menuObj, null, 2)) as Menu[],
    topbarList: CommonUtils.jsonDeserialize(Menu, JSON.stringify(topbarMenuObj, null, 2)) as Menu[],
    selectIconList: [
      {type: "BRIDGE", path: "/ico_tree_대표공간_bridge.png"},
      {type: "BUILDING", path: "/ico_tree_대표공간_building.png"},
      {type: "PARK", path: "/ico_tree_대표공간_park.png"},
      {type: "ROAD", path: "/ico_tree_대표공간_road.png"},
      {type: "TUNNEL", path: "/ico_tree_대표공간_tunnel.png"},
      {type: "CZone", path: "/ico_tree_묶음공간.png"},
      {type: "UZone", path : "/ico_tree_단위공간.png"}
    ]
  }),
  getters: {
    getMenuList(state) {
      return state.menuList as Menu[];
    },    
    getMenu() {
      return (menuKind: MenuKind, menuList?: Menu[]): Menu | null => {
        let findMenu: Menu | null = null;

        if (CommonUtils.isNullOrEmpty(menuList))
          menuList = this.getMenuList;

        for (const menu of menuList) {
          if (menu.menuKind === menuKind) {     
            findMenu = menu;
            break;
          } else {
            findMenu = this.getMenu(menuKind, menu.childMenu);

            if (findMenu !== null) {
              break;
            }
          }
        }

        return findMenu;
      };
    },
    getRootMenu() {
      return (menuKind: MenuKind, menuList: Menu[], index = 0): Menu | null => {
        let findMenu: Menu | null = null;

        for (const menu of menuList) {
          if (menu.menuKind === menuKind) {
            findMenu = menu;
            break;
          } else {
            findMenu = this.getRootMenu(menuKind, menu.childMenu, index++);
            index--;

            if(findMenu !== null && index === 0)
              return menu;            
          } 
        }

        return findMenu;
      };
    },
    getStorageMenu() {
      return () => this.persistStateManager.getMenu();
    }
  },

  actions: {
    setLightSetTrigger(){
      this.isLightSet = !this.isLightSet;
    },

    toggleMainLeft(){
      this.isMainLeftOn = !this.isMainLeftOn;
    },
    findZoneIconPath(zone: Zone) {

      let typeCode = zone.zoneTypeCode;

      if(typeCode === ZoneTypeCode.대표존)
        typeCode = zone.rzone?.rzoneTypeCode ?? "";      
      else if (typeCode === ZoneTypeCode.묶음존)
        typeCode = "CZone";
      else if (typeCode === ZoneTypeCode.단위존)
        typeCode = "UZone";      

      return this.selectIconList.find(v => v.type === typeCode)?.path ?? "";     
    },
    selectionMenu(menuKind: MenuKind) {

      const editStore = useEditStore();
      let menu = this.getMenu(menuKind);

      try {

        if (CommonUtils.isNullOrEmpty(menu))
          return;

        this.deSelectionAllTopBarSubMenu();

        if (menu.childMenu.length > 0)
          return;

        this.setMenuStateSelect(menuKind, this.menuList as Menu[]);
        this.selectedMenu = menu;
        this.persistStateManager.setMenu(menu);

        if (menuKind === Depth2_Kind.조명배치) {
          useEditStore().resetDpointNode(useEditStore().selectGroupInfo as CtrlGrp);
        } else if (menuKind === Depth2_Kind.그룹) {
          useEditStore().resetColor();
        } else if (menuKind === Depth2_Kind.씬) {
          useSceneStore().setSelectSceneInfo(null);
        }

        if (menu.childMenu.length > 0)
          return;

        menu = this.getRootMenu(menuKind, this.menuList as Menu[], 0);
      } finally {

        //Undo, Redo로 인하여 메뉴가 변경되어야 하므로 router push 코드는 코드는 Store에 둠.
        if (!CommonUtils.isNullOrEmpty(menu)) {

          editStore.snapShot.menuKind = menuKind ?? null;
          router.push({ name: menu.path });                    
          console.log(`★★ menuKind => ${menuKind}, selectedMenuKind => ${this.selectedMenu?.menuKind}`);
        }        
      }
    },

    setMenuStateSelect(menuKind: MenuKind, menuList: Menu[]): boolean {
      let isFind = false;
      for (const menu of menuList) {        
        if (menu.menuKind === menuKind) {          
          isFind = true;
          menu.isSelected = true;  
        } else {
          if (menu.childMenu.length > 0) {
            const isSelected = this.setMenuStateSelect(menuKind, menu.childMenu);
            menu.isSelected = isSelected;
          } else {
            menu.isSelected = false;
          }          
        }
      }
      return isFind;
    },

    selectionTopBarSubMenu(topbarSubMenu: Menu) {
      for (const topbarMenu of this.topbarList) {
        for (const topbarChildMenu of topbarMenu.childMenu) {
          if (topbarSubMenu.menuKind === topbarChildMenu.menuKind) {            
            topbarChildMenu.isSelected = topbarSubMenu.isUseToggle ? !topbarChildMenu.isSelected : false;
          } else topbarChildMenu.isSelected = false;
        }
      }

      this.lastSelectedTopBarMenu = topbarSubMenu;
    },


    deSelectionAllTopBarSubMenu() {
      for (const topbarMenu of this.topbarList) {

        for (const topbarChildMenu of topbarMenu.childMenu) {
          topbarChildMenu.isSelected = false;
        }
      }

      this.lastSelectedTopBarMenu = null;
    }
  },
});

import { useEditStore } from "@/stores/edit-store";
import type ZoneSmlTypeLight from "./zone-sml-type-light";



class LightSet{
  zoneSeq: number;
  siteSeq: number;
  lightSetSeq: string;
  lightSetName: string;
  width: number;
  height: number;
  x: number;
  y: number;
  type?: string;
  lightList?: ZoneSmlTypeLight[];
  
  constructor(
    lightSetSeq: string,
    lightSetName: string,
    width: number,
    height: number,
    x: number,
    y: number,
    zoneSeq?: number | undefined,
    siteSeq?: number | undefined
  ){
    this.lightSetSeq = lightSetSeq;
    this.lightSetName = lightSetName;
    this.width = width;
    this.height = height;
    this.x = x;
    this.y = y;
    this.lightList = [];
    this.zoneSeq = zoneSeq ? zoneSeq : useEditStore().selectSpaceInfo?.zoneSeq as number;
    this.siteSeq = siteSeq ? siteSeq : useEditStore().selectSpaceInfo?.siteSeq as number;
  }
}  

export default LightSet;
<script setup lang="ts">
import { onMounted, computed, watchEffect, reactive, onUnmounted, ref } from "vue";
import * as d3 from "d3";
import draggable from "vuedraggable";
import { EditorMode, NodeStatus, Direction, NodeEventType } from "@/global/enums";
import type { DragBoxInfo, EditorInfo } from "@/models/editor/editor-setting";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info";
import CommonUtils from "@/utils/common-util";
import type PortInfo from "@/models/editor/nodes/info/port-info";
import type { LinkPoint } from "@/global/types";
import { path } from "d3";
import DrawUtil from "@/utils/draw-util";
import LinkInfo from "@/models/editor/nodes/info/link-info";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useEditStore } from "@/stores/edit-store";
//import { useMouse } from "@/composables/events/node-mouse-events";

//const { x, y } = useMouse();

//useNodeMouseEventListener(window, "mousemove",);

const editStore = useEditStore();

/**
 * 기본값 X Props
 */
const props = defineProps<{
  modelValue: EditorInfo;
  drawBoxSelectValidator?: (() => boolean);
  deleteValidator?: ((nodeIds: string[]) => boolean);  
}>();

//defineEmits(["update:modelValue"]);

const emitData = defineEmits<{
  (eventName: "update:modelValue"): void;
  (eventName: "dragBoxMouseMove", event: MouseEvent, dragBoxInfo: DragBoxInfo): void;
  (eventName: "dragBoxMouseUp", event: MouseEvent, dragBoxInfo: DragBoxInfo): void;
  (eventName: "editorKeyEvent", event: KeyboardEvent): void;
  (eventName: "nodesEvent", nodeEventType: NodeEventType, nodeIds: string[] | null, nodeInfos: AbstractNodeInfo[] | null, event?: DragEvent): void;
  (eventName: "canvasMouseDown", event: MouseEvent): void;
  (eventName: "canvasMouseUp", event: MouseEvent): void;
}>();

const svgItems = [1];
const editorInfo = reactive(props.modelValue);
//const { editorInfo } = storeToRefs(nodeEditorStore);

const drawLinkInfos = reactive<LinkInfo[]>([]);
const nodeEditorStore = useNodeEditorStore();

let isCanvasFocus = false;

/**
 * 그리드 수 계산
 * @param isMajor 메이저 라인 여부
 * @param isHorizontal 가로 라인 여부
 */
const gridTicks = (isMajor: boolean, isHorizontal: boolean) =>
  computed(() => {
    const ticks = new Array<number>();

    console.log(
      `isHorizontal -> ${isHorizontal}, props.majorGridSize: ${editorInfo.gridSetting.majorGridSize}, props.minorGridSize: ${editorInfo.gridSetting.minorGridSize}`
    );

    if (editorInfo.gridSetting.majorGridSize <= 0) return ticks;

    const size = isHorizontal ? editorInfo.gridSetting.pageHeight : editorInfo.gridSetting.pageWidth;
    const gridSize = isMajor ? editorInfo.gridSetting.majorGridSize : editorInfo.gridSetting.minorGridSize;

    for (let i = 0; i <= size; i += +gridSize) {
      ticks.push(i);
    }
    return ticks;
  });

/**
 * Mounted
 */
onMounted(() => {
  //강제 렌더링
  // const instance = getCurrentInstance();
  // instance?.proxy?.$forceUpdate();

  nodeEditorStore.setEditorInfo(props.modelValue);
  editStore.setEditorInfo(props.modelValue);

  watchEffect(() => {
    if (!props) return;

    drawGrid();
    console.log(`Props.majorGridSize: ${editorInfo.gridSetting.majorGridSize}`);
  });

  window.addEventListener("keydown", keydown);
});

onUnmounted(() => {
  console.log("onUnmounted~~~~!!!");
  window.removeEventListener("keydown", keydown);
});



/**
 * 그리드 그리는 함수
 */
function drawGrid(): void {
  const grid = d3.select<SVGGElement, undefined>("#grid");

  //Clear 그리드
  grid.selectAll("*").remove();

  //Major Grid 생성
  drawGridLine(true, true);
  drawGridLine(true, false);

  //Minor Grid 생성
  drawGridLine(false, true);
  drawGridLine(false, false);

  console.log("Do drawGrid");
}

/**
 * 그리드 라인 그리는 함수
 * @param isMajor
 * @param isHorizontal
 */
function drawGridLine(isMajor: boolean, isHorizontal: boolean): void {
  const grid = d3.select<SVGGElement, undefined>("#grid");
  const ticks = gridTicks(isMajor, isHorizontal).value;

  console.log(`tick len: ${ticks.length}`);

  for (let i = 0; i < ticks.length; i++) {
    const tick = ticks[i];

    const strokeSize = isMajor ? editorInfo.gridSetting.majorGridStrokeSize : editorInfo.gridSetting.minorGridStrokeSize;
    const strokeColor = isMajor ? editorInfo.gridSetting.majorGridStrokeColor : editorInfo.gridSetting.minorGridStrokeColor;

    const line = d3
      .create<SVGLineElement>("svg:line")
      .attr("class", "grid")
      .attr("fill", "node")
      .attr("shape-rendering", "crispEdges")
      .attr("stroke", strokeColor)
      .attr("stroke-opacity", 0.3)
      .attr("stroke-width", `${strokeSize}px`);

    if (isHorizontal) {
      line.attr("x1", 0).attr("x2", editorInfo.gridSetting.pageWidth).attr("y1", tick).attr("y2", tick);
    } else {
      line.attr("x1", tick).attr("x2", tick).attr("y1", 0).attr("y2", editorInfo.gridSetting.pageHeight);
    }

    grid.append(() => line.node());
  }
}

/**
 * 아이템 이동
 * @param event 드래그 이벤트
 */
function onDragMove(event: DragEvent) {
  //console.log(`onDragMove -> clientX: ${event.clientX}, clientY:${event.clientY}, x: ${event.offsetX}, y: ${event.offsetY}`);
}

/**
 * 노드 마우스 Down 이벤트
 * @param event 마우스 이벤트
 * @param id 노드 아이디
 */
function onNodeMouseDown(event: MouseEvent, id: string) {

  try {

    editorInfo.editorMode = EditorMode.ReadyMove;

    //선택된 노드 리스트를 가져온다.
    const selectedNodes = nodeEditorStore.getSelectedNodes(false);

    const node = nodeEditorStore.getNode(id);

    if (CommonUtils.isNullOrEmpty(node))
      return;

    if (event.ctrlKey) {
      if (node.nodeStatus === NodeStatus.Normal)
        node.startPositioning(editorInfo.scale, editorInfo.snap, event);
      else
        node.endPositioning();

      return;
    }

    //선택된 노드 리스트에서 현재 마우스 Down한 노드가 포함된 경우
    if (selectedNodes.some((n) => n.id === id)) {
      for (const n of selectedNodes) {
        n.startPositioning(editorInfo.scale, editorInfo.snap, event);
      }
    } else {
      //선택된 노드 선택 해제
      for (const n of selectedNodes) {
        n.endPositioning();
      }

      node.startPositioning(editorInfo.scale, editorInfo.snap, event);
    }
    nodeEditorStore.setSelectionNode(id);
  } finally {
    onFocusIn();
    event.preventDefault();
    event.stopPropagation();
  }
}

/**
 * 노드 마우스 Up 이벤트
 * @param event 마우스이벤트
 * @param _id 노드 아이디
 */
function onNodeMouseUp(event: MouseEvent, _id: string) {
  try {
    console.log("onNodeMouseUp ~~~~~");

    if (editorInfo.editorMode === EditorMode.ObjectMoving) {
      const nodes = nodeEditorStore.getNodes;
      for (const node of nodes) {
        node.endPositioning(false);
      }

      const commitedNodeIds = nodes.map(n => n.id);
      emitData("nodesEvent", NodeEventType.Moved, commitedNodeIds, null);
    }
    //nodeEditorStore.setSelectionNode(null);

  } finally {    

    editorInfo.editorMode = EditorMode.None;
    event.preventDefault();
    event.stopPropagation();
  }
}

/**
 * Canvas 마우스 Down 이벤트
 * @param event 마우스 이벤트
 */
function canvasMouseDown(event: MouseEvent) {
  try {

    console.log(`canvasMouseDown -> client.x: ${event.clientX}, client.y: ${event.clientY},  x: ${event.offsetX}, y: ${event.offsetY}`);
    if (editorInfo.editorMode === EditorMode.None) {
      editorInfo.dragBoxInfo.initPosition(editorInfo.scale, event);
      editorInfo.editorMode = EditorMode.DrawBoxMoving;
      nodeEditorStore.setSelectionNode(null);
    } else {
    }
  } finally {
    emitData("canvasMouseDown", event);
  }
}

/**
 * Canvas 마우스 Move 이벤트
 * @param event 마우스 이벤트
 */
function canvasMouseMove(event: MouseEvent) {
  try {
    
    // console.log(`canvasMouseMove -> offX: ${event.offsetX}, offY: ${event.offsetY}, pageX: ${event.pageX}, pageY: ${event.pageY}`);

    if (editorInfo.editorMode === EditorMode.ReadyMove) {

      if (CommonUtils.isNullOrEmpty(nodeEditorStore.selectionNodeInfo))
        return;
      
      const d = Math.abs(nodeEditorStore.selectionNodeInfo.dx - event.offsetX) + Math.abs(nodeEditorStore.selectionNodeInfo.dy - event.offsetY);

      // 노드 위치 대비 마우스 위치가 3px 보다 
      if (d > 3) {
        editorInfo.editorMode = EditorMode.ObjectMoving;
        return;
      }
    } else if (editorInfo.editorMode === EditorMode.DrawBoxMoving) {
      editorInfo.dragBoxInfo.updatePosition(editorInfo.scale, event);

      const dragBoxInfoClone = CommonUtils.deepClone(editorInfo.dragBoxInfo);
      emitData("dragBoxMouseMove", event, dragBoxInfoClone);
      return;
    } else if (editorInfo.editorMode === EditorMode.ObjectMoving) {
      for (const node of nodeEditorStore.getNodes) {
        if (node.nodeStatus !== NodeStatus.Selected)
          continue;

        node.updatePosition(editorInfo.scale, editorInfo.snap, event);
      }

      //연결 노드 업데이트
      nodeEditorStore.updateLinks();
    } else if (editorInfo.editorMode === EditorMode.Joining) {
      const startPortNode = nodeEditorStore.startPortNode as AbstractNodeInfo;
      const startPortInfo = nodeEditorStore.startPortInfo as PortInfo;

      if (startPortNode === null || startPortInfo === null)
        return;

      drawLink(event, startPortNode, startPortInfo, false);

      console.log(`PathString -> ${path?.toString()}`);

      // console.log(`drawDragpath Draw --> EndPort: ${this.endPort}`);
    }
  } finally {
    //emitData("canvasMouseMove", event);
    event.preventDefault();
    event.stopPropagation();
  }
}

/**
 * Canvas 마우스 Up 이벤트
 * @param event 마우스 이벤트
 */
function canvasMouseUp(event: MouseEvent) {
  

  try {
    console.log("canvasMouseUp ~~");
    if (editorInfo.editorMode === EditorMode.DrawBoxMoving) {
      //NOTE: drawBoxSelectValidator false 인 경우 처리 로직 (ex: 조명 자동 추가 할때 DrawBox 아래쪽 객체가 선택 되는것을 방지 )
      if (!CommonUtils.isNullOrEmpty(props.drawBoxSelectValidator)) {
        if (!props.drawBoxSelectValidator()) {
          const dragBoxInfoClone = CommonUtils.deepClone(editorInfo.dragBoxInfo);
          emitData("dragBoxMouseUp", event, dragBoxInfoClone);
          return;
        }
      }

      for (const node of nodeEditorStore.getNodes) {
        const isSelected =
          node.x > editorInfo.dragBoxInfo.x &&
          node.x < editorInfo.dragBoxInfo.x2 &&
          node.y > editorInfo.dragBoxInfo.y &&
          node.y < editorInfo.dragBoxInfo.y2;

        if (event.ctrlKey) {
          if (isSelected) {
            if (node.nodeStatus === NodeStatus.Normal) {
              node.startPositioning(editorInfo.scale, editorInfo.snap, event);
            }
            else node.endPositioning();
          }
        } else {
          if (isSelected === false) {
            node.endPositioning();
          } else {            
            node.startPositioning(editorInfo.scale, editorInfo.snap, event);
          }
        }
      }

      const dragBoxInfoClone = CommonUtils.deepClone(editorInfo.dragBoxInfo);
      emitData("dragBoxMouseUp", event, dragBoxInfoClone);
    } else if (editorInfo.editorMode === EditorMode.ObjectMoving) {

    } else if (editorInfo.editorMode === EditorMode.Joining) {
      clearLink();
    }    
  } finally {
    editorInfo.editorMode = EditorMode.None;
    emitData("canvasMouseUp", event);
  }
}

function onPortMouseDown(event: MouseEvent, nodeInfo: AbstractNodeInfo, portInfo: PortInfo) {
  try {
    // if(portInfo.portType === PortType.In)
    //   return;

    editorInfo.editorMode = EditorMode.Joining;
    nodeEditorStore.setStartPortData(nodeInfo, portInfo);
  } finally {
    //이벤트 동작 / 전파 중지
    event.preventDefault();
    event.stopPropagation();
  }
}

function onPortMouseUp(event: MouseEvent, nodeInfo: AbstractNodeInfo, portInfo: PortInfo) {
  try {
    console.log(`Port Mouse Up ~~~~~~~!!! ${JSON.stringify(nodeInfo, null, 2)}, ${JSON.stringify(portInfo, null, 2)}`);

    drawLink(event, nodeInfo, portInfo, true);
    clearLink();        
  } finally {
    //이벤트 동작 / 전파 중지
    event.preventDefault();
    event.stopPropagation();
  }
}

function onPortMouseOver(event: MouseEvent, _nodeInfo: AbstractNodeInfo, _portInfo: PortInfo) {
  try {
    console.log("Port Mouse Over ~~~~~~~!!!");
  } finally {
    //이벤트 동작 / 전파 중지
    event.preventDefault();
    event.stopPropagation();
  }
}

function onPortMouseOut(event: MouseEvent, _nodeInfo: AbstractNodeInfo, _portInfo: PortInfo) {
  try {
    console.log("Port Mouse Out ~~~~~~~!!!");
  } finally {
    //이벤트 동작 / 전파 중지
    event.preventDefault();
    event.stopPropagation();
  }
}

function drawLink(event: MouseEvent, nodeInfo: AbstractNodeInfo, portInfo: PortInfo, isEnd: boolean) {
  let startLinkPoint: LinkPoint | null = null;
  let endLinkPoint: LinkPoint | null = null;

  let startPortNode: AbstractNodeInfo | null = null;
  let endPortNode: AbstractNodeInfo | null = null;

  if (isEnd) {
    startPortNode = nodeEditorStore.startPortNode as AbstractNodeInfo | null;
    endPortNode = nodeInfo as AbstractNodeInfo | null;

    const startPortInfo = nodeEditorStore.startPortInfo;

    if (startPortNode === null || startPortInfo === null) return;

    startLinkPoint = DrawUtil.getLinkPoint(startPortNode, startPortInfo);
    endLinkPoint = DrawUtil.getLinkPoint(nodeInfo, portInfo);
  } else {
    startPortNode = nodeInfo;
    startLinkPoint = DrawUtil.getLinkPoint(nodeInfo, portInfo);
    endLinkPoint = {
      x: event.offsetX,
      y: event.offsetY,
    };
  }

  if (startLinkPoint === null || endLinkPoint === null) return;

  const x0 = startLinkPoint.x;
  const y0 = startLinkPoint.y;
  const x1 = endLinkPoint?.x;
  const y1 = endLinkPoint?.y;  

  const nodePath = DrawUtil.getNodePath(x0, y0, x1, y1);
  const linkInfo = new LinkInfo("", nodePath);

  if (isEnd) {
    drawLinkInfos.splice(0);

    if (endPortNode === null)
      return;

    const linkNodeInfo = nodeEditorStore.createLinkNode(linkInfo, startLinkPoint, endLinkPoint);
    if(CommonUtils.isNullOrEmpty(linkNodeInfo))
      return;

    emitData("nodesEvent", NodeEventType.Added, [linkNodeInfo.id], null);
    
  } else {
    if (drawLinkInfos.length > 0) {
      drawLinkInfos[0] = linkInfo;
    } else {
      drawLinkInfos.push(linkInfo);
    }
  }
}

function clearLink() {
  drawLinkInfos.splice(0);

  nodeEditorStore.setStartPortData(null, null);
  editorInfo.editorMode = EditorMode.None;
}

function keydown(event: KeyboardEvent) {

  if(!isCanvasFocus)
    return;

  console.log(`keydown event ---> code: ${event.code}, key: ${event.key}`);

  let isBubbling = false;

  if (event.ctrlKey) {
    const keyLower = event.key.toLowerCase();

    switch (keyLower) {
      case "a":
        nodeEditorStore.setSelectionAllNode();
        isBubbling = true;
        break;
      case "c":
        nodeEditorStore.copying();
        emitData("nodesEvent", NodeEventType.Copying, null, null);  
        break;
      case "v":
        nodeEditorStore.pasteNodes();        
        emitData("nodesEvent", NodeEventType.Paste, null, null); 
        break;
    }
  } else {

    let commitedNodeIds: string[] | null = null;

    switch (event.key) {
      case "Delete":

        // 삭제 Validator가 존재하면 수행
        let result = true;        

        if (!CommonUtils.isNullOrEmpty(props.deleteValidator)) {

          //선택한 노드 Id List를 넘겨준다.
          const nodeIds = nodeEditorStore.getSelectedNodes(false).map(n => n.id);
          result = props.deleteValidator(nodeIds);
        }

        if (!result)
          break;
                

        console.log(" :::: clearNodes :::::");

        const commitedNodeInfos = nodeEditorStore.clearNodes(true);

        //노드 삭제 이벤트는 삭제한 노드의 클론 노드를 이벤트 파라미터로 넘겨준다.
        emitData("nodesEvent", NodeEventType.Removed, [], commitedNodeInfos);
        break;
      case "ArrowLeft":
        commitedNodeIds = nodeEditorStore.moveSelectedNodes(Direction.Left);
        emitData("nodesEvent", NodeEventType.Moved, commitedNodeIds, null);        
        break;
      case "ArrowRight":
        commitedNodeIds = nodeEditorStore.moveSelectedNodes(Direction.Right);
        emitData("nodesEvent", NodeEventType.Moved, commitedNodeIds, null);        
        break;
      case "ArrowUp":
        commitedNodeIds = nodeEditorStore.moveSelectedNodes(Direction.Up);
        emitData("nodesEvent", NodeEventType.Moved, commitedNodeIds, null);        
        break;
      case "ArrowDown":
        commitedNodeIds = nodeEditorStore.moveSelectedNodes(Direction.Down);
        emitData("nodesEvent", NodeEventType.Moved, commitedNodeIds, null);
        break;
    }

    if ((commitedNodeIds?.length ??  0) > 0) 
      isBubbling = true;
  }

  emitData("editorKeyEvent", event);

  if (isBubbling === false)
    return;

  //이벤트 동작 중지
  event.preventDefault();

  //이벤트 전파 중지
  event.stopPropagation();
}

function onItemDrop(event: DragEvent) {
  const id = event.dataTransfer?.getData("selectedNodeConfigId");

  if(CommonUtils.isNullOrEmpty(id))
    return;
  
  emitData("nodesEvent", NodeEventType.Drop, [id], null, event);
}


function onFocusIn() {
  isCanvasFocus = true;
  console.log(`DrawCanvas FocusIn => ${isCanvasFocus}`);
  
}

function onFocusOut() {
  isCanvasFocus = false;
  console.log(`DrawCanvas FocusIn => ${isCanvasFocus}`);
}

</script>

<template>
  <div>
    <draggable v-model="svgItems" item-key="id" @dragover="onDragMove" draggable="false" dragoverBubble="false" setdata="id">
      <template #item="{}">        
        <svg
          :width="editorInfo.gridSetting.pageWidth * editorInfo.scale"
          :height="editorInfo.gridSetting.pageHeight * editorInfo.scale"
          pointer-events="all"
          @mousedown="canvasMouseDown"
          @mousemove="canvasMouseMove"
          @mouseup="canvasMouseUp"
          @drop="onItemDrop"
          @focusin="onFocusIn"
          @focusout="onFocusOut"
        >
          <g :transform="`scale(${editorInfo.scale})`">
            <g id="grid" class="grid" ref="grid"></g>

            <g id="dragGroup">
              <path v-for="(linkInfo, i) in drawLinkInfos" :key="i" fill="none" :d="linkInfo.d" class="drag_line" />
            </g>

            <g id="drawItemGroup">
              <!-- Node는 Slot을 이용하여 다양한 노드를 수용 -->
              <slot
                name="real"
                :onNodeMouseDown="onNodeMouseDown"
                :onNodeMouseUp="onNodeMouseUp"
                :onPortMouseDown="onPortMouseDown"
                :onPortMouseUp="onPortMouseUp"
                :onPortMouseOver="onPortMouseOver"
                :onPortMouseOut="onPortMouseOut"
              />
            </g>

            <g id="drawTempGroup">
              <slot name="temp" />
            </g>

            <g>
              <slot name="action" />
            </g>

            <!-- 빈영역에서 드래그로 노드를 포함하는 박스 -->
            <rect
              v-if="editorInfo.editorMode === EditorMode.DrawBoxMoving"
              class="dragBox"
              :ox="editorInfo.dragBoxInfo.ox"
              :oy="editorInfo.dragBoxInfo.oy"
              rx="1"
              ry="1"
              :x="editorInfo.dragBoxInfo.x"
              :y="editorInfo.dragBoxInfo.y"
              :width="editorInfo.dragBoxInfo.w"
              :height="editorInfo.dragBoxInfo.h"
            />
          </g>
        </svg>
      </template>
    </draggable>     
  </div>  
</template>

<style lang="scss">
//TODO: 변수는 FlowPort에도 지역적으로 사용중이니 추후 글로벌로 뺄것
$node-selected-color: #ff7f0e;

.q-input {
  margin-bottom: 10px;
}

.dragBox {
  stroke-width: 1px;
  stroke: #ff7f0e;
  fill: rgba(20, 125, 255, 0.1);
  stroke-dasharray: 10 5;
}

.drag_line {
  stroke: $node-selected-color;
  stroke-width: 3;
  fill: none;
  pointer-events: none;
}
</style>

import RestClient from "@/net/rest-client";
import UserApiService from "./user-api-service";
import SettingApiService from "./setting-api-service";
import ScenarioApiService from "./scenario-api-service";
import DeviceApiService from "./device-api-service";
import EtcApiService from "./etc-api-service";
import SiteZoneApiService from "./site-zone-api-service";
import SimulationApiService from "./simulation-api-service";
import UsageProfilesApiService from "./usage-profiles-api-service";
import { useCommonStore } from "@/stores/common-store";
import CommonUtils from "@/utils/common-util";

/**
 * 앱 서비스 모음 클래스
 * @class
 */
class ApiServices {
  public readonly userApiService: UserApiService;
  public readonly settingApiService: SettingApiService;
  public readonly scenarioApiService: ScenarioApiService;
  public readonly deviceApiService: DeviceApiService;
  public readonly etcApiService: EtcApiService;
  public readonly sitezoneApiService: SiteZoneApiService;
  public readonly simulationApiService: SimulationApiService;
  public readonly usageProfilesApiService: UsageProfilesApiService;

  private restClient: RestClient | null = null;

  constructor() {
    const apiBaseUrl: string = import.meta.env.VITE_API_URL;

    //RestClient 생성
    this.restClient = new RestClient(apiBaseUrl);

    this.userApiService = new UserApiService(this.restClient);
    this.settingApiService = new SettingApiService(this.restClient);
    this.scenarioApiService = new ScenarioApiService(this.restClient);
    this.deviceApiService = new DeviceApiService(this.restClient);
    this.etcApiService = new EtcApiService(this.restClient);
    this.sitezoneApiService = new SiteZoneApiService(this.restClient);
    this.simulationApiService = new SimulationApiService(this.restClient);
    this.usageProfilesApiService = new UsageProfilesApiService(this.restClient);
  }

  setErrorCallback() {
    const commonStore = useCommonStore();

    if (CommonUtils.isNullOrEmpty(this.restClient))
      return;

    this.restClient.setErrorCallback(commonStore.setErrorMessage);
  }
}

export default ApiServices;

<script setup lang="ts">
import { computed, inject, onMounted, onBeforeMount, onUpdated, proxyRefs, reactive, watch, ref } from "vue";
  
import TopBarComponent from "@/components/common/TopBar/TopBarComponent.vue";
import FooterComponent from "@/components/common/FooterComponent.vue";
import ModelView from "./Contents/ModelView.vue";
  
import { DragBoxInfo, EditorInfo, GridSetting } from "@/models/editor/editor-setting";
import DrawCanvas from "@/components/Temp/DrawCanvas.vue";
import BaseNode from "@/components/Nodes/BaseNode.vue";
import type LightNodeConfig from "@/models/editor/nodes/config/light-node-config";
import type AbstractNodeInfo from "@/models/editor/nodes/info/abstract-node-info.js";
import { useNodeEditorStore } from "@/stores/node-editor-store";
import { useLightStore } from "@/stores/light-store";
import ZoneRspace from "@/components/SpaceLight/ZoneRspace.vue";
import ZoneCspace from "@/components/SpaceLight/ZoneCspace.vue";
import ZoneUspace from "@/components/SpaceLight/ZoneUspace.vue";
import ZonePath from "@/components/SpaceLight/ZonePath.vue";
import { useEditStore } from "@/stores/edit-store";
import type Zone from "@/models/entity/zone";
import { useMenuStore } from "@/stores/menu-store";  
import { Depth2_Kind, InitState, LightSetResizeCondition, LightSetResizeType, NodeEventType, SvgButtonVisibility, TopBar_Action_Kind, ViewType, ZoneTypeCode } from "@/global/enums";
import type { Position } from "@/global/types";
import CommonUtils from "@/utils/common-util";
import { useQuasar } from "quasar";
import type * as THREE from "three";
import { useSceneStore } from "@/stores/scene-store";
import ZoneSelectArea from "@/components/SpaceLight/ZoneSelectArea.vue";
import LightSetArea from "./LightSetArea/LightSetArea.vue";
import type InDPoint from "@/models/group/in-d-point";
import LightSet from "@/models/entity/light-set";
  
// defineProps<{
//   isLeftOpen: boolean,
//   isRightOpen: boolean,
// }>();

const emitData = defineEmits<{
  (eventName: "openChange", value: string): void;
}>();

const nodeEditorStore = useNodeEditorStore();
const menuStore = useMenuStore();
const sceneStore = useSceneStore();

const lightStore = useLightStore();
const editStore = useEditStore();

const $q = useQuasar();
  
const gridSetting = new GridSetting(import.meta.env.VITE_LIGHT_DEPLOY_GRID_WIDTH, import.meta.env.VITE_LIGHT_DEPLOY_GRID_HEIGHT);
const editorInfo = reactive(new EditorInfo(gridSetting));

const lightSetInfos = [];

// let areaWidth: number;
// let areaHeight: number;

const setMoveX = ref(0);
const setMoveY = ref(0);

const isUZONEInfo = computed(() => {
  return editStore.selectModelAreaInfo;
});

const viewType = computed(() => {
  return editStore.modelViewType;
});

const modelFileGrp = computed(() => {
  return editStore.selectSpaceInfo?.modelFileGrp;
});

const groupBoxes = computed(() => {
  let selectGroupId = null;
  if(editStore.selectGroupInfo !== null){
    selectGroupId = editStore.selectGroupInfo.grpSeq as number;
  }
  // editStore.clearNodeEL();
  return editStore.getGroupBoxes(selectGroupId as number);
});

const lightSetInfoList = computed(() => {
  return editStore.selectSpaceInfo?.lightSetList;
});

const actionButtonPosition = reactive<Position>( { x: 0, y: 0} );
const actionButtonVisible = ref(SvgButtonVisibility.Hidden);

onBeforeMount(() => {
  nodeEditorStore.clearNodes();
});

onMounted(async () => {
  await initPage();

  // document.addEventListener("click", (e) => {
  //   console.log(e.target.tagName);
  //   if((e.target as HTMLElement).tagName !== "rect" && (e.target as HTMLElement).tagName !== "text"){
  //     onSelectLightSetInfo(null);
  //   }
  // });
});

onUpdated(() => {
  editStore.selectGroupLightUpper(editStore.modelViewType);

  console.log(" ::: spacelightcontent updated ::::");
});

//NOTE: InitLoad 수행 후 처리 로직
watch(() => editStore.initState, async () => {  
  await initPage();
});

watch(() => editStore.selectSpaceInfo, () => {
  nodeEditorStore.clearNodes();
  loadSpaceLightContents();  
});

async function initPage() {
  if(editStore.initState === InitState.NotLoad)
    return;

  await lightStore.loadDeviceElementGroups();
  loadSpaceLightContents();
  editStore.resetColor();
}

function loadSpaceLightContents() {      
  if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo))
    return;

  if (editStore.selectSpaceInfo.zoneTypeCode !== ZoneTypeCode.단위존)
    return;
  
  setDefaultSize();

  editStore.setEditSpaceSelectType("");
  editStore.setRightSpaceInfo(null);
  editStore.setSelectGroupInfo(null);
  editStore.setViewType(ViewType.SecondDimension);
  sceneStore.initData();

  editStore.loadZoneLights(editStore.selectSpaceInfo.zoneSeq);
}




// const lights = computed(() => {

//   console.log("Group Light Update");
//   nodeEditorStore.deselectAllNode();
//   if(editStore.selectGroupInfo === null || ((menuStore.selectedMenu?.menuKind !== Depth2_Kind.그룹) && (menuStore.selectedMenu?.menuKind !== Depth2_Kind.씬))) {
//     editStore.setSelectGroupInfo(null);
//     return nodeEditorStore.nodeInfos;
//   } else {

//     if(CommonUtils.isNullOrEmpty(editStore.selectGroupInfo) || CommonUtils.isNullOrEmpty(editStore.selectGroupInfo.members))
//       return null;

//     const menu = menuStore.getMenu(TopBar_Action_Kind.그룹멤버편집, menuStore.topbarList as Menu[]) as Menu;

//     const inDPoints = editStore.getGroupInDPoints(editStore.selectGroupInfo);
    
//     const groupLights: AbstractNodeInfo[] = [];

//     for (const inDPoint of inDPoints) {
//       for (const nodeInfo of nodeEditorStore.nodeInfos) {

//         if(CommonUtils.isNullOrEmpty(inDPoint.nodeID))
//           continue;

//         if(inDPoint.nodeID !== nodeInfo.id)
//           continue;

//         if (menu.isSelected) {
//           nodeInfo.nodeStatus = NodeStatus.Selected;                    
//         } else
//           groupLights.push(nodeInfo as AbstractNodeInfo);
//       }
//     }

//     if (menu.isSelected) {
//       return nodeEditorStore.nodeInfos;      
//     } else
//       return groupLights;
//   }
// });


function collapseOpenClose(value: string) {
  emitData("openChange", value);
}

function setDefaultSize(zone: Zone | null = editStore.selectZoneInfo ?? null) {

  if (CommonUtils.isNullOrEmpty(zone))
    return;

  if (zone.childZones) {
    zone.childZones.forEach(z => {
      if (z.horizSize === null || z.horizSize === undefined) {
        z.horizSize = zone.horizSize;
      }

      if (z.vertiSize === null || z.horizSize === undefined) {
        z.vertiSize === zone.vertiSize;
      }

      if (z.height === null || z.horizSize === undefined) {
        z.height = zone.height;
      }

      z.childZones.forEach(childZone => {
        setDefaultSize(childZone);
      });
    });
  }
}

function onDragBoxMouseMove(event: MouseEvent, dragBoxInfo: DragBoxInfo) {

  console.log(dragBoxInfo.w, dragBoxInfo.h);

  if(menuStore.lastSelectedTopBarMenu === null || menuStore.lastSelectedTopBarMenu.menuKind !== TopBar_Action_Kind.조명자동추가 || !menuStore.lastSelectedTopBarMenu.isSelected)
    return;
  
  const menuList = menuStore.getMenuList;

  console.log(`CurrentMenuList -> ${JSON.stringify(menuList, null, 2)}`);
  
  nodeEditorStore.clearTempNodes();          
  const config = lightStore.autoDeployDeviceConfig;

  if(CommonUtils.isNullOrEmpty(config))
    return;
  const nodePosList = getNodePosList(lightStore.lightAutoDeployCount.horizontalCount, lightStore.lightAutoDeployCount.verticalCount, config, dragBoxInfo);
    
  if (nodePosList === null) 
    return;
    
  for (const nPos of nodePosList) {
    nodeEditorStore.createTempNode(nPos.x, nPos.y, config);
  }

  return;
}
  
function onDragBoxMouseUp(event: MouseEvent, dragBoxInfo: DragBoxInfo) {
  console.log(editStore.selectSpaceInfo);
  if(menuStore.selectedMenu?.menuKind === Depth2_Kind.조명셋 && menuStore.isLightSet === true){
    console.log("onDraw");
    drawLightSetBase(event, dragBoxInfo, event);
    return;
  }


  if(menuStore.lastSelectedTopBarMenu === null || menuStore.lastSelectedTopBarMenu.menuKind !== TopBar_Action_Kind.조명자동추가 || !menuStore.lastSelectedTopBarMenu.isSelected)
    return;
    
  const config = lightStore.autoDeployDeviceConfig;

  if(CommonUtils.isNullOrEmpty(config))
    return;

  const nodePosList = getNodePosList(lightStore.lightAutoDeployCount.horizontalCount, lightStore.lightAutoDeployCount.verticalCount, config, dragBoxInfo);
    
  if (nodePosList === null)
    return;

  nodeEditorStore.clearTempNodes();
  
  const nodeIds: string[] = [];
  for (const nPos of nodePosList) {
    const node = nodeEditorStore.createNode(nPos.x, nPos.y, config);

    if(CommonUtils.isNullOrEmpty(node))
      continue;
      
    nodeIds.push(node.id);
  }    

  editStore.updateDPoints(NodeEventType.Added, nodeIds);
}

function drawLightSetBase(event: MouseEvent, dragBoxInfo: DragBoxInfo, mouseEvent: MouseEvent){
  
  console.log(dragBoxInfo, event);
  
  
  const ox = dragBoxInfo.x < dragBoxInfo.ox ? dragBoxInfo.x : dragBoxInfo.ox;
  const oy = dragBoxInfo.y < dragBoxInfo.oy ? dragBoxInfo.y : dragBoxInfo.oy;
  const lightIdx = editStore.lightSetList.length <= 0 ? 1 : editStore.lightSetList[editStore.lightSetList.length-1].lightSetSeq + 1;

  const squareParameter = new LightSet(lightIdx, "테스트조명셋", dragBoxInfo.w, dragBoxInfo.h, ox, oy);

  console.log(squareParameter);
  editStore.makeLightSetArea(squareParameter);
  menuStore.setLightSetTrigger();

}
  
//사각 영역에 조명 사이즈로 꽉 채워 배치하는 로직
/*
  function onDragBoxMouseUp(event: MouseEvent, dragBoxInfo: DragBoxInfo) {
    console.log(`onDragBoxMouseUp~~~!!!! ox: ${dragBoxInfo.ox}, oy: ${dragBoxInfo.oy}, w: ${dragBoxInfo.w}, h: ${dragBoxInfo.h}, dx: ${dragBoxInfo.x}, dy: ${dragBoxInfo.y} mode: ${editorInfo.editorMode}`);
    
    const config = nodeEditorStore.getNodeConfigs[0] as LightNodeConfig;  
    const hNodeCount = Math.floor(dragBoxInfo.w / config.w);
    const vNodeCount = Math.floor(dragBoxInfo.h / config.h);
    
    const hDistance = (dragBoxInfo.w - (config.w * hNodeCount)) / hNodeCount;
    const vDistance = (dragBoxInfo.h - (config.h * vNodeCount)) / vNodeCount;
    
    //드래그를 아래서 위로 하였을때 원점 변경
    const ox = dragBoxInfo.x < dragBoxInfo.ox ? dragBoxInfo.x : dragBoxInfo.ox;
    const oy = dragBoxInfo.y < dragBoxInfo.oy ? dragBoxInfo.y : dragBoxInfo.oy;
    
    let pos: Position = {
      x: ox + hDistance,
      y: oy,
    }
    
    while(pos.y < (dragBoxInfo.h + oy)) {
      
      for(let i = 0; i < hNodeCount; i++) {
        
        nodeEditorStore.createNode(pos.x, pos.y, config);    
        pos.x = pos.x + config.w + hDistance;    
      }
      pos.x = ox + hDistance;
      pos.y = pos.y + config.h + vDistance;
    }
  }
  */
  
function getNodePosList(hCount: number, vCount: number, config: LightNodeConfig, dragBoxInfo: DragBoxInfo) {
  //드래그를 아래서 위로 하였을때 원점 변경
  const ox = dragBoxInfo.x < dragBoxInfo.ox ? dragBoxInfo.x : dragBoxInfo.ox;
  const oy = dragBoxInfo.y < dragBoxInfo.oy ? dragBoxInfo.y : dragBoxInfo.oy;
    
  //가로 및  세로 몇개 들어가는지 갯수
  const hMaxCount = Math.floor(dragBoxInfo.w / config.w);
  const vMaxCount = Math.floor(dragBoxInfo.h / config.h);
    
  //해당영역에 가로 또는 세로로 넣을수 있는 조명 갯수 보다 크면 조명 배치 안함. (얼럿 띄우기?)
  if (hCount > hMaxCount || vCount > vMaxCount) return null;
    
  const hDistance = (dragBoxInfo.w - config.w * hCount) / (hCount + 1);
  const vDistance = (dragBoxInfo.h - config.h * vCount) / (vCount + 1);
    
  const pos: Position = {
    x: ox + hDistance,
    y: oy + vDistance,
  };
    
  const nodePosList: Position[] = [];
    
  for (let i = 0; i < vCount; i++) {
    for (let j = 0; j < hCount; j++) {
      const nodePos = {
        x: pos.x,
        y: pos.y,
      };
        
      nodePosList.push(nodePos);
      pos.x = pos.x + config.w + hDistance;
    }
      
    pos.x = ox + hDistance;
    pos.y = pos.y + config.h + vDistance;
  }
    
  return nodePosList;
}

function SetEditorInfo(width: number, height: number){
  // console.log(width, height);
  // editorInfo.gridSetting.pageWidth = width;
  // editorInfo.gridSetting.pageHeight = height;
}

function onEditorKeyEvent(event: KeyboardEvent) {
  console.log(`Editor Key Event -> ctrl: ${event.ctrlKey}, ${event.key}`);  
}

/**
 * DrawCanvas에서 노드들의(추가, 삭제, 이동)이벤트를 받는 함수
 * @param nodeEventType 노드 이벤트 타입
 * @param nodeIds 노드 아이디 리스트
 * @param event 드래그 이벤트
 */
function onNodesEvent(nodeEventType: NodeEventType, nodeIds: string[] | null, nodeInfos: AbstractNodeInfo[] | null, event?: DragEvent) {
  console.log(nodeEventType, "::: nodesEvent :::");
  if (nodeEventType === NodeEventType.Drop) {

    if(CommonUtils.isNullOrEmpty(event))
      return;

    const id = event.dataTransfer?.getData("selectedNodeConfigId");

    const config = nodeEditorStore.getNodeConfigs.find(n => n.id === id) as LightNodeConfig;

    const x = event.offsetX / editorInfo.scale - config.w / 2;
    const y = event.offsetY / editorInfo.scale - config.h / 2;

    if (CommonUtils.isNullOrEmpty(editStore.selectSpaceInfo))
      return;

    //노드 생성
    const newNode = nodeEditorStore.createNode(x, y, config);

    if(CommonUtils.isNullOrEmpty(newNode))
      return;
    
    //Drop 이벤트 -> Added 이벤트로 변경
    nodeIds = [newNode.id];
    nodeEventType = NodeEventType.Added;
  } else if(nodeEventType === NodeEventType.Removed) {

    if(!CommonUtils.isNullOrEmpty(nodeInfos))
      nodeIds = nodeInfos?.map(n => n.id);
  } else if(nodeEventType === NodeEventType.Paste) {
    nodeIds = nodeEditorStore.copyingNodes.map(n => n.id);
  }

  editStore.updateDPoints(nodeEventType, nodeIds);
}

/**
 * DrawCanvas의 DrawBox 유효성 체크
 */
function drawBoxSelectValidator() {

  if (menuStore.lastSelectedTopBarMenu === null)
    return true;

  if (menuStore.lastSelectedTopBarMenu.menuKind === TopBar_Action_Kind.조명자동추가 && menuStore.lastSelectedTopBarMenu.isSelected)
    return false;
  else
    return true;
}

/**
 * DrawCanvas의 노드 삭제 유효성 체크
 */
function deleteValidator(nodeIds: string[]) {
  console.log("::: delete validator :::");
  const result = editStore.isLightInGroup(nodeIds);
  
  if (result) {
    console.log(`deleteValidator !!!! ===> ${result}`);

    $q.dialog({ title: "오류", message: "조명을 삭제 하려면 그룹에서 삭제할 조명이 없어야 합니다.", ok: "OK" })
      .onOk(() => {
      })
      .onCancel(() => {
      })
      .onDismiss(() => {
      });    
  }

  return !result;
}

const modelInfo = computed(() => {
  return editStore.selectModelInfo !== null ? Object.keys(editStore.selectModelInfo as THREE.Object3D).length : null;
});

/**
 * DrawCanvas 캔바스 마우스 다운 이벤트
 * @param event 
 */
function onCanvasMouseDown(event: MouseEvent) {
  actionButtonVisible.value = SvgButtonVisibility.Hidden;
  console.log(`SpaceLightContents canvasMouseDown -> offX: ${event.offsetX}, offY: ${event.offsetY}, pageX: ${event.pageX}, pageY: ${event.pageY}`);  
}

/**
 * DrawCanvas 캔바스 마우스 업 이벤트
 * @param event 
 */
function onCanvasMouseUp(event: MouseEvent) {

  if (menuStore.lastSelectedTopBarMenu === null)
    return;

  let isShow = false;
  if (menuStore.lastSelectedTopBarMenu.menuKind === TopBar_Action_Kind.그룹멤버편집 && menuStore.lastSelectedTopBarMenu.isSelected) {
    if (nodeEditorStore.getSelectedNodes(true).length <= 0)
      return;

    isShow = true;
  }

  if (!isShow)
    return;

  actionButtonPosition.x = event.offsetX - 50;
  actionButtonPosition.y = event.offsetY - 10;
  actionButtonVisible.value = SvgButtonVisibility.Visible;  
}

/**
 * 액션 취소 버튼 수행
 * @param event 
 */
function onClose(event: MouseEvent) {
  actionButtonVisible.value = SvgButtonVisibility.Hidden;  
}

/**
 * 액션 적용 버튼 수행
 * @param event 
 */
function onApply(event: MouseEvent) {
  try {

    if (menuStore.lastSelectedTopBarMenu === null)
      return;

    if (menuStore.lastSelectedTopBarMenu.menuKind === TopBar_Action_Kind.그룹멤버편집 && menuStore.lastSelectedTopBarMenu.isSelected) {
      editStore.updateCurrentGroupMember();
    }

    menuStore.lastSelectedTopBarMenu.isSelected = false;
  } finally {
    actionButtonVisible.value = SvgButtonVisibility.Hidden;
    event.preventDefault();
    event.stopPropagation();
  }
}

function onSelectLightSetInfo(lightSetSeq: number|null){
  
  if(lightSetSeq){
    editStore.selectLightSetSeq = lightSetSeq;
  } else {
    editStore.selectLightSetSeq = null;
  }
}

function selectLightSetOver(e: MouseEvent, seq: number){
  if(editStore.selectLightSetSeq !== seq) return;

  const selectLightSet = (editStore.selectSpaceInfo?.lightSetList as LightSet[]).find(v => Number(v.lightSetSeq) === Number(seq)) as LightSet;
  
  const lt = {x: selectLightSet.x, y: selectLightSet.y + selectLightSet.height};
  const lb = {x: selectLightSet.x, y: selectLightSet.y};
  const rt = {x: selectLightSet.x + selectLightSet.width, y: selectLightSet.y + selectLightSet.height};
  const rb = {x: selectLightSet.x + selectLightSet.width, y: selectLightSet.y};

  // console.log(selectLightSet.x, selectLightSet.y, selectLightSet.width, selectLightSet.height, e.offsetX, e.offsetY);

  // console.log(lt, lb, rt, rb, e.offsetX, e.offsetY);

  if(editStore.lightSetResizeCondition === LightSetResizeCondition.NORMAL){
    console.log(e);
    if(rt.x + 5 > e.offsetX && rb.x - 5 <= e.offsetX && rt.y + 5 > e.offsetY && rb.y -5 <= e.offsetY){
      editStore.lightSetResizeType = LightSetResizeType.RIGHT;
      (e.target as HTMLElement).style.cursor = "col-resize";
    } else if(lt.x + 5 > e.offsetX && lb.x - 5 <= e.offsetX && lt.y + 5 > e.offsetY && lb.y - 5 <= e.offsetY){
      editStore.lightSetResizeType = LightSetResizeType.LEFT;
      (e.target as HTMLElement).style.cursor = "col-resize";
    } else if(lt.x - 5 < e.offsetX && rt.x + 5 > e.offsetX && lt.y + 5 > e.offsetY && lt.y -5 < e.offsetY && rt.y + 5 > e.offsetY && rt.y - 5 < e.offsetY){
      editStore.lightSetResizeType = LightSetResizeType.BOTTOM;
      (e.target as HTMLElement).style.cursor = "row-resize";
    } else if(lb.x - 5 < e.offsetX && rb.x + 5 > e.offsetX && lb.y + 5 > e.offsetY && lb.y -5 < e.offsetY && rb.y + 5 > e.offsetY && rb.y - 5 < e.offsetY){
      editStore.lightSetResizeType = LightSetResizeType.TOP;
      (e.target as HTMLElement).style.cursor = "row-resize";
    } else if(lt.x + 5 < e.offsetX && rt.x - 5 > e.offsetX && lt.y - 5 > e.offsetY && lb.y +5 < e.offsetY){
      editStore.lightSetResizeType = LightSetResizeType.MOVE;
      (e.target as HTMLElement).style.cursor = "move";
    } else {
      editStore.lightSetResizeType = LightSetResizeType.NONE;
      (e.target as HTMLElement).style.cursor = "pointer";
    }

  }
  
}

function resizing(e: MouseEvent){
  const selectLightSet = (editStore.selectSpaceInfo?.lightSetList as LightSet[]).find(v => Number(v.lightSetSeq) === Number(editStore.selectLightSetSeq)) as LightSet;
  console.log(editStore.lightSetResizeType);


  if(editStore.lightSetResizeCondition === LightSetResizeCondition.ISRESIZING){

    if(editStore.lightSetResizeType === LightSetResizeType.RIGHT){
      selectLightSet.width = e.offsetX - selectLightSet.x;

      if(selectLightSet.width < 0) editStore.lightSetResizeType = LightSetResizeType.LEFT;

    } else if(editStore.lightSetResizeType === LightSetResizeType.LEFT){
      const originX = selectLightSet.x;
      selectLightSet.x = e.offsetX;
      selectLightSet.width += (originX - selectLightSet.x);

      if(selectLightSet.width < 0) editStore.lightSetResizeType = LightSetResizeType.RIGHT;      

    } else if(editStore.lightSetResizeType === LightSetResizeType.TOP){
      const originY = selectLightSet.y;
      selectLightSet.y = e.offsetY;
      selectLightSet.height += (originY - selectLightSet.y);

      if(selectLightSet.height < 0) editStore.lightSetResizeType = LightSetResizeType.BOTTOM;

    } else if(editStore.lightSetResizeType === LightSetResizeType.BOTTOM){
      console.log(selectLightSet.height, e);
      selectLightSet.height = e.offsetY - selectLightSet.y;

      if(selectLightSet.height < 0) editStore.lightSetResizeType = LightSetResizeType.TOP;
    }

  }
}

function setMove(e: MouseEvent){
  const selectLightSet = (editStore.selectSpaceInfo?.lightSetList as LightSet[]).find(v => Number(v.lightSetSeq) === Number(editStore.selectLightSetSeq)) as LightSet;

  selectLightSet.x = e.offsetX;
  selectLightSet.y = e.offsetY;

  selectLightSet.x -= setMoveX.value;
  selectLightSet.y -= setMoveY.value;

}


function resizingDown(e: MouseEvent){
  if(editStore.lightSetResizeType === LightSetResizeType.MOVE){
    const selectLightSet = (editStore.selectSpaceInfo?.lightSetList as LightSet[]).find(v => Number(v.lightSetSeq) === Number(editStore.selectLightSetSeq)) as LightSet;

    setMoveX.value = e.offsetX - selectLightSet.x;
    setMoveY.value = e.offsetY - selectLightSet.y;

    editStore.lightSetResizeCondition = LightSetResizeCondition.MOVE;
    document.addEventListener("mousemove", setMove, false);
  } else if(editStore.lightSetResizeType !== LightSetResizeType.NONE){
    editStore.lightSetResizeCondition = LightSetResizeCondition.ISRESIZING;
    document.addEventListener("mousemove", resizing, false);
  }

}

function resizingUp(e:Event){
  if(editStore.lightSetResizeCondition === LightSetResizeCondition.MOVE){
    document.removeEventListener("mousemove", setMove, false);
  } else if(editStore.lightSetResizeCondition === LightSetResizeCondition.ISRESIZING){
    document.removeEventListener("mousemove", resizing, false);
  }

  editStore.lightSetResizeCondition = LightSetResizeCondition.NORMAL;
 
}

</script>

<template>
  <div class="SpaceLightContentsComponent fit">
    <TopBarComponent />
    <div id="editArea" class="editArea col">
      <!-- NOTE - 3D 모델 화면 -->
      <ModelView
        v-if="viewType === ViewType.ThirdDimension" 
        ref="modelView"
        :baseData="editStore.selectSiteInfo?.siteClsfyCode === 'A001' ? editStore.selectSpaceInfo : editStore.selectZoneInfo"
       >
      </ModelView>

      <!-- NOTE - 대표공간 편집 화면 -->
      <ZoneRspace
        v-else-if="(viewType === ViewType.SecondDimension && editStore.selectSpaceInfo?.zoneTypeCode === 'R' && modelInfo !== null)"
        :width="editStore.selectZoneInfo === null ? 0 : editStore.selectZoneInfo.horizSize"
        :height="editStore.selectZoneInfo === null ? 0 : editStore.selectZoneInfo.height"        
        :vertical="editStore.selectZoneInfo === null ? 0 : editStore.selectZoneInfo.vertiSize"
        :baseData="editStore.selectZoneInfo"
        :baseWidth="editStore.zoneWidth"
        :baseHeight="editStore.zoneHeight"
        @update:setEditor="SetEditorInfo"
      ></ZoneRspace>
  
      <!-- NOTE - 묶음공간 편집 화면 -->
      <ZoneCspace
        v-else-if="(viewType === ViewType.SecondDimension && editStore.selectSpaceInfo?.zoneTypeCode === 'C' && modelInfo !== null)" 
        :width="editStore.selectSpaceInfo === null ? 0 : editStore.selectSpaceInfo.horizSize"
        :height="editStore.selectSpaceInfo === null ? 0 : editStore.selectSpaceInfo.height"
        :vertical="editStore.selectSpaceInfo === null ? 0 : editStore.selectSpaceInfo.vertiSize as number"
        :baseData="editStore.selectSpaceInfo"
        :baseWidth="editStore.zoneWidth"
        :baseHeight="editStore.zoneHeight"
        @update:setEditor="SetEditorInfo"        
      >
      </ZoneCspace>
    
      <!-- NOTE - 단위공간 편집 화면 -->
    
      <ZoneUspace
        v-else-if="(viewType === ViewType.SecondDimension && editStore.selectSpaceInfo?.zoneTypeCode === 'U' && modelInfo !== 0)"
        :width="editStore.selectSpaceInfo === null ? 0 : editStore.selectSpaceInfo.horizSize"
        :height="editStore.selectSpaceInfo === null ? 0 : editStore.selectSpaceInfo.height"
        :vertical="editStore.selectSpaceInfo === null ? 0 : editStore.selectSpaceInfo.vertiSize"
        :baseData="editStore.selectSpaceInfo"
        @update:setEditor="SetEditorInfo"     
      ></ZoneUspace>
      
      <ZonePath 
        v-if="(viewType === ViewType.SecondDimension && editStore.selectSpaceInfo?.zoneTypeCode === 'U' && modelInfo === 0)"
      ></ZonePath>

      <ZoneSelectArea 
        v-if="isUZONEInfo !== null && editStore.selectSpaceInfo?.zoneTypeCode === 'U'"      
      ></ZoneSelectArea>

      <LightSetArea
      
      ></LightSetArea>

      <!-- NOTE - 조명 편집 화면 -->
      <draw-canvas
        :style="{ width: '100%', height: '100%', position: 'absolute'}"        
        v-show="
          menuStore.selectedMenu?.menuKind === Depth2_Kind.조명배치 || 
          menuStore.selectedMenu?.menuKind === Depth2_Kind.그룹 ||
          menuStore.selectedMenu?.menuKind === Depth2_Kind.씬 ||
          menuStore.selectedMenu?.menuKind === Depth2_Kind.조명셋"
        v-model="editorInfo"
        :deleteValidator="deleteValidator"
        :drawBoxSelectValidator="drawBoxSelectValidator"
        @dragBoxMouseMove="onDragBoxMouseMove"
        @dragBoxMouseUp="onDragBoxMouseUp"
        @editorKeyEvent="onEditorKeyEvent"
        @nodesEvent="onNodesEvent"
        @canvasMouseDown="onCanvasMouseDown"
        @canvasMouseUp="onCanvasMouseUp"
        >
        <template v-slot:real="{ onNodeMouseDown, onNodeMouseUp }">
          <base-node
          v-for="node in nodeEditorStore.nodeInfos"
          :nodeInfo="(node as AbstractNodeInfo)"
          :key="node.id"
          @nodeMouseDown="onNodeMouseDown"
          @nodeMouseUp="onNodeMouseUp"
        />      
        </template>
    
        <template v-slot:temp>
          <base-node v-for="node in nodeEditorStore.tempNodeInfos" :nodeInfo="(node as AbstractNodeInfo)" :key="node.id" />
        </template>

        <template v-slot:action>
          <foreignObject :x="actionButtonPosition.x" :y="actionButtonPosition.y" width="130" height="76" :visibility="actionButtonVisible">
                <div class="q-pa-md q-gutter-sm">
                  <q-btn round size="sm" color="pink" icon="close" @mousedown="onClose" />
                  <q-btn round size="sm" color="secondary" icon="done" @mousedown="onApply" />
                </div>
              </foreignObject>
        </template>
      </draw-canvas>
    
      <!-- NOTE 그룹박스 -->
      <div
        v-if="editStore.selectSpaceInfo?.zoneTypeCode === ZoneTypeCode.단위존 && 
              (menuStore.selectedMenu?.menuKind === Depth2_Kind.그룹 || menuStore.selectedMenu?.menuKind === Depth2_Kind.씬) &&
              (menuStore.lastSelectedTopBarMenu?.menuKind !== TopBar_Action_Kind.그룹멤버편집 || (menuStore.lastSelectedTopBarMenu?.menuKind === TopBar_Action_Kind.그룹멤버편집 && !menuStore.lastSelectedTopBarMenu.isSelected))">
        <svg           
          :x="0" 
          :y="0"          
          :width="editorInfo.gridSetting.pageWidth * editorInfo.scale"
          :height="editorInfo.gridSetting.pageHeight * editorInfo.scale"
          pointer-events="all">

          <g id="groupBoxes">
            <g v-for="(groupBox, i) in groupBoxes" :key="i" :class="`groupBoxes${groupBox.group.grpSeq}`" 
            :style="{display: `${editStore.sceneGroupONOFF === true ? 'block' : editStore.selectGroupInfo?.grpSeq === groupBox.group.grpSeq ? 'block' : 'none'}`}">
  
            <rect
              :class="`GroupBox${groupBox.group.grpSeq} ${editStore.selectGroupInfo?.grpSeq === groupBox.group.grpSeq ? 'selectBnpm run ox' : ''}`"
              rx="10"
              ry="10"
              :ox="0"
              :oy="0"            
              :x="groupBox.x"
              :y="groupBox.y"
              :width="groupBox.width"
              :height="groupBox.height"
              :style="{fill: '#000000', opacity: 0.5}"
               />
              <text :transform="`translate(${groupBox.x + (groupBox.width / 2)}, ${groupBox.y + (groupBox.height / 2)})`" text-anchor="middle" alignment-baseline="middle"
                fill="#ffffff" style="font-weight: bold; font-size: 20;">
                {{ groupBox.group.grpName }}
              </text>
            </g>
          </g>
        </svg>
      </div>

      <div v-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.조명셋 && menuStore.isLightSet === false">
        <svg 
        :x="0" 
        :y="0" 
        :width="editorInfo.gridSetting.pageWidth * editorInfo.scale"
        :height="editorInfo.gridSetting.pageHeight * editorInfo.scale"
        :pointer-events="'all'"
        >
        <g id="lightSetInfo">
          <g v-for="set in lightSetInfoList" :key="`lightSetKey${set.lightSetSeq}`" :class="`lightSet${set.lightSetSeq}`" @click="onSelectLightSetInfo(Number(set.lightSetSeq))">
            <rect
              rx="10"
              ry="10"
              :x="set.x"
              :y="set.y"
              :ox="0"
              :oy="0"
              :width="set.width"
              :height="set.height"
              draggable="true"
              @mousemove="selectLightSetOver($event, Number(set.lightSetSeq))"
              @mousedown="resizingDown"
              @mouseup="resizingUp"
              :style="{fill: '#313131', 
                       opacity: editStore.selectLightSetSeq === Number(set.lightSetSeq) ? 1 : 0.5, 
                       stroke: editStore.selectLightSetSeq === Number(set.lightSetSeq) ? '#000000' : 'transparent',
                       strokeWidth: editStore.selectLightSetSeq === Number(set.lightSetSeq) ? 3 : 0}"
            >  
            </rect>
            <text :transform="`translate(${set.x + (set.width / 2)}, ${set.y + (set.height / 2)})`" text-anchor="middle" alignment-baseline="middle"
              fill="#ffffff" style="font-weight: bold; font-size: 20;">
              {{ set.lightSetName }}
            </text>
          </g>
        </g>
      </svg>

      </div>
        <!-- <q-btn
    z-max
    class="leftButton"
    z-top
    unelevated
    color="primary"
    :icon="isLeftOpen === true ? `chevron_left` : 'chevron_right'"
    @click="collapseOpenClose('left')"
    :style="{ position: 'fixed', left: '0', top: '50%', zIndex: '20', width: '5px' }"
    />
    
    <q-btn
    z-max
    class="leftButton"
    z-top
    unelevated
    color="primary"
    :icon="isRightOpen === true ? 'chevron_right' : 'chevron_left'"
    @click="collapseOpenClose('right')"
    :style="{ position: 'fixed', right: '0', top: '50%', zIndex: '20', width: '5px' }" -->
  </div>
  <FooterComponent></FooterComponent>
</div>
</template>

<style lang="scss">
  .SpaceLightContentsComponent {
    background-color: #181b25;
    .editArea {
      overflow: auto;
      scrollbar-width: thin;
      height: calc(100% - 97px);
      
      &::-webkit-scrollbar-thumb {
        right: 4px;
        border-radius: 5px;
        background-color: #c1c1c1;
        width: 5px;
        opacity: 0.3;
      }
      &::-webkit-scrollbar-track {
        right: 2px;
        border-radius: 9px;
        background-color: #181b25;
        width: 9px;
        opacity: 0.2;
      }
      
      &::-webkit-scrollbar {
        position: absolute;
        width: 10px;
        height: 10px;
      }
    }
  }

  // #drawItemGroup{
  //   outline: auto;
  // }

  #groupBoxes{
    rect{
      &.selectBox{
        stroke: rgb(0, 255, 17);
        stroke-width: 5px;
        stroke-linecap: square;
        stroke-dasharray: 0;
        opacity: 1 !important;
      }
    }
 
  }

  .ZoneContent {
    position: absolute;
    width: 100%;
    height: 100%;
    #siteArea {
      width: 100%;
      height: 100%;
    }
  }
</style>
<script></script>

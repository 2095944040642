<script setup lang="ts">
import { onMounted, ref, onBeforeMount, computed, watch } from "vue";

import { QSelect, useDialogPluginComponent } from "quasar";
// import Uspace from "@/models/uspace";
import { useEditStore } from "@/stores/edit-store";
import { useMaterialsStore } from "@/stores/materials-store";
import Zone from "@/models/entity/zone";
import UZone from "@/models/entity/uzone";
import { useCommonStore } from "@/stores/common-store";
import ShapeCanvas from "./ShapeCanvas.vue";
import SpaceShape from "@/settings/spaceshape.json";
import SVGPathCommander from "svg-path-commander";
import CZone from "@/models/entity/czone";


const editStore = useEditStore();
const materialStore = useMaterialsStore();
const commonStore = useCommonStore();

const { dialogRef } = useDialogPluginComponent();

const props = defineProps({
  spaceModal: Boolean,
  type: String,
  shapeType: String,
  //   modelValue: Boolean,
});


const emitData = defineEmits(["update:spaceModal"]);

const selectRspaceShapeType = ref({
  name: "사각",
  tag: "rect",
  imgPath: "rect_sample.png"
});
const uspaceBoundPath = ref("");
const uspaceBoundParameter = ref({
  x1: 0,
  x2: 0,
  y1: 0,
  y2: 0
});

const profileOptions = computed(() => {
  return materialStore.profileOption;
});

const selectSpaceInfo = computed(() => {
  return editStore.selectSpaceInfo;
});

const zoneTypeCodeList = computed(() => {
  const result = [];

  const zoneTypeList = commonStore.getOptionTypes("zoneTypeCode");

  for(let i = 0; i < zoneTypeList.length; i++){
    if(zoneTypeList[i].value !== "R"){
      if(editStore.selectSpaceInfo?.zoneTypeCode === zoneTypeList[i].value){
        continue;
      } else {
        result.push(zoneTypeList[i]);
      }
    }
  }

  return result;
});

const uzoneTypeCode = computed(() => {
  return commonStore.getOptionTypes("uzoneTypeCode");
});

const selectCzoneTypeCode = ref();
const selectFloorTypeCode = ref();

const czoneTypeCode = computed(() => {
  return commonStore.getOptionTypes("czoneTypeCode");
});
const floorTypeCode = computed(() => {
  return commonStore.getOptionTypes("floorTypeCode");
});

const polygonPoints = ref([] as {x: number, y: number}[]);

// profileStore.getUsageProfileApi();

onBeforeMount(async () => {
  await materialStore.getUsageProfileApi();
});


const spaceForm = ref(new Zone());

watch(selectSpaceInfo, () => {
  reset();
}, {deep: true});


// spaceForm.value.zoneName = "테스트 공간";
// spaceForm.value.desc = "테스트 공간";
// spaceForm.value.uzone = new UZone();
// spaceForm.value.uzone.usageProfileCode = "일반사무실";
// spaceForm.value.horizSize = 10;
// spaceForm.value.height = 10;
// spaceForm.value.vertiSize = 10;
// spaceForm.value.area = 10;
// spaceForm.value.originX = 10;
// spaceForm.value.originY = 10;
// spaceForm.value.zoneTypeCode = "U";
// spaceForm.value.originZ = 10;


onMounted(() => {
  reset();
});

function reset(){
  spaceForm.value = new Zone();
  spaceForm.value.zoneTypeCode = "U";
  spaceForm.value.uzone = new UZone();
}

function isClose() {
  emitData("update:spaceModal", false, props.type);
}

function CspaceInput(){
  // const selectSpace = editStore.selectSpaceInfo;

  const sendData = spaceForm.value;
  const sendCspace: Zone = sendData as Zone;

  if(sendCspace.uzone){
    sendCspace.uzone = undefined;
  }

  sendCspace.zoneSeq =  Number(Math.floor(Math.random() * 100000));

  sendCspace.czone = new CZone(sendCspace.siteSeq, sendCspace.zoneSeq, selectCzoneTypeCode.value, selectFloorTypeCode.value);
  
  sendCspace.horizSize = Number(uspaceBoundParameter.value.x1);
  sendCspace.vertiSize = Number(uspaceBoundParameter.value.y1);
  sendCspace.area = Number(sendCspace.area);
  sendCspace.height = Number(sendCspace.height);

  try{
    editStore.importCspace(sendCspace as Zone);

    isClose();

  } catch {
    alert("오류가 발생하였습니다. 잠시 후 다시 시도해주세요.");
    return;
  }


}

function UspaceInput() {
  
  const sendData = spaceForm.value;
  const sendUspace: Zone = sendData as Zone;

  if(sendUspace.czone){
    sendUspace.czone = undefined;
  }

  sendUspace.zoneSeq = Number(Math.floor(Math.random() * 100000));
  
  const w = document.getElementById("polygon")?.getBoundingClientRect().width;
  const h = document.getElementById("polygon")?.getBoundingClientRect().height;

  sendUspace.horizSize = w as number;
  sendUspace.vertiSize = h as number;

  // sendUspace = Object.assign(sendData);
  switch(selectRspaceShapeType.value.tag){
    case "rect":{
      if(uspaceBoundParameter.value.x1 === 0 || 
      uspaceBoundParameter.value.y1 === 0
      ){
        alert("크기가 0보다 커야됩니다.");
        return;
      }
      break;
    }
    case "L-shape": {
      if(uspaceBoundParameter.value.x1 === 0 || 
      uspaceBoundParameter.value.x2 === 0 ||
      uspaceBoundParameter.value.y1 === 0 ||
      uspaceBoundParameter.value.y2 === 0
      ) {
        alert("크기가 0보다 커야됩니다.");
        return;
      }
      break;
    } 
  }
  
  const poly = document.getElementById("polygon");
  const path = SVGPathCommander.shapeToPath(poly) as HTMLElement;
  editStore.importUspace(sendUspace as Zone, path.getAttribute("d") as string);
  
  isClose();
}

const options = ref(profileOptions.value);

function filterFn (val: string, update: (callbackFn: () => void, afterFn?: ((ref: QSelect) => void) | undefined) => void) {
  if (val === "") {
    update(() => {
      options.value = profileOptions.value;
    });
    return;
  }
  
  update(() => {
    const needle = val.toLowerCase();
    options.value = profileOptions.value.filter(v => (v as unknown as string).toLowerCase().indexOf(needle) > -1);
  });
}

function resetBoundParameter(){
  uspaceBoundParameter.value.x1 = 0;
  uspaceBoundParameter.value.y1 = 0;
  uspaceBoundParameter.value.x2 = 0;
  uspaceBoundParameter.value.y2 = 0;
}

function polygonPointInput(){
  polygonPoints.value.push({
    x: Number(uspaceBoundParameter.value.x1),
    y: Number(uspaceBoundParameter.value.y1)
  });
  
  uspaceBoundParameter.value.x1 = 0;
  uspaceBoundParameter.value.y1 = 0;
}

function resetPolygon(){
  for(let i = 0; i < polygonPoints.value.length; i++){
    polygonPoints.value.splice(i, 1);
    i--;
  }
}

function setZoneType(){
  if(spaceForm.value.zoneTypeCode === "U"){
    spaceForm.value.czone = undefined;
    spaceForm.value.uzone = new UZone();
  } else if(spaceForm.value.zoneTypeCode === "C"){
    spaceForm.value.uzone = undefined;
  }
}

</script>

<template>
  <div>
    <q-dialog ref="dialogRef" class="NewUspaceModal" v-model="spaceModal" @hide="isClose">
      <q-card class="NewUspaceCard">
        <q-card-section class="row items-center NewUspaceModalTopbar">
          <div class="text-h6">공간 추가</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section class="q-pt-none NewUspaceModalBody">
          <div class="NewUspaceModalContentRow row">
            <label class="col-2">명칭 : </label>
            <q-input class="col-10" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.zoneName" />
          </div>
          <!-- <div class="row">
            <label class="col-2" for="">설명 :</label>
            <div class="col-8">
              <q-input outlined dense v-model="spaceForm.desc" />
            </div>
          </div> -->
          <div class="NewUspaceModalContentRow row">
            <label class="col-2" for="">공간 타입 : </label>
            <q-select
            class="col-10"  
            outlined
            square
            bg-color="white"
            :dark="false"
            dense
            v-model="spaceForm.zoneTypeCode"
            :options="zoneTypeCodeList"
            option-label="label"
            :popup-content-style="{color: '#000000'}"
            emit-value
            map-options
            @change:model-value="setZoneType"
            />
          </div>

          <div class="NewUspaceModalContentRow row" v-if="spaceForm.zoneTypeCode === 'U'">
            <label class="col-2" for="">용도프로필 : </label>
            <q-select
            class="col-10"  
            outlined
            square
            bg-color="white"
            :dark="false"
            dense
            v-model="(spaceForm.uzone as UZone).usageProfileCode"
            :options="profileOptions"
            @filter="filterFn"
            :popup-content-style="{color: '#000000'}"
            emit-value
            map-options
            />
          </div>

     

          <div v-if="spaceForm.zoneTypeCode === 'C'">
            <div class="NewUspaceModalContentRow row">
              <label class="col-2">묶음공간 유형 : </label>
              <q-select
                class="col-10"
                outlined
                square
                bg-color="white"
                :dark="false"
                dense
                v-model="selectCzoneTypeCode"
                :options="czoneTypeCode"
                :popup-content-style="{color: '#000000'}"
                emit-value
                map-options
              />
              <label class="col-2">지상/지하 구분 : </label>
              <q-select
                class="col-10"
                outlined
                square
                bg-color="white"
                :dark="false"
                dense
                v-model="selectFloorTypeCode"
                :options="floorTypeCode"
                :popup-content-style="{color: '#000000'}"
                emit-value
                map-options
              />
           </div>
          </div>

          <div v-else-if="spaceForm.zoneTypeCode === 'U'">
            <div class="NewUspaceModalContentRow row">
                <label class="col-2">단위공간 유형 : </label>
                <q-select
                class="col-10"
                outlined
                square
                bg-color="white"
                :dark="false"
                dense
                v-model="(spaceForm.uzone as UZone).uzoneTypeCode"
                :options="uzoneTypeCode"
                @filter="filterFn"
                :popup-content-style="{color: '#000000'}"
                emit-value
                map-options
                />
              </div>
              <div class="NewUspaceModalContentRow row">
                <label class="col-2">대표 공간 모양 :</label>
                <select name="rspaceShape" class="col-3 rspaceShape" v-model="selectRspaceShapeType" :style="{
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: '5px',
                  paddingLeft: '5px',
                  paddingBottom: '5px',
                  marginLeft: '5px',
                  marginBottom: '0',
                }
                "
                @change="resetBoundParameter"
                >
                <option v-for="(data , i) in SpaceShape" :key="`shapeType${i}`" :value="data">{{data.name}}</option>
              </select>
            </div>  
            
            <div class="NewUspaceModalContentRow row">
              <div class="col-2"></div>
              <ShapeCanvas class="col-9" :shapeType="selectRspaceShapeType.tag" :shape-parameter="uspaceBoundParameter" :shape-img="selectRspaceShapeType.imgPath" :polygonPath="polygonPoints" :style="{margin: '5px'}" ></ShapeCanvas>
            </div>
            
            <div class="NewUspaceModalContentRow row">
              
            </div>
          </div>
     
        <div class="boxArea">
          <div class="subjectArea">
            좌표
          </div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>X : </label>
              <q-input outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.originX" />
            </div>
            <div class="row">
              <label>Y : </label>
              <q-input outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.originY" />
            </div>
          </div>
        </div>
        
        <div v-if="selectRspaceShapeType.tag === 'rect'" class="boxArea">
          <div class="subjectArea">
            크기
          </div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>X(가로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x1" />
            </div>
            <div class="row">
              <label>Y(세로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y1" />
            </div>
          </div>
          <div :style="{marginTop: '5px', marginBottom:'5px'}"></div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>높이 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.height" />
            </div>
            <div class="row">
              <label>면적 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.area" />
            </div>
          </div>
        </div>
        
        <div v-else-if="selectRspaceShapeType.tag === 'L-shape'" class="boxArea">
          <div class="subjectArea">
            크기
          </div>
          <div class="NewUspaceModalContentsRow row" :style="{marginTop: '5px', marginBottom:'5px'}">
            <div class="row">
              <label>X1(전체가로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x1" />
            </div>
            <div class="row">
              <label>Y1(전체세로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y1" />
            </div>
          </div>
          <div class="NewUspaceModalContentsRow row" :style="{marginTop: '5px', marginBottom:'5px'}">
            <div class="row">
              <label>X2(내부가로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x2" />
            </div>
            <div class="row">
              <label>Y2(내부세로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y2" />
            </div>
          </div>
          <div :style="{marginTop: '5px', marginBottom:'5px'}"></div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>높이 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.height" />
            </div>
            <div class="row">
              <label>면적 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.area" />
            </div>
          </div>
        </div>
        <div v-else-if="selectRspaceShapeType.tag === 'A-shape'" class="boxArea">
          <div class="subjectArea">
            크기
          </div>
          <div class="NewUspaceModalContentsRow row" :style="{marginTop: '5px', marginBottom:'5px'}">
            <div class="row">
              <label>X1(전체가로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x1" />
            </div>
            <div class="row">
              <label>Y1(전체세로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y1" />
            </div>
          </div>
          <div class="NewUspaceModalContentsRow row" :style="{marginTop: '5px', marginBottom:'5px'}">
            <div class="row">
              <label>X2(내부가로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x2" />
            </div>
            <div class="row">
              <label>Y2(내부세로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y2" />
            </div>
          </div>
          <div :style="{marginTop: '5px', marginBottom:'5px'}"></div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>높이 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.height" />
            </div>
            <div class="row">
              <label>면적 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.area" />
            </div>
          </div>
        </div>
        <!-- <div v-if="selectRspaceShapeType.tag === 'A-shape'" class="boxArea">
          <div class="subjectArea">
            크기
          </div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>X(가로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x1" />
            </div>
            <div class="row">
              <label>Y(세로) : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y1" />
            </div>
          </div>
          <div :style="{marginTop: '5px', marginBottom:'5px'}"></div>
          <div class="NewUspaceModalContentsRow row">
            <div class="row">
              <label>높이 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.height" />
            </div>
            <div class="row">
              <label>면적 : </label>
              <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.area" />
            </div>
          </div>
        </div> -->
        <div v-else-if="selectRspaceShapeType.tag === 'polygon'">
          <div class="boxArea">
            <div class="subjectArea">
              크기
            </div>
            <div>
              추가된 좌표리스트
              <label v-for="(data, i) in polygonPoints" :key="`point${i}`">{{ `(${data.x},${data.y}) ` }}</label>
            </div>
            <div class="NewUspaceModalContentsRow row">
              <div class="row">
                <label>가로 : </label>
                <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.x1" />
              </div>
              <div class="row">
                <label>세로 : </label>
                <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="uspaceBoundParameter.y1" />
              </div>
              <div class="row">
                <q-btn type="submit" @click="polygonPointInput">추가</q-btn>
              </div>
              <div class="row">
                <q-btn @click="resetPolygon">초기화</q-btn>
              </div>
            </div>
            <div :style="{marginTop: '5px', marginBottom:'5px'}"></div>
            
          </div>
          <div class="boxArea">
            <div class="subjectArea">
              기타
            </div>
            <div class="NewUspaceModalContentsRow row">
              <div class="row">
                <label>높이 : </label>
                <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.height" />
              </div>
              <div class="row">
                <label>면적 : </label>
                <q-input type="number" outlined square hide-hint bg-color="white" :dark="false" dense v-model="spaceForm.area" />
              </div>
            </div>
          </div>
        </div>
        
        
      </q-card-section>
      
      <q-card-actions class="NewUspaceBottomBar" align="right">
        <q-btn v-if="spaceForm.zoneTypeCode === 'U'" class="col-2 NewUspaceAdd" flat label="추가" color="primary" @click="UspaceInput" />
        <q-btn v-else class="col-2 NewUspaceAdd" flat label="추가" color="primary" @click="CspaceInput" />
        <q-btn class="col-2 NewUspaceCancel" flat label="취소" color="danger" @click="isClose" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</div>
</template>

<style lang="scss">
.NewUspaceModal {
  .NewUspaceCard{
    width: 700px;
    max-width: 80vw;
    
    .boxArea {
      border: 1px solid #000000;
      margin: 20px 10px;
      padding: 15px;
      .NewUspaceModalContentsRow {
        justify-content: space-around;
      }
      .subjectArea {
        font-weight: bold;
        position: absolute;
        text-align: center;
        top: -15px;
        left: 50px;
        padding: 5px 15px;
        background-color: #f2f4fb;
      }
    }
  }
  
  .NewUspaceModalTopbar{
    background-color: #ffffff;
    color: #000000;
    border-bottom: 1px solid #e4e5e9;
  }
  
  .NewUspaceBottomBar{
    background-color: #ffffff;
    color: #000000;
    border-top: 1px solid #e4e5e9;
    
    .NewUspaceAdd{
      background-color: #0f1837;
      border: 1px solid #e9e9eb;
      color: white;
    }
    
    .NewUspaceCancel{
      background-color: #f2f4fb;
      border: 1px solid #e9e9eb;
      color: black;
    } 
  }
  
  .NewUspaceModalBody {
    background-color: #f2f4fb;
    color: #000000 !important;
    
    .NewUspaceModalContentRow{
      label{
        font-weight: bold;
        align-self: center;
        text-align: right;
        padding-top: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        margin-bottom: 0;
      }
      button {
        padding: 5px;
      }
    }
    
    .row {
      align-items: center;
      
      label {
        margin-bottom: 0px;
        
        align-self: center;
      }
    }
  }
}
</style>



<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from "vue";
import type {CalendarObject, alertMessageType, SimulationChartDataType, simulationLightInfo} from "@/global/types";
import SimulationGraphComponent from "./SimulationGraphComponent.vue";
import { useCommonStore } from "@/stores/common-store";
import type ZoneSml from "@/models/entity/zone-sml-type";
import ZoneSmlTypeLight from "@/models/entity/zone-sml-type-light";
import { useSimulationStore } from "@/stores/simulation-store";
import CommonUtils from "@/utils/common-util";
import { useEditStore } from "@/stores/edit-store";
import type Zone from "@/models/entity/zone";
import type { ApexOptions } from "apexcharts";
import type ZoneSmlType from "@/models/entity/zone-sml-type";
import type ZoneSmlWindoor from "@/models/entity/zone-sml-windoor";
import type { QTable, QTrProps } from "quasar";
import Windoor from "../SpaceLight/Windoor";
import AlertWarningComponent from "../common/AlertWarningComponent.vue";

const commonStore = useCommonStore();
const simulationStore = useSimulationStore();

/**
 * 선택 공간 정보
 */
const selectZone = computed(() => {
  return useEditStore().selectSpaceInfo;
});

const props = defineProps({
  simulationType: String
});

const chartLabel = ref([] as string[]);

const formSimulationData = {
  calc: "",
  lightOver: 0,
  control: "",
  errorRatio: 0,
  lightInfo: [] as simulationLightInfo[],
  fromDate: "2023-01",
  toDate: "2023-12"
};


const colorValue = ref([] as string[]);


/**
 * 시뮬레이션 정보
 */
const simulationData = computed(() => {
  return simulationStore.SimulationInfo;
});



watch(selectZone, async () => {

  await simulationStore.getZoneSmlData(selectZone.value?.siteSeq as number, selectZone.value?.zoneSeq as number);

  const smlType = simulationStore.getZoneSmlType(props.simulationType as string);

  startDateMonth.value = smlType.simulStartMonth;
  endDateMonth.value = smlType.simulEndMonth;

  OnProgress();
}, {deep: true});

/**
 *  시뮬레이션 타입 정보
 */
const detectZoneSimul = computed(() => {
  return simulationStore.getZoneSmlType(props.simulationType as string) as ZoneSmlType;
});


// const zoneSimulTypeData = computed(() => {
//   if(detectZoneSimul.value === undefined){
//     return new ZoneSmlType(props.simulationType as string);
//   }

//   return detectZoneSimul.value;
// });

const conditionDisabled = ref(props.simulationType === "SL" ? false : true);

// const zoneSimulLightTable = computed(() => {
  
//   const tableData = CommonUtils.deepClone(zoneSimulTypeData.value.zoneSmlTypeLights);
//   tableData?.forEach((v, i) => {
    
//     Object.defineProperty(v, "index", {
//       value: i
//     });
//     if(v.lightModeCode === "LM01"){
//       v.lightModeCode = "직접";
//     } else if(v.lightModeCode === "LM02"){
//       v.lightModeCode = "직간접";
//     } else if(v.lightModeCode === "LM03"){
//       v.lightModeCode = "간접";
//     }
    
//     allCommonCode.map(c => {
//       if(c.grpCode === "lightTypeCode" && c.code === v.lightTypeCode){
//         v.lightTypeCode = c.codeName;
//       }
//     });
//   });
  
//   return tableData;
// });

const chartData = ref([] as SimulationChartDataType[]);
const chartOption = ref({} as ApexOptions);
const mbeData = ref(0);
const cvrmseData = ref(0);
const diffData = ref([] as number[]);
const allData = ref([] as number[]);
const selectedRow = ref([] as ZoneSmlTypeLight[]);
const isLightAdd = ref(false);
const isLightModify = ref(true);

const isFromDateOpen = ref(false);
const isToDateOpen = ref(false);

const lightTableRef = ref({} as QTable);

const newLightInfo = ref(new ZoneSmlTypeLight());
const modifyLightInfo = ref(new ZoneSmlTypeLight());

const simulationChart = ref(null);

const alertStatic = ref(false);
const alertMessage = ref({} as alertMessageType);

const selectType = ref({} as ZoneSmlType);

const startDateMonth = ref();
const endDateMonth = ref();


onBeforeMount(() => {
  let isEmpty = false;

  const checkEmptyData = {
    lightCalcTypeCode: detectZoneSimul.value.lightCalcTypeCode,
    unitAreaByLightLoad: detectZoneSimul.value.unitAreaByLightLoad,
    lightCtrlSystemCode: detectZoneSimul.value.lightCtrlSystemCode,
    faultRate: detectZoneSimul.value.faultRate,
    maintainProcCode: detectZoneSimul.value.maintainProcCode
  };

  const checkKeys: (keyof typeof checkEmptyData)[] = Object.keys(checkEmptyData) as (keyof typeof checkEmptyData)[];  
  
  for(let i = 0; i < checkKeys.length; i++){
    if(checkEmptyData[checkKeys[i]] === null || checkEmptyData[checkKeys[i]] === undefined || checkEmptyData[checkKeys[i]] === ""){
      isEmpty = true;
      break;
    }
  }

  if(isEmpty){

    return;
  }

    
  selectType.value = (simulationStore.selectZoneSml.zoneSmlTypes as ZoneSmlType[]).find(v => v.simulTypeCode === props.simulationType) as ZoneSmlType;

  
  startDateMonth.value = selectType.value.simulStartMonth;
  endDateMonth.value = selectType.value.simulEndMonth;

  OnProgress();
});

const myLocale = {
  /* starting with Sunday */
  days: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
  daysShort: ["일","월","화","수","목","금","토"],
  months: ["1월", "2월", "3월", "4월","5월","6월","7월","8월","9월","10월","11월","12월"],
  monthsShort:  ["1월", "2월", "3월", "4월","5월","6월","7월","8월","9월","10월","11월","12월"],
  firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
  format24h: true,
};

const column = ref([
  {name: "index", label: "idx", field: "index", headerStyle: "display: none"},
  {name: "lightModeCode", label: "방식", align:"center" as const, field: "lightModeCode", headerStyle: "width: 15%"},
  {name: "lightTypeCode", label: "종류", align: "left" as const, field: "lightTypeCode", headerStyle: "width: 40%; text-align: center"},
  {name: "consumpPower", label: "소비", align: "center" as const, field: "consumpPower", headerStyle: "width: 15%; "},
  {name: "lightQty", label: "개수", align: "center" as const, field: "lightQty", headerStyle: "width: 15%;"},
  {name: "lightRate", label: "비율", align: "center" as const, field: "lightRate", headerStyle: "width: 15%;"}
]);


function fromDateSelect(DateString: string, pickDateKind: string, DateObject: CalendarObject){
  if(pickDateKind === "month"){
    isFromDateOpen.value = false;
    // formSimulationData.fromDate = DateString;
    startDateMonth.value = DateString;
    
    // const year = DateObject.month !== 1 ? DateObject.year + 1 : DateObject.year;
    
    // const months = (month: number) => {
    //   if(month - 1 === 0){
    //     return 12;
    //   }
      
    //   if(month - 1 < 10){
    //     return `0${month - 1}`;
    //   }
      
    //   return month - 1;
    // };
    
    // selectType.value.simulEndMonth = `${year}-${months(DateObject.month as number)}`;
  }
}
function fromEndSelect(DateString: string, pickDateKind: string, DateObject: CalendarObject){
  if(pickDateKind === "month"){
    isToDateOpen.value = false;
    // formSimulationData.fromDate = DateString;
    endDateMonth.value = DateString;
    
  }
}


function selectFromCalendar(){
  isFromDateOpen.value = !isFromDateOpen.value;
  isToDateOpen.value = false;
}

function selectToCalendar(){
  isToDateOpen.value = !isToDateOpen.value;

  isFromDateOpen.value = false;
  
  
}

function addZoneSimulationLight(){
  newLightInfo.value.lightSeq = detectZoneSimul.value.zoneSmlTypeLights?.length === 0 ? 1 : (detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[])[(detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[]).length - 1].lightSeq + 1;
  newLightInfo.value.simulTypeCode = props.simulationType;
  // newLightInfo.value.lightType = {} as lightType;
  // newLightInfo.value.lightType.lightTypeCode = newLightInfo.value.lightTypeCode as string;
  // newLightInfo.value.lightType.kl = 123;
  
  detectZoneSimul.value.zoneSmlTypeLights?.push(newLightInfo.value as ZoneSmlTypeLight);
  
  newLightInfo.value = new ZoneSmlTypeLight();
}

function addLightCancel(){
  newLightInfo.value = new ZoneSmlTypeLight();
}

function getOptionCode(code: string){
  return commonStore.getOptionTypes(code);
}

function getSelectedString () {
  return selectedRow.value.length === 0 ? "" : `${selectedRow.value.length}개가 선택되었습니다.`;
}

function resetLightInfo(){
  isLightAdd.value = true;
  isLightModify.value = false;
  newLightInfo.value = new ZoneSmlTypeLight();
}

function deleteRow(){
  selectedRow.value.map(v => {
    for(let i = 0; i < (detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[]).length; i++){
      if(JSON.stringify((detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[])[i]) === JSON.stringify(v)){
        (detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[]).splice(i, 1);
        break;
      }
    }
  });
 
  selectedRow.value = new Array<ZoneSmlTypeLight>();

}

function alertFalse(value: boolean){
  alertStatic.value = value;
}

async function OnProgress(){
  chartLabel.value = [] as string[];

  const sendZoneData = CommonUtils.deepClone(detectZoneSimul.value);

  const checkEmptyData = {
    lightCalcTypeCode: sendZoneData.lightCalcTypeCode,
    unitAreaByLightLoad: sendZoneData.unitAreaByLightLoad,
    lightCtrlSystemCode: sendZoneData.lightCtrlSystemCode,
    faultRate: sendZoneData.faultRate,
    maintainProcCode: sendZoneData.maintainProcCode
  };

  const {isEmpty} = CommonUtils.isObjectValueEmpty(checkEmptyData);

  console.log("empty check-----------------" ,isEmpty);
  if(isEmpty){
    return;
  }

  if(detectZoneSimul.value.lightCalcTypeCode === "CALC"){
    if((sendZoneData.zoneSmlTypeLights as ZoneSmlTypeLight[]).length <= 0){
 
      alertMessage.value.message = "조명 정보를 추가해주세요.";
      alertStatic.value = true;
      return;
    }

    let lightRate = 0;

    sendZoneData.zoneSmlTypeLights?.map(v => {
      lightRate += Number(v.lightRate);
    });

    if(Number(lightRate) !== 100){
      alertMessage.value.message = "조명 비율의 합이 100%가 아닙니다.";
      alertStatic.value = true;
      return;
    }
  }

  if(detectZoneSimul.value.daylightUseYn === "Y"){
    if((simulationStore.selectZoneSml.zoneSmlWindoors as ZoneSmlWindoor[]).length <= 0){
      alertMessage.value.message = "창호 정보를 입력해 주세요.";
      alertStatic.value = true;
      return;
    }
  }

  const getSimulationData = simulationData.value;
  
  getSimulationData.simulTypeCode = props.simulationType;
  getSimulationData.zoneSml = simulationStore.selectZoneSml;

  const detectType = getSimulationData.zoneSml.zoneSmlTypes?.find(v => v.simulTypeCode === props.simulationType) as ZoneSmlType;


  const tmpSmlTypes = CommonUtils.deepClone(getSimulationData.zoneSml.zoneSmlTypes as ZoneSmlType[]);

  const checkSmlTypes = getSimulationData.zoneSml.zoneSmlTypes as ZoneSmlType[];

  const simulStartDate = startDateMonth.value;
  const simulEndDate = endDateMonth.value;

  // console.log(Number(simulStartDate.split("-")[0]), Number(simulEndDate.split("-")[0]));

  if(Number(simulStartDate.split("-")[0]) > Number(simulEndDate.split("-")[0])){
    alert("기간을 다시 확인해주세요.");
    return;
  } else if(Number(simulStartDate.split("-")[0]) == Number(simulEndDate.split("-")[0])){
    if(Number(simulStartDate.split("-")[1]) > Number(simulEndDate.split("-")[1])){
      alert("기간을 다시 확인해주세요.");
      return;
    }
  }

  detectType.simulStartMonth = startDateMonth.value;
  detectType.simulEndMonth = endDateMonth.value;



  for(let i = 0; i < checkSmlTypes.length; i++){
    const data = {
      lightCalcTypeCode: checkSmlTypes[i].lightCalcTypeCode,
      unitAreaByLightLoad: checkSmlTypes[i].unitAreaByLightLoad,
      lightCtrlSystemCode: checkSmlTypes[i].lightCtrlSystemCode,
      faultRate: checkSmlTypes[i].faultRate,
      maintainProcCode: checkSmlTypes[i].maintainProcCode
    };

    const keys = Object.keys(data) as (keyof typeof data)[];
    let isEmpty = false;

    keys.map(v => {
      if(data[v] === "" || data[v] === null || data[v] === undefined){
        isEmpty = true;
        return;
      }
    });
    
    if(isEmpty){
      checkSmlTypes.splice(i, 1);
    }
  }
  
  chartData.value = [];
  allData.value = [];
  diffData.value = [];
  const label = [];
  const dataLabel = ["소요량", "실 사용량"];
  const datas = [];
  const predData = [];
  const realData = [];

  // getSimulationData.zoneSml.zoneSmlTypes = [detectZoneSimul.value];
  // delete getSimulationData.zoneSimuls;


  const res = await simulationStore.setSimulationData(selectZone.value?.siteSeq as number, selectZone.value?.zoneSeq as number, getSimulationData);
  
  console.log("---- getsimuldata -----");

  for(let i = 0; i < tmpSmlTypes.length; i++){
    if(tmpSmlTypes[i].simulTypeCode === props.simulationType){
      tmpSmlTypes[i] = detectType;
    }
  }


  for(let i = 0; i < (res?.consumes.length as number); i++){
    if(res.consumes[i].month === "합계"){
      allData.value.push(Number(res.consumes[i].predConsume.toFixed(2)));
      allData.value.push(Number(res.consumes[i].realUsage.toFixed(2)));
      allData.value.push(Number(res.consumes[i].diffVal.toFixed(2)));
      label.push("합계");
    } else {
      label.push(res.consumes[i].month);
      predData.push(Number(res.consumes[i].predConsume?.toFixed(2)));
      realData.push(Number(res.consumes[i].realUsage?.toFixed(2)));
      diffData.value.push(Number(res.consumes[i].diffVal.toFixed(2)));
    }
  }


  // label = [];
  // const startDate = simulStartDate as string; 
  // const endDate = simulEndDate as string;

  // const startDateYear = Number(startDate.split("-")[0]);
  // const startDateMonth = Number(startDate.split("-")[1]);
  // const endDateYear = Number(endDate.split("-")[0]);
  // const endDateMonth = Number(endDate.split("-")[1]);

  // if(startDateYear === endDateYear){
  //   for(let i = 0; i < 12; i++){
  //     label.push(`${startDateYear}-${i+1 >= 10 ? i+1 : `0${i+1}`}`);
  //   }
  // } else {
  //   for(let i = startDateMonth; i <= 12; i++){
  //     label.push(`${startDateYear}-${i >= 10 ? i : `0${i}`}`);
  //   }

  //   for(let i = 1; i <= endDateMonth; i++){
  //     label.push(`${endDateYear}-${i >= 10 ? i : `0${i}`}`);
  //   }
  // }

  // for(let i = 0; i < 12; i++){
  //   predData.unshift(0);
  //   realData.unshift(0);
  //   diffData.value.unshift(0);
  //   allData.value.unshift(0);
  // }

  datas.push(predData);
  datas.push(realData);
  
  for(let i = 0; i < dataLabel.length; i++){
    chartData.value.push({
      name: dataLabel[i],
      data: datas[i]
    });
  }
  
  mbeData.value = res.mbe as number;
  cvrmseData.value = res.cvrmse as number;

  colorValue.value = [] as string[];

  if(props.simulationType === "TL"){
    colorValue.value = ["#233471", "#3bb3ff"];
  } else if(props.simulationType === "LL"){
    colorValue.value = ["#6a2681", "#f548cd"];
  } else if(props.simulationType === "SL"){
    colorValue.value = ["#137f69", "#21ddb7"];
  }
  

  chartLabel.value = label;


  getSimulationData.zoneSml.zoneSmlTypes = tmpSmlTypes;
  

  // nextTick(() => {
  //   console.log(simulationChart.value?.simulationChart);
  //   simulationChart.value?.simulationChart.updateSeries(chartData, true);
  // });
  // simulationGraph.value
}


function changeLightInfo(row: ZoneSmlTypeLight, props: QTrProps["props"]){
  isLightAdd.value = false;
  lightTableRef.value.selected?.splice(0);

  if(props.selected === false){
    props.selected = true;
  }
  
  const lightRows = document.querySelectorAll("[id*=\"lightRow\"]");

  for(let i = 0; i < lightRows.length; i++){
    const lightRow = lightRows[i] as HTMLElement;
    if(lightRow.id === `lightRow${row.lightSeq}`){
      lightRow.style.backgroundColor = "#e2e6f3";
      isLightModify.value = true;
      modifyLightInfo.value = CommonUtils.deepClone(row);
    } else {
      lightRow.style.backgroundColor = "#ffffff";
    }
  }
  // modifyLightInfo.value = CommonUtils.deepClone(detectZoneSimul.value.zoneSmlTypeLights?.find(v => v.lightSeq === row.lightSeq) as ZoneSmlTypeLight);
}

function modifyLight(){
  for(let i = 0; i < (detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[]).length; i++){
    if((detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[])[i].lightSeq === modifyLightInfo.value.lightSeq){
      (detectZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[])[i] = modifyLightInfo.value;
      break;
    }
  }

  modifyLightInfo.value = new ZoneSmlTypeLight;
  isLightModify.value = false;
}

function modifyLightCancel(){
  const windoorRows = document.querySelectorAll("[id*=\"lightRow\"]");

  windoorRows.forEach(v => {
    const el = v as HTMLElement;
    el.style.backgroundColor = "#ffffff";
  });

  modifyLightInfo.value = {} as ZoneSmlTypeLight;
}

</script>

<template>
  <div class="SimulationTrandLedComponent row">
    <AlertWarningComponent :static-value="alertStatic" :confirm-value="false" :message="alertMessage" @change="alertFalse"></AlertWarningComponent>
    <div class="SimulationTrandLedLeft col-sm-12 col-md-4">
      <div class="SimulationTrandLedLeftContents row">
        <div class="headerLabel">
          <label>시뮬레이션 입력</label>
        </div>
        
        <div class="simulationTrandLedLeftInput row">
          <div class="col-md-12 col-lg-6 q-py-xs q-px-md">
            <label>계산 방식</label>
            <q-select v-model="detectZoneSimul.lightCalcTypeCode" :options="getOptionCode('lightCalcTypeCode')" dense :dark="false" label-color="black" :popup-content-style="{color: '#000000'}" outlined square bg-color="white" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-6 q-py-xs q-px-md">
            <label>기준 조명 부하[W/㎡]</label>
            <q-input type="number" v-model.number="detectZoneSimul.unitAreaByLightLoad" dense :dark="false" outlined square bg-color="white"></q-input>
          </div>
          
          <div class="col-md-12 col-lg-6 q-py-xs q-px-md">
            <label>주광 제어 시스템</label>
            <q-select v-model="detectZoneSimul.lightCtrlSystemCode" :options="getOptionCode('lightCtrlSystemCode')" dense :dark="false" outlined square bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-6 q-py-xs q-px-md">
            <label>월별 고장 률[%]</label>
            <q-input  type="number" v-model.number="detectZoneSimul.faultRate" dense :dark="false" outlined square bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-6 q-py-xs q-px-md">
            <label>유지 보수</label>
            <q-select v-model="detectZoneSimul.maintainProcCode" :options="getOptionCode('maintainProcCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-6 q-py-xs q-px-md" :style="{alignSelf: 'self-end', textAlign: 'center', paddingRight: '30px'}">
            <q-checkbox size="xs" class="col-12" v-model="detectZoneSimul.daylightUseYn" :dark="false" label="주광 이용 여부" :true-value="'Y'" :false-value="'N'"/>
          </div>
        </div>
      

        <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>

        
        <div class="SimulationTrandLedLeftTable col-12 q-py-xs q-px-md">
          <div class="row TrandLedBtn">
            <label>조명 정보</label>
            <div>
              <q-btn square flat icon="add" @click="resetLightInfo"></q-btn>
              <q-btn square flat icon="remove" @click="deleteRow"></q-btn>
            </div>
          </div>

          <q-table 
            hide-pagination 
            square 
            dense
            flat
            ref="lightTableRef"
            :columns="column"
            :visible-columns="column.map(v => v.field !== 'index' ? v.field : undefined)" 
            :rows="detectZoneSimul.zoneSmlTypeLights" 
            row-key="lightSeq" 
            :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
            :rows-per-page-options="[0]" 
            :dark="false" 
            :selected-rows-label="getSelectedString"
            no-data-label="추가된 데이터가 없습니다." 
            table-class="SimulationTrandLedTable" 
            table-header-class="SimulationTrandLedTableHeader" 
            selection="multiple"
            v-model:selected="selectedRow"
          >
          <template v-slot:header-selection="props">
            
            <td>
              <q-checkbox size="xs" v-model="props.selected"></q-checkbox>
            </td>
          </template>
          <template v-slot:body="props" >
            <tr :id="`lightRow${props.row.lightSeq}`" :style="{textAlign: 'center', cursor:'pointer'}" @click="changeLightInfo(props.row, props)">
              <td>
                <q-checkbox size="xs" v-model="props.selected"></q-checkbox>
              </td>
              <td>{{commonStore.getFindCodeToName('lightModeCode', props.row.lightModeCode) }}</td>
              <td>{{commonStore.getFindCodeToName('lightTypeCode',props.row.lightTypeCode) }}</td>
              <td>{{props.row.consumpPower}}</td>
              <td>{{props.row.lightQty}}</td>
              <td>{{props.row.lightRate}}</td>
            </tr>
          </template>
        </q-table>
       
      </div>
      
      <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
      
      <div v-if="isLightAdd === true" class="simulationTrandLedLeftInput col-12 q-py-xs q-px-md" >
        <div class="row q-py-xs" >
          <div class="col-12 q-py-xs q-px-md">
            <label>조명 방식</label>
            <q-select v-model="newLightInfo.lightModeCode" :options="getOptionCode('lightModeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-12 q-py-xs q-px-md">
            <label>조명 종류</label>
            <q-select v-model="newLightInfo.lightTypeCode" :options="getOptionCode('lightTypeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>소비 전력[W]</label>
            <q-input type="number" v-model="newLightInfo.consumpPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>대기 전력[W]</label>
            <q-input type="number" v-model="newLightInfo.standbyPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>조명 개수</label>
            <q-input type="number" v-model="newLightInfo.lightQty" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>조명 비율[%]</label>
            <q-input type="number" v-model="newLightInfo.lightRate" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          
          <div class="row col-12 justify-end q-pa-xs">
            <q-btn class="AddinfoBtn" flat square @click="addZoneSimulationLight">추가</q-btn>
            <q-btn class="infoBtn" flat square @click="addLightCancel">취소</q-btn>
            <!-- <q-btn class="infoBtn col-md-12 col-lg-3" dense color="grey" :dark="false">삭제</q-btn> -->
          </div>
        </div>
      </div>
      <div v-if="isLightModify === true" class="simulationTrandLedLeftInput col-12 q-py-xs q-px-md" >
        <div class="row q-py-xs">
          <div class="col-12 q-py-xs q-px-md">
            <label>조명 방식</label>
            <q-select v-model="modifyLightInfo.lightModeCode" :options="getOptionCode('lightModeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-12 q-py-xs q-px-md">
            <label>조명 종류</label>
            <q-select v-model="modifyLightInfo.lightTypeCode" :options="getOptionCode('lightTypeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>소비 전력[W]</label>
            <q-input type="number" v-model="modifyLightInfo.consumpPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>대기 전력[W]</label>
            <q-input type="number" v-model="modifyLightInfo.standbyPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>조명 개수</label>
            <q-input type="number" v-model="modifyLightInfo.lightQty" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>조명 비율[%]</label>
            <q-input type="number" v-model="modifyLightInfo.lightRate" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>조명 시작 시간</label>
            <q-input type="text" v-model="modifyLightInfo.useStartTime" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-md">
            <label>조명 종료 시간</label>
            <q-input type="text" v-model="modifyLightInfo.useEndTime" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
          </div>
          <div class="row col-12 justify-end q-pa-xs">
            <q-btn flat class="AddinfoBtn" square @click="modifyLight">수정</q-btn>
            <q-btn flat class="infoBtn" square @click="modifyLightCancel">취소</q-btn>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 q-py-xs q-px-md column">
        <q-btn class="col-12" dense color="grey" :dark="false" back>시뮬레이션 실행</q-btn>
      </div> -->
    </div>
  </div>
  <div class="SimulationTrandLedRight col-sm-12 col-md-8">
    <div class="SimulationTrandLedDate row items-center" :style="$q.screen.md ? {height: 'auto'} : {}">
      <div class="headerLabel">
        <label>시뮬레이션 실행</label>
      </div>
      <div class="SimulationCalendar">
        <label :style="{marginRight: '5px'}">시뮬레이션 기간 : </label>
      </div>
      <div class="row col-md-12 col-lg-3">
        <q-input class="hideBottom" v-model="startDateMonth" :dark="false" dense outlined square>
          <template v-slot:append>
            <q-icon class="calendarIcon" :name="'calendar_month'" color="black" @click="selectFromCalendar"></q-icon>
          </template>
        </q-input>
        
        <q-date class="FromDateCalendar" v-if="isFromDateOpen" v-model="startDateMonth" :locale="myLocale" minimal
        mask="YYYY-MM"
        :years-in-month-view="true"
        emit-immediately
        default-view="Months"
        @update:model-value="fromDateSelect"
        />
      </div>
      
      <span :style="{marginRight: '10px', marginLeft: '10px', fontSize: '15px', fontWeight: 'bold'}"> ~ </span>
      <div class="row col-md-12 col-lg-3">
        <q-input class="hideBottom" v-model="endDateMonth" :dark="false" dense outlined square>
          <template v-slot:append>
            <q-icon class="calendarIcon" :name="'calendar_month'" color="black" @click="selectToCalendar"></q-icon>
          </template>
        </q-input>
        
        <q-date class="toDateCalendar" v-if="isToDateOpen" v-model="endDateMonth" :locale="myLocale" minimal
        mask="YYYY-MM"
        :years-in-month-view="true"
        emit-immediately
        default-view="Months"
        @update:model-value="fromEndSelect"

        />
      </div>
      <div class="row">
        <q-btn class="dateBtn" square @click="OnProgress">
          실행
        </q-btn>
      </div>
      <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>

    </div>
    <div class="SimulationTrandLedGraph">
      <!-- <label>시뮬레이션 결과</label> -->
      <SimulationGraphComponent 
      ref="simulationChart" 
      :chartData="chartData" 
      :mbe-data="mbeData" 
      :cvrmse-data="cvrmseData" 
      :diff-data="diffData" 
      :all-data="allData"
      :label="chartLabel"
      :colorValue="colorValue"
      ></SimulationGraphComponent>
    </div>
  </div>
</div>
</template>

<style lang="scss">
.SimulationTrandLedComponent{
  background-color: #f2f4fb;
  color: #404040;
  height: 100%;
  .SimulationTrandLedLeft{
    height: 100%;
    border-right: 1px solid #c1c1c1;
    background-color: #e2e6f3;
    .SimulationTrandLedLeftContents{
      height: 100%;
      align-content: flex-start;
      .headerLabel{
        background-color: #f2f4fb;
        color: #404040;
        border-bottom: 1px solid #c1c1c1;
        width: 100%;
        height: 40px;
        padding: 10px 15px 10px 15px;
        label{
          font-size: 15px;
          font-weight: bold;
        }
      }
      
      .simulationTrandLedLeftInput{
        label{
          font-size: 13px;
          font-weight: bold;
        }
        .q-checkbox__label{
          font-size: 15px;
          font-weight: bold;
        }
      }
      .SimulationTrandLedLeftTable{
        margin-bottom: 10px;
        .TrandLedBtn{
          padding: 0px 0px 10px 0px;
          justify-content: space-between;
          align-items: center;
          button{
              background-color: #172041;
              margin-left: 5px;
              color: #ffffff;
          }
          label{
              font-size: 15px;
              font-weight: bold;
          }
        }
      }
      
      .simulationTrandLedLeftInput{
        padding: 10px 0px 10px 0px;

      }
      
      .SimulationTrandLedTable{
        background-color:#ffffff;
        color: #404040;
        th{
          font-size: 13px;
        }
        td{
          font-size: 12px;
        }
      }
      
      .SimulationTrandLedTableHeader{
        background-color: #f2f4fb;
      }
    }
    
    
    .infoBtn{
      background-color: #172041;
      margin-left: 5px;
      color: #ffffff;
    }

    .AddinfoBtn{
      background-color: #3bb3ff;
      margin-left: 5px;
      color: #ffffff;
    }
  }
  
  .SimulationTrandLedRight{
    height: 100%;
    background-color: #e2e6f3;

    .SimulationTrandLedDate{
      .headerLabel{
        background-color: #f2f4fb;
        color: #404040;
        border-bottom: 1px solid #c1c1c1;
        width: 100%;
        height: 40px;
        padding: 10px 15px 10px 15px;
        label{
          font-size: 15px;
          font-weight: bold;
        }
      }

      .SimulationCalendar{
        padding: 15px;
        label{
          font-size: 15px;
          font-weight: bold;
        }
      }
      .calendarIcon{
        align-self: center;
      }
      
      .hideBottom{
        width: 100%;
        margin-bottom: 0px !important;
        .q-field__control{
          .q-field__append{
            border: 0px;
          }
        }
      }
      
      .FromDateCalendar,
      .toDateCalendar{
        position: absolute;
        z-index: 10;
        top: 40px;
      }
      
      .dateBtn{
        margin: 5px;
        width: 80px;
        height: 30px;
        background-color: #172041;
        color:white
      }
    }
    
    .SimulationTrandLedGraph{
      padding: 10px 15px 10px 15px;
      height: calc(100% - 100px);
      label{
        font-size: 1px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
import { useEditStore } from "@/stores/edit-store";
import { useUserStore } from "@/stores/user-store";
import type ZoneSmlTypeLight from "./zone-sml-type-light";

class ZoneSmlType {
  absenceRate = null as null | number;
  autoLightCtrlAbsentYn = "N" as null | string;
  daylightUseRate = null as null | number;
  daylightUseYn= "N";
  faultRate = null as number | null;
  lightCalcTypeCode = null as string | null;
  lightCtrlSystemCode = null as string | null;
  maintainProcCode = null as string | null;
  scenarioSeq = null as null | number;
  simulTypeCode = null as string | null;
  siteSeq = 0 as number;
  simulStartMonth = "2020-10" as string;
  simulEndMonth = "2021-11" as string;
  unitAreaByLightLoad = null as number | null;
  zoneSeq = 0 as number;
  
  zoneSmlTypeLights?: ZoneSmlTypeLight[];

  constructor(simulTypeCode: string){
    this.simulTypeCode = simulTypeCode;
    this.siteSeq = useEditStore().selectSpaceInfo?.siteSeq as number;
    this.zoneSeq = useEditStore().selectSpaceInfo?.zoneSeq as number;
    this.zoneSmlTypeLights = new Array<ZoneSmlTypeLight>();
  }
}

export default ZoneSmlType;


<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import type {CalendarObject, alertMessageType, simulationLightInfo, SimulAllChartData, SimulationChartDataType} from "@/global/types";
import { useCommonStore } from "@/stores/common-store";
import ZoneSmlTypeLight from "@/models/entity/zone-sml-type-light";
import { useSimulationStore } from "@/stores/simulation-store";
import CommonUtils from "@/utils/common-util";
import { useEditStore } from "@/stores/edit-store";
import type { ApexOptions } from "apexcharts";
import type ZoneSmlType from "@/models/entity/zone-sml-type";
import type { QTable, QTrProps } from "quasar";
import AlertWarningComponent from "../common/AlertWarningComponent.vue";
import SimulationAllGraphComponent from "./SimulationAllGraphComponent.vue";


const commonStore = useCommonStore();
const simulationStore = useSimulationStore();
const editStore = useEditStore();

/**
 * 선택 공간 정보
 */
const selectZone = computed(() => {
  return useEditStore().selectSpaceInfo;
});

watch(selectZone, async () => {
  console.log("simulation watch ------------");
  await simulationStore.getZoneSmlData(selectZone.value?.siteSeq as number, selectZone.value?.zoneSeq as number);


  TLZoneSimul.value = simulationStore.getZoneSmlType("TL");
  SLZoneSimul.value = simulationStore.getZoneSmlType("SL");

  TLlightSetInfos.value = TLZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[];
  SLLightSetInfos.value = SLZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[];

  onReset();
}, {deep: true});

const formSimulationData = {
  calc: "",
  lightOver: 0,
  control: "",
  errorRatio: 0,
  lightInfo: [] as simulationLightInfo[],
  fromDate: "2023-01",
  toDate: "2023-12"
};


const allCommonCode = commonStore.commonList;

/**
 * 시뮬레이션 정보
 */
const simulationData = computed(() => {
  return simulationStore.SimulationInfo;
});

/**
 *  시뮬레이션 타입 정보
 */
// const TLZoneSimul = computed(() => {
//   return simulationStore.getZoneSmlType("TL");
// });
// const SLZoneSimul = computed(() => {
//   return simulationStore.getZoneSmlType("SL");
// });


const TLZoneSimul = ref();
const SLZoneSimul = ref();


const chartOption = ref({} as ApexOptions);
const isLightAdd = ref(false);
const isLightModify = ref(true);
const isFromDateOpen = ref(false);
const isToDateOpen = ref(false);
const lightTableRef = ref({} as QTable);
const modifyLightInfo = ref(new ZoneSmlTypeLight());
const simulationChart = ref(null);
const alertStatic = ref(false);
const alertMessage = ref({} as alertMessageType);

// const TLlightSetInfos = computed(() => {
//   // return TLZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[];
// });
// const SLLightSetInfos = computed(() => {
//   // return SLZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[];
// }

const TLlightSetInfos = ref();
const SLLightSetInfos = ref();

// const selectTL = ref();
// const selectSL = ref();


const label = ref([] as string[]);
const chartData = ref([] as SimulationChartDataType[]);
const predDataTL = ref([] as (number | null)[]);
const predDataSL = ref([] as (number | null)[]);

const diffData = ref([] as (number | null)[]);
const percentageData = ref([] as (number | null)[]);
const allData = ref([] as number[]);

onBeforeMount(async() => {
  let isEmpty = false;

  TLZoneSimul.value = await simulationStore.getZoneSmlType("TL");
  SLZoneSimul.value = await simulationStore.getZoneSmlType("SL");

  TLlightSetInfos.value = TLZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[];
  SLLightSetInfos.value = SLZoneSimul.value.zoneSmlTypeLights as ZoneSmlTypeLight[];

  // if(TLlightSetInfos.value.length > 0){
  //   selectTL.value = TLlightSetInfos.value[0];
  //   console.log(simulationStore);
  //   const simul = simulationStore.selectZoneSml?.zoneSmlTypes?.find(v => v.simulTypeCode === "TL") as ZoneSmlType;
  //   simul.zoneSmlTypeLights = selectTL.value.lightList;
  // }

  // if(SLLightSetInfos.value.length > 0){
  //   selectSL.value = SLLightSetInfos.value[0];
  //   const simul = simulationStore.selectZoneSml?.zoneSmlTypes?.find(v => v.simulTypeCode === "SL") as ZoneSmlType;
  //   simul.zoneSmlTypeLights = selectSL.value.lightList;
  // }
  
  const TLCheckEmptyData = {
    lightCalcTypeCode: TLZoneSimul.value.lightCalcTypeCode,
    unitAreaByLightLoad: TLZoneSimul.value.unitAreaByLightLoad,
    lightCtrlSystemCode: TLZoneSimul.value.lightCtrlSystemCode,
    faultRate: TLZoneSimul.value.faultRate,
    maintainProcCode: TLZoneSimul.value.maintainProcCode,
    simulStartMonth: TLZoneSimul.value.simulStartMonth,
    simulEndMonth: TLZoneSimul.value.simulEndMonth
  };

  const SLCheckEmptyData = {
    lightCalcTypeCode: SLZoneSimul.value.lightCalcTypeCode,
    unitAreaByLightLoad: SLZoneSimul.value.unitAreaByLightLoad,
    lightCtrlSystemCode: SLZoneSimul.value.lightCtrlSystemCode,
    faultRate: SLZoneSimul.value.faultRate,
    maintainProcCode: SLZoneSimul.value.maintainProcCode,
    simulStartMonth: SLZoneSimul.value.simulStartMonth,
    simulEndMonth: SLZoneSimul.value.simulEndMonth
  };

  const TLcheckKeys: (keyof typeof TLCheckEmptyData)[] = Object.keys(TLCheckEmptyData) as (keyof typeof TLCheckEmptyData)[];  
  
  for(let i = 0; i < TLcheckKeys.length; i++){
    if(TLCheckEmptyData[TLcheckKeys[i]] === null || TLCheckEmptyData[TLcheckKeys[i]] === undefined || TLCheckEmptyData[TLcheckKeys[i]] === ""){
      isEmpty = true;
      break;
    }
  }

  const SLcheckKeys: (keyof typeof SLCheckEmptyData)[] = Object.keys(SLCheckEmptyData) as (keyof typeof SLCheckEmptyData)[];  
  
  for(let i = 0; i < SLcheckKeys.length; i++){
    if(SLCheckEmptyData[SLcheckKeys[i]] === null || SLCheckEmptyData[SLcheckKeys[i]] === undefined || SLCheckEmptyData[SLcheckKeys[i]] === ""){
      isEmpty = true;
      break;
    }
  }

  if(isEmpty){
    return;
  }

  onReset();
});

async function onReset(){
  chartData.value = [];
  predDataSL.value = [];
  predDataTL.value = [];
  label.value = [];
  allData.value = [];
  percentageData.value = [];


  await OnProgress("TL");
  await OnProgress("SL");

  await setChartData();
}

const myLocale = {
  /* starting with Sunday */
  days: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
  daysShort: ["일","월","화","수","목","금","토"],
  months: ["1월", "2월", "3월", "4월","5월","6월","7월","8월","9월","10월","11월","12월"],
  monthsShort:  ["1월", "2월", "3월", "4월","5월","6월","7월","8월","9월","10월","11월","12월"],
  firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
  format24h: true,
};

const column = ref([
  {name: "index", label: "idx", field: "index", headerStyle: "display: none"},
  {name: "lightModeCode", label: "방식", align:"center" as const, field: "lightModeCode", headerStyle: "width: 15%"},
  {name: "lightTypeCode", label: "종류", align: "left" as const, field: "lightTypeCode", headerStyle: "width: 40%; text-align: center"},
  {name: "consumpPower", label: "소비", align: "center" as const, field: "consumpPower", headerStyle: "width: 15%; "},
  {name: "lightQty", label: "개수", align: "center" as const, field: "lightQty", headerStyle: "width: 15%;"},
  {name: "lightRate", label: "비율", align: "center" as const, field: "lightRate", headerStyle: "width: 15%;"}
]);

function fromDateSelect(DateString: string, pickDateKind: string, DateObject: CalendarObject){
  if(pickDateKind === "month"){
    isFromDateOpen.value = false;
    formSimulationData.fromDate = DateString;
    const year = DateObject.month !== 1 ? DateObject.year + 1 : DateObject.year;
    
    const months = (month: number) => {
      if(month - 1 === 0){
        return 12;
      }
      
      if(month - 1 < 10){
        return `0${month - 1}`;
      }
      
      return month - 1;
    };
    
    formSimulationData.toDate = `${year}-${months(DateObject.month as number)}`;
  }
}

function selectFromCalendar(){
  isFromDateOpen.value = !isFromDateOpen.value;
  isToDateOpen.value = false;
}

function selectToCalendar(){
  isFromDateOpen.value = false;
  
  isToDateOpen.value = !isToDateOpen.value;
  
}


function getOptionCode(code: string){
  return commonStore.getOptionTypes(code);
}

function alertFalse(value: boolean){
  alertStatic.value = value;
}

async function OnProgress(type: string){

  diffData.value = [];
  
  const getSimulationData = simulationData.value;
  
  getSimulationData.simulTypeCode = type;
  getSimulationData.zoneSml = simulationStore.selectZoneSml;
  // getSimulationData.startMonth = formSimulationData.fromDate;
  // getSimulationData.endMonth = formSimulationData.toDate;

  const res = await simulationStore.setSimulationData(selectZone.value?.siteSeq as number, selectZone.value?.zoneSeq as number, getSimulationData);


  
  for(let i = 0; i < (res?.consumes.length as number); i++){
    if(res.consumes[i].month === "합계"){
      allData.value.push(Number(res.consumes[i].predConsume.toFixed(2)));
      
    } else {
      if(type === "TL") predDataTL.value.push(Number(res.consumes[i].predConsume?.toFixed(2)));
      else if(type === "SL") predDataSL.value.push(Number(res.consumes[i].predConsume?.toFixed(2)));
    }
  }


}

async function setChartData(){
  console.log("setchartdata ------------------");

  
  if(predDataTL.value.length > predDataSL.value.length){
    for(let i = predDataSL.value.length; i < predDataTL.value.length; i++){
      predDataSL.value.push(null);
    }
  } else if(predDataTL.value.length < predDataSL.value.length){
    for(let i = predDataTL.value.length; i < predDataSL.value.length; i++){
      predDataTL.value.push(null);
    }
  }

  for(let i = 0; i < predDataSL.value.length; i++){
    if(predDataSL.value[i] !== null && predDataTL.value[i] !== null){
      diffData.value.push(Number((Number(predDataTL.value[i]) - Number(predDataSL.value[i])).toFixed(2)));
    } else {
      diffData.value.push(null);
    }
  }


  for(let i = 0; i < predDataSL.value.length; i++){
    label.value.push(`${i+1}`);
  }

  label.value.push("합계");

  for(let i = 0; i < predDataSL.value.length; i++){
    if(predDataTL.value[i] !== null && predDataSL.value[i] !== null){
      percentageData.value.push((Number(predDataTL.value[i]) - Number(predDataSL.value[i])) / Number(predDataTL.value[i]) * 100);
    } else {
      percentageData.value.push(null);
    }
  }

  chartData.value.push({
    name: "전통조명",
    data: predDataTL.value as number[]
  });

  chartData.value.push({
    name: "스마트조명",
    data: predDataSL.value as number[]
  });

  
  

}

function changeLightInfo(row: ZoneSmlTypeLight, props: QTrProps["props"]){
  isLightAdd.value = false;
  lightTableRef.value.selected?.splice(0);

  if(props.selected === false){
    props.selected = true;
  }
  
  const lightRows = document.querySelectorAll("[id*=\"lightRow\"]");

  for(let i = 0; i < lightRows.length; i++){
    const lightRow = lightRows[i] as HTMLElement;
    if(lightRow.id === `lightRow${row.lightSeq}`){
      lightRow.style.backgroundColor = "#e2e6f3";
      isLightModify.value = true;
      modifyLightInfo.value = CommonUtils.deepClone(row);
    } else {
      lightRow.style.backgroundColor = "#ffffff";
    }
  }
  // modifyLightInfo.value = CommonUtils.deepClone(detectZoneSimul.value.zoneSmlTypeLights?.find(v => v.lightSeq === row.lightSeq) as ZoneSmlTypeLight);
}

// async function searchAllOnProgress(){
//   AllChartData.value = [];

//   await OnProgress("TL");
//   await OnProgress("SL");
// }


</script>

<template>
  <div class="SimulationTrandLedComponent row">
    <AlertWarningComponent :static-value="alertStatic" :confirm-value="false" :message="alertMessage" @change="alertFalse"></AlertWarningComponent>
    <div class="SimulationTrandLedLeft col-sm-12 col-md-4">
      <div class="SimulationTrandLedLeftContents row">
        <div class="headerLabel">
          <label>시뮬레이션 입력</label>
        </div>
        <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>

        <!-- <div class="simulationTrandLedLeftInput row">
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>계산 방식</label>
            <q-select v-model="TLZoneSimul.lightCalcTypeCode" :options="getOptionCode('lightCalcTypeCode')" dense :dark="false" label-color="black" :popup-content-style="{color: '#000000'}" outlined square bg-color="white" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>기준 조명 부하[W/㎡]</label>
            <q-input type="number" v-model.number="TLZoneSimul.unitAreaByLightLoad" dense :dark="false" outlined square bg-color="white"></q-input>
          </div>
          
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>주광 제어 시스템</label>
            <q-select v-model="TLZoneSimul.lightCtrlSystemCode" :options="getOptionCode('lightCtrlSystemCode')" dense :dark="false" outlined square bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>월별 고장 률[%]</label>
            <q-input  type="number" v-model.number="TLZoneSimul.faultRate" dense :dark="false" outlined square bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>유지 보수</label>
            <q-select v-model="TLZoneSimul.maintainProcCode" :options="getOptionCode('maintainProcCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs" :style="{alignSelf: 'center', textAlign: 'center', paddingRight: '30px'}">
            <q-checkbox size="xs" class="col-12" v-model="TLZoneSimul.daylightUseYn" :dark="false" label="주광 이용 여부" :true-value="'Y'" :false-value="'N'"/>
          </div>
        </div>
       -->
        <div class="SimulationTrandLedLeftTable col-12 q-py-xs q-px-md">
          <div class="row TrandLedBtn">
            <label>전통 조명 정보</label>
            <!-- <q-select
              class="col-6"
              v-model="selectTL"
              bg-color="white"
              :dark="false"
              :options="TLlightSetInfos"
              option-label="lightSetName"
              emit-value
              map-options
              label-color="#313131"
              popup-content-style="color: #000000"
            >
            </q-select> -->
          </div>

          <q-table 
            hide-pagination 
            square 
            flat
            ref="lightTableRef"
            :columns="column"
            :visible-columns="column.map(v => v.field !== 'index' ? v.field : undefined)" 
            :rows="TLlightSetInfos" 
            :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
            :rows-per-page-options="[0]" 
            :dark="false" 
            no-data-label="추가된 데이터가 없습니다." 
            table-class="SimulationTrandLedTable" 
            table-header-class="SimulationTrandLedTableHeader" 
          >
       
          <template v-slot:body="props" >
            <tr :id="`lightRow${props.row.lightSeq}`" :style="{textAlign: 'center', cursor:'pointer'}" @click="changeLightInfo(props.row, props)">
              <td>{{commonStore.getFindCodeToName('lightModeCode', props.row.lightModeCode) }}</td>
              <td>{{commonStore.getFindCodeToName('lightTypeCode',props.row.lightTypeCode) }}</td>
              <td>{{props.row.consumpPower}}</td>
              <td>{{props.row.lightQty}}</td>
              <td>{{props.row.lightRate}}</td>
            </tr>
          </template>
        </q-table>
      </div>
      
      <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>
      
      <!-- <div class="simulationTrandLedLeftInput row">
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>계산 방식</label>
            <q-select v-model="SLZoneSimul.lightCalcTypeCode" :options="getOptionCode('lightCalcTypeCode')" dense :dark="false" label-color="black" :popup-content-style="{color: '#000000'}" outlined square bg-color="white" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>기준 조명 부하[W/㎡]</label>
            <q-input type="number" v-model.number="SLZoneSimul.unitAreaByLightLoad" dense :dark="false" outlined square bg-color="white"></q-input>
          </div>
          
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>주광 제어 시스템</label>
            <q-select v-model="SLZoneSimul.lightCtrlSystemCode" :options="getOptionCode('lightCtrlSystemCode')" dense :dark="false" outlined square bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>월별 고장 률[%]</label>
            <q-input  type="number" v-model.number="SLZoneSimul.faultRate" dense :dark="false" outlined square bg-color="white"></q-input>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs">
            <label>유지 보수</label>
            <q-select v-model="SLZoneSimul.maintainProcCode" :options="getOptionCode('maintainProcCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
          </div>
          <div class="col-md-12 col-lg-4 q-py-xs q-px-xs" :style="{alignSelf: 'center', textAlign: 'center', paddingRight: '30px'}">
            <q-checkbox size="xs" class="col-12" v-model="SLZoneSimul.daylightUseYn" :dark="false" label="주광 이용 여부" :true-value="'Y'" :false-value="'N'"/>
          </div>
        </div> -->

      <div class="SimulationTrandLedLeftTable col-12 q-py-xs q-px-md">
          <div class="row TrandLedBtn">
            <label>스마트조명 정보</label>
            <!-- <q-select
              class="col-6"
              v-model="selectSL"
              bg-color="white"
              :dark="false"
              :options="SLLightSetInfos"
              option-label="lightSetName"
              emit-value
              map-options
              label-color="#313131"
              popup-content-style="color: #000000"
            >
            </q-select> -->
          </div>

          <q-table 
            hide-pagination 
            square 
            flat
            ref="lightTableRef"
            :columns="column"
            :visible-columns="column.map(v => v.field !== 'index' ? v.field : undefined)" 
            :rows="SLLightSetInfos" 
            row-key="lightSeq" 
            :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
            :rows-per-page-options="[0]" 
            :dark="false" 
            no-data-label="추가된 데이터가 없습니다." 
            table-class="SimulationTrandLedTable" 
            table-header-class="SimulationTrandLedTableHeader" 
          >
     
          <template v-slot:body="props" >
            <tr :id="`lightRow${props.row.lightSeq}`" :style="{textAlign: 'center', cursor:'pointer'}" @click="changeLightInfo(props.row, props)">
              <td>{{commonStore.getFindCodeToName('lightModeCode', props.row.lightModeCode) }}</td>
              <td>{{commonStore.getFindCodeToName('lightTypeCode',props.row.lightTypeCode) }}</td>
              <td>{{props.row.consumpPower}}</td>
              <td>{{props.row.lightQty}}</td>
              <td>{{props.row.lightRate}}</td>
            </tr>
          </template>
        </q-table>
      </div>
    </div>
  </div>
  <div class="SimulationTrandLedRight col-sm-12 col-md-8">
    <div class="SimulationTrandLedDate row items-center" :style="$q.screen.md ? {height: 'auto'} : {}">
      <div class="headerLabel">
        <label>시뮬레이션 실행</label>
      </div>
      <!-- <div class="SimulationCalendar">
        <label :style="{marginRight: '5px'}">시뮬레이션 기간 : </label>
      </div>
      <div class="row col-md-12 col-lg-3">
        <q-input class="hideBottom" v-model="formSimulationData.fromDate" :dark="false" dense outlined square>
          <template v-slot:append>
            <q-icon class="calendarIcon" :name="'calendar_month'" color="black" @click="selectFromCalendar"></q-icon>
          </template>
        </q-input>
        
        <q-date class="FromDateCalendar" v-if="isFromDateOpen" v-model="formSimulationData.fromDate" :locale="myLocale" minimal
          mask="YYYY-MM"
          years-in-month-view
          emit-immediately
          default-view="Months"
          @update:model-value="fromDateSelect"
        />
      </div>
      
      <span :style="{marginRight: '10px', marginLeft: '10px', fontSize: '15px', fontWeight: 'bold'}"> ~ </span>
      <div class="row col-md-12 col-lg-3">
        <q-input class="hideBottom" v-model="formSimulationData.toDate" :dark="false" dense outlined square disable>
          <template v-slot:append>
            <q-icon class="calendarIcon" :name="'calendar_month'" color="black" @click="selectToCalendar" disable></q-icon>
          </template>
        </q-input>
        
        <q-date class="toDateCalendar" v-if="isToDateOpen" v-model="formSimulationData.toDate" :locale="myLocale" minimal
        mask="YYYY-MM"
        disable
        />
      </div>
      <div class="row">
        <q-btn class="dateBtn" square @click="searchAllOnProgress">
          실행
        </q-btn>
      </div> -->
      <q-separator class="col-12" :dark="false" size="1px" color="#c1c1c1"></q-separator>

    </div>
    <div class="SimulationTrandLedGraph">
      <!-- <label>시뮬레이션 결과 비교</label> -->
      <SimulationAllGraphComponent 
        v-if="chartData.length > 0"
        ref="simulationChart" 
        :chartData="chartData"
        :label="label"
        :diffData="diffData"
        :allData="allData"
        :percentageData="percentageData"
        >
      </SimulationAllGraphComponent>
    </div>
  </div>
</div>
</template>

<style lang="scss">
.SimulationTrandLedComponent{
  background-color: #f2f4fb;
  color: #404040;
  height: 100%;
  .SimulationTrandLedLeft{
    height: 100%;
    border-right: 1px solid #c1c1c1;
    background-color: #e2e6f3;
    .SimulationTrandLedLeftContents{
      height: 100%;
      align-content: flex-start;
      .headerLabel{
        background-color: #f2f4fb;
        color: #404040;
        border-bottom: 1px solid #c1c1c1;
        width: 100%;
        height: 40px;
        padding: 10px 15px 10px 15px;
        label{
          font-size: 15px;
          font-weight: bold;
        }
      }
      
      .simulationTrandLedLeftInput{
        label{
          font-size: 13px;
          font-weight: bold;
        }
        .q-checkbox__label{
          font-size: 15px;
          font-weight: bold;
        }
      }
      .SimulationTrandLedLeftTable{
        margin-bottom: 10px;
        .TrandLedBtn{
          padding: 0px 0px 10px 0px;
          justify-content: space-between;
          align-items: center;
          button{
              background-color: #172041;
              margin-left: 5px;
              color: #ffffff;
          }
          label{
              font-size: 15px;
              font-weight: bold;
          }
        }
      }
      
      .simulationTrandLedLeftInput{
        padding: 10px 0px 10px 0px;

      }
      
      .SimulationTrandLedTable{
        background-color:#ffffff;
        color: #404040;
        th{
          font-size: 13px;
        }
        td{
          font-size: 12px;
        }
      }
      
      .SimulationTrandLedTableHeader{
        background-color: #f2f4fb;
      }
    }
    
    
    .infoBtn{
      background-color: #172041;
      margin-left: 5px;
      color: #ffffff;
    }

    .AddinfoBtn{
      background-color: #3bb3ff;
      margin-left: 5px;
      color: #ffffff;
    }
  }
  
  .SimulationTrandLedRight{
    height: 100%;
    background-color: #e2e6f3;

    .SimulationTrandLedDate{
      .headerLabel{
        background-color: #f2f4fb;
        color: #404040;
        border-bottom: 1px solid #c1c1c1;
        width: 100%;
        height: 40px;
        padding: 10px 15px 10px 15px;
        label{
          font-size: 15px;
          font-weight: bold;
        }
      }

      .SimulationCalendar{
        padding: 15px;
        label{
          font-size: 15px;
          font-weight: bold;
        }
      }
      .calendarIcon{
        align-self: center;
      }
      
      .hideBottom{
        width: 100%;
        margin-bottom: 0px !important;
        .q-field__control{
          .q-field__append{
            border: 0px;
          }
        }
      }
      
      .FromDateCalendar,
      .toDateCalendar{
        position: absolute;
        z-index: 10;
        top: 40px;
      }
      
      .dateBtn{
        margin: 5px;
        width: 80px;
        height: 30px;
        background-color: #172041;
        color:white
      }
    }
    
    .SimulationTrandLedGraph{
      padding: 10px 15px 10px 15px;
      height: calc(100% - 100px);
      label{
        font-size: 1px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
<script setup lang="ts">
import { computed, ref, type PropType, onUpdated, nextTick } from "vue";
import type { SimulAllChartData, SimulationChartDataType, SimulationTableDataForm, stringObject } from "@/global/types";
import type ApexCharts from "apexcharts";
import type { QTableColumn } from "quasar";



const props = defineProps({
  chartData: Array<SimulationChartDataType>,
  label: Array<string>,
  diffData: Array<number | null>,
  allData: Array<number>,
  percentageData: Array<number | null>
});
    
const simulationChart = ref({} as ApexCharts);

const chartLabel = computed(() => {
  const result = [] as string[];

  for(let i = 0; i < (props.label as string[]).length; i++){
    if((props.label as string[])[i] !== "합계") result.push((props.label as string[])[i] as string);
  }

  return result;
});

const sendChartData = computed(() => {
  const originChartData = chartData.value as SimulationChartDataType[];
  const result = [] as SimulationChartDataType[];

  for(let i = 0; i < originChartData.length; i++){
    originChartData[i].data = originChartData[i].data.splice(-1);
    result.push(originChartData[i]);
  }

  return result;
});

const column = computed(() => {
  return tableColumn();
});

defineExpose({simulationChart});


const chartOption = ref( {
  colors: ["#233471", "#3bb3ff", "#6a2681", "#137f69"],
  chart: {
    type: "bar",
    height: 350,
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    }
  },
  title: {
    text: "시뮬레이션 데이터 비교",
    align: "center",
    style: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#404040"
    }
  },
  legend: {
    show: true,
    position: "top",
    horizontalAlign: "left",
    offsetY: -30,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["fff"]
  },
  xaxis: {
    categories: chartLabel.value
  },
  fill: {
    opacity: 1
  }
});


function tableColumn(){
  const column = [] as QTableColumn[];
                                                                      
  column.push({
    name: "구분",
    label: "구분",
    align: "center",
    field: "구분",                                                                   
  });

  // for(let i = 0; i < (chartOption.value?.xaxis?.categories as string[]).length; i++){
  //   column.push({
  //     name: (chartOption.value?.xaxis?.categories as string[])[i] as string,
  //     label: (chartOption.value?.xaxis?.categories as string[])[i] as string,
  //     align: "center",
  //     field: (chartOption.value?.xaxis?.categories as string[])[i] as string,
  //   });
  // }

  for(let i = 0; i < (props.label as string[]).length; i++){
    column.push({
      name: (props.label as string[])[i] as string,
      label: (props.label as string[])[i] as string,
      align: "center",
      field: (props.label as string[])[i] as string,
    });
  }

                                                                      
  return column;
}
const tableData = computed(() => {
  if((props.chartData as SimulationChartDataType[]).length > 0){
    return tableDataSet();
  } 
  return [];
  // return tmpTableData;
});


const chartData = computed(() => {
  return props.chartData;
});



// function dataSetToChart(){
// if(tableData.value.length <= 0) return [];

// const chartData = [];

// for(let i = 0; i <(props.AllChartData as SimulAllChartData[]).length; i++){
//   let type = "";

//   if((props.AllChartData as SimulAllChartData[])[i].type === "TL") type = "전통조명 소요량";
//   else if((props.AllChartData as SimulAllChartData[])[i].type === "SL") type = "스마트조명 소요량";

//   chartData.push({
//     name: type,
//     data: (props.AllChartData as SimulAllChartData[])[i].predData.slice(0, (props.AllChartData as SimulAllChartData[])[i].predData.length - 1)
//   });
// }


// chartData.push({
//   name: "실 사용량",
//   data: props.realData?.slice(0, props.realData.length - 1)
// });

// return chartData;
// }
                                                                    
function tableDataSet(){
  // const tableData = [];

  const tableData = [] as SimulationTableDataForm[];
                                                                      
  if(chartData.value !== undefined){
    for(let i = 0; i < chartData.value.length; i++){
      tableData.push({"구분": chartData.value[i]?.name});
      for(let j = 1; j < column.value.length; j++){
        if(chartData.value[i]?.data[j-1] !== null) tableData[i][column.value[j].field as string] = chartData.value[i]?.data[j-1]?.toFixed(2);
        else tableData[i][column.value[j].field as string] = null;
      }  
    }
  }
                   
  for(let i = 0; i < tableData.length; i++){
    tableData[i]["합계"] = (props.allData as number[])[i]?.toFixed(2);
  }

  const diff = {} as {[x:string] : string | number | null};
  for(let i = 0; i < column.value.length; i++){
    if((props.diffData as number[])[i-1] !== null) diff[column.value[i].field as string] = (props.diffData as number[])[i-1]?.toFixed(2);
    else diff[column.value[i].field as string] = null;
  }

  diff["구분"] = "차이";
                                                                                                                                          
  tableData.push(diff);
                                  
  const allTLProd = (tableData.find(v => v["구분"] === "전통조명") as SimulationTableDataForm)["합계"];
  const allSLProd = (tableData.find(v => v["구분"] === "스마트조명") as SimulationTableDataForm)["합계"];

  diff["합계"] = (Number(allTLProd) - Number(allSLProd)).toFixed(2);

  const percentage = {} as  {[x:string] : string | number | null};

  for(let i = 0; i < column.value.length; i++){
    if((props.percentageData as number[])[i-1] !== null) percentage[column.value[i].field as string] = (props.percentageData as number[])[i-1]?.toFixed(2) + "%";
    else percentage[column.value[i].field as string] = null;
  }

  percentage["구분"] = "절감율";

  percentage["합계"] = ((Number(allTLProd) - Number(allSLProd)) / Number(allTLProd) * 100).toFixed(2) + "%";

  tableData.push(percentage);




  // diff["절감률"] = (allSLProd - allSLProd) / allSLProd * 100;

                                                                                                                                          
  return tableData;   
                                                                      
      
  // tableData.push({"구분": "실사용량"});

  // const findTable = tableData.find(v => v["구분"] === "실사용량") as stringObject;

  // for(let i = 0; i < (props.realData as number[]).length; i++){
  //   findTable[column.value[i+1].field as string] = (props.realData as number[])[i];
  // }

  // if(props.AllChartData){
  //   for(let i = 0; i < props.AllChartData.length; i++){
  //     let type = "";
  //     if(props.AllChartData[i].type === "TL") type = "전통조명";
  //     else if(props.AllChartData[i].type === "SL") type = "스마트조명";
  //     tableData.push({"구분": `${type} 소요량`});

  //     let findTable = tableData.find(v => v["구분"] === `${type} 소요량`) as stringObject;

  //     for(let j = 0; j < props.AllChartData[i].predData.length; j++){
  //       findTable[column.value[j+1].field as string] = props.AllChartData[i].predData[j].toFixed(2);
  //     }

  //     tableData.push({"구분": `${type} 차이`});

  //     findTable =  tableData.find(v => v["구분"] === `${type} 차이`) as stringObject; 

  //     for(let j = 0; j < props.AllChartData[i].diffData.length; j++){
  //       findTable[column.value[j+1].field as string] = props.AllChartData[i].diffData[j].toFixed(2);
  //     }

  //     tableData.push({"구분": `${type} 추정값`});

  //     findTable =  tableData.find(v => v["구분"] === `${type} 추정값`) as stringObject;

  //     findTable["mbe"] = props.AllChartData[i].mbe.toFixed(2);
  //     findTable["cvrmse"] = props.AllChartData[i].cvrmse.toFixed(2);

  //   }


  //   for(let i = 0; i < props.AllChartData.length; i++){
  //     let type = "";
  //     if(props.AllChartData[i].type === "TL") type = "전통조명";
  //     else if(props.AllChartData[i].type === "SL") type = "스마트조명";
  //     tableData.push({"구분": `${type} 차이`});
  //     const findTable = tableData.find(v => v["구분"] === `${type} 차이`);
  //   }

    
  // }




  // const sortOrder = ["실사용량", "전통조명", "스마트조명"];

  // tableData.sort((a,b) => {
  //   const matchOrderA = sortOrder.findIndex(term => a["구분"].includes(term));
  //   const matchOrderB = sortOrder.findIndex(term => b["구분"].includes(term));


  //   // 우선순위가 있는 항목끼리 비교 (숫자값이 -1이 아니면 순위 있음)
  //   if (matchOrderA !== -1 && matchOrderB !== -1) {
  //     return matchOrderA - matchOrderB;
  //   }

  //   // 둘 다 우선순위에 포함되지 않으면 순서를 변경하지 않음
  //   if (matchOrderA === -1 && matchOrderB === -1) {
  //     return 0;
  //   }

  //   // 하나만 우선순위가 포함되어 있으면 그 항목이 먼저 오도록
  //   return matchOrderA === -1 ? 1 : -1;

  // });


  // return tableData;
                                                          
}
                                                                    
                
// const columns = [
//   {
//     name:"type",
//     require: false,
//     label: "항목",
//     align: "left"
//   },
//   {
//     name: "name",
//     required: true,
//     label: "Dessert (100g serving)",
//     align: "left",
//     field: row => row.name,
//     format: val => `${val}`,
//     sortable: true
//   },
//   { name: "calories", align: "center", label: "Calories", field: "calories", sortable: true },
//   { name: "fat", label: "Fat (g)", field: "fat", sortable: true },
//   { name: "carbs", label: "Carbs (g)", field: "carbs" },
//   { name: "protein", label: "Protein (g)", field: "protein" },
//   { name: "sodium", label: "Sodium (mg)", field: "sodium" },
//   { name: "calcium", label: "Calcium (%)", field: "calcium", sortable: true, sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) },
//   { name: "iron", label: "Iron (%)", field: "iron", sortable: true, sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) }
// ];

                                                                                               
</script>
                                                                                                  
<template>

  <div class="SimulationGraphComponent">
    <div class="ChartArea">
      <apexchart ref="simulationChart" type="bar" height="350" :options="chartOption" :series="chartData"></apexchart>
    </div>
    <!-- <q-separator class="q-mb-md" :dark="false" size="1px" color="#c1c1c1"></q-separator> -->
    <!-- <q-table 
        :columns="column" 
        :rows="tableData" 
        hide-pagination 
        :dark="false" 
        :sortable="false"
        square 
        table-class="graphTable" 
        table-header-class="graphTableHeader" 
        dense
      >
      
      </q-table> -->


    <div class="tableArea">
      <q-table 
        :columns="column" 
        :rows="tableData" 
        :dark="false" 
        :sortable="false"
        separator="cell" 
        square 
        table-class="graphTable" 
        table-header-class="graphTableHeader" 
        :hide-pagination="true"
        :rows-per-page-options="[0]"
      >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td class="tableCategory">{{ props.row["구분"] }}</q-td>
          <template v-for="data in Object.keys(props.row)" :key="`${data}`">
            <template v-if="data !== '구분'">
              <q-td v-if="props.row[data] !== null" :style="{textAlign: 'center'}">{{ props.row[data] }}</q-td>
              <q-td v-else :style="{textAlign: 'center'}">{{ "-" }}</q-td>
            </template>
          </template>
        </q-tr>
      </template>
      <!-- <template v-slot:header="props">
        <q-tr :props="props" class="graphTableHeader">
          <template v-for="(col, i) in props.cols" :key="`chartCol${i}`">
            <q-th v-if="col.name === '구분'" colspan="2">{{ col.name }}</q-th>
            <q-th v-else>{{ col.name }}</q-th>
          </template>
        </q-tr>
      </template> -->
      <!-- <template v-slot:body="props">
         <q-tr>
          <template  v-for="data in Object.keys(props.row)" :key="`${data}`">
            <template v-if="props.row[data].toString().includes('스마트조명')">
              <template v-if="props.rowIndex === 4">
                <q-td class="tableCategory" rowspan="3" >
                  {{ '스마트조명' }}
                </q-td>
                <q-td class="tableCategory">
                    {{ props.row[data].split("스마트조명")[1] }}
                  </q-td>
              </template>
              <template v-else-if="props.row[data].includes('추정값')">
                <q-td class="bottomRowTd subjectTd" :style="{borderLeft: '1px'}">
                  MBE
                </q-td>
                <q-td class="bottomRowTd" colspan="6">
                  {{ props.row.mbe }}
                </q-td>
                <q-td class="bottomRowTd subjectTd">
                  CVRMSE
                </q-td>
                <q-td class="bottomRowTd" colspan="6">
                  {{ props.row.cvrmse }}
                </q-td>
              </template>
              <template v-else>
                <q-td class="tableCategory">
                  {{ props.row[data].split("스마트조명")[1] }}
                </q-td>
              </template>
           
            </template>
            <template v-else-if="props.row[data].toString().includes('전통조명')">
              <template v-if="props.rowIndex === 1">
                <q-td class="tableCategory" rowspan="3" >
                  {{ '전통조명' }}
                </q-td>
                <q-td class="tableCategory">
                    {{ props.row[data].split("전통조명")[1] }}
                  </q-td>
              </template>
              <template v-else-if="props.row[data].includes('추정값')">
                <q-td class="bottomRowTd subjectTd">
                  MBE
                </q-td>
                <q-td class="bottomRowTd" colspan="6">
                  {{ props.row.mbe }}
                </q-td>
                <q-td class="bottomRowTd subjectTd">
                  CVRMSE
                </q-td>
                <q-td class="bottomRowTd" colspan="6">
                  {{ props.row.cvrmse }}
                </q-td>
              </template>
              <template v-else>
                <q-td class="tableCategory">
                  {{ props.row[data].split("전통조명")[1] }}
                </q-td>
              </template>
            </template>
            <q-td class="tableCategory" v-else-if="data === '구분'" colspan="2">
              {{ props.row[data] }}
            </q-td>
            <q-td v-else-if="data !== 'mbe' && data !== 'cvrmse'" :style="{textAlign: 'center'}">
              {{ props.row[data] }}
            </q-td>
          </template>
          </q-tr>
      </template> -->

      </q-table>
   
    </div>

    <!-- <div class="tableArea">
      <q-table 
        v-if="tableData.length > 0" 
        row-key="name" :columns="column" 
        :rows="tableData" hide-pagination 
        :dark="false" 
        separator="cell" 
        square 
        table-class="graphTable" 
        table-header-class="graphTableHeader" 
        dense
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td class="graphTableTD" v-for="data in Object.keys(props.row)" :key="`${data}`" :props="props" :style="{backgroundColor: data !== '구분' ? 'transparent' : '#f2f4fb', color: data === '구분' ? '#000000' : 'black', fontSize: '12px'}">
              {{ props.row[data] }}
            </q-td>
          </q-tr>
          
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="1" class="bottomRowTd subjectTd">
              MBE
            </q-td>
            <q-td colspan="5" class="bottomRowTd contentTd">
              {{props.mbeData?.toFixed(2)}}
            </q-td>
            <q-td colspan="3" class="bottomRowTd subjectTd">
              CVRMSE
            </q-td>
            <q-td colspan="5" class="bottomRowTd contentTd">
              {{props.cvrmseData?.toFixed(2)}}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div> -->
    
  </div>
</template>

<style lang="scss">
.SimulationGraphComponent{
  padding: 5px 0px 5px 0px;
  .EmptyChart{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    background-color: #40404033;
  }
  .tableArea{

    .graphTable{
      font-size: 10px;
    }
    
    .graphTableHeader{
      background-color: #f2f4fb;
      color: #404040;   
      th:first-child{
        padding: 4px 8px;
      } 
      th:last-child{
        padding: 4px 8px;
      }
    }
    .graphTableTD{
      padding: 4px 8px !important;
    }
    .bottomRowTd{
      text-align: center;
    }

    .tableCategory{
      background-color: #f2f4fb;
      text-align: center;
    }
    
    .subjectTd{
      background-color: #f2f4fb;
      color: #404040;

    }
    
    .contentTd{
      color: #404040;
    }

  }
}
</style>
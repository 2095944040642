<script setup lang="ts">
import type Profile from "@/models/profile";
import CommonUtils from "@/utils/common-util";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";

const props = defineProps<{
    isSelect: boolean,
    profileData: Profile,
    profileList: Profile[],
    isNew: boolean
}>();

const emits = defineEmits(["reset", "save", "modify", "delete"]);

const copyProfileData = computed(() => {
  return CommonUtils.deepClone(props.profileData) as Profile;
});

const overlayProfile = computed(() => {
  let result = undefined;
  const overlayKey = selectOverlayTemp.value.find(v => v.key === copyProfileData.value.usageProfileCode);

  if(overlayKey){
    result = overlayKey.overlayName;
  } 

  return result;
});

const renderBeforeOverlay = ref();

const $q = useQuasar();

const selectOverlayTemp = ref([] as {
  key: string,
  overlayKey: string,
  overlayName: string
}[]);


function overlayProfileData(data: Profile){

  $q.dialog({
    title: "덮어쓰기",
    message: "선택된 용도프로필 정보로 덮어쓰시겠습니까?",
    cancel: "취소",
    ok: "확인",
  }).onOk(() => {

    const originName = copyProfileData.value.usageProfileName;
    const originKey = copyProfileData.value.usageProfileCode;
    const keyword = Object.keys(copyProfileData);

    for(let i = 0; i < keyword.length; i++){
      copyProfileData.value[keyword[i] as keyof Profile] = data[keyword[i] as keyof Profile] as any;
    }
    copyProfileData.value.usageProfileName = originName;
    copyProfileData.value.usageProfileCode = originKey;

    renderBeforeOverlay.value = data;

    const overlayTemp = selectOverlayTemp.value.find(v => v.key === copyProfileData.value.usageProfileCode);

    if(!overlayTemp){
      selectOverlayTemp.value.push({
        key: copyProfileData.value.usageProfileCode as string,
        overlayKey:  data.usageProfileCode as string,
        overlayName: data.usageProfileName as string,
      });
    } else {
      overlayTemp.overlayKey = data.usageProfileCode as string;
      overlayTemp.overlayName = data.usageProfileName as string;
    }

  }).onCancel(() => {

  });
}

function save(){

  emits("save", copyProfileData.value);
  selectOverlayTemp.value = [];
}

function modify(){

  emits("modify", copyProfileData.value);

  selectOverlayTemp.value = [];
}

function remove(){
  emits("delete", copyProfileData.value.usageProfileCode);

  selectOverlayTemp.value = [];
}

function reset(){
  emits("reset");

  selectOverlayTemp.value = [];

}


</script>

<template>
  <div class="ProfileFormComponent">
    <div class="headerLabel" :style="{display: 'flex'}">
        <label>용도 프로필 항목</label>
        <div class="importArea" :style="{display: 'flex', marginLeft: '100px', alignItems: 'center'}">
          <label>덮어쓰기</label>
          <q-select
            :style="{width: '200px', marginLeft: '10px'}"
            v-model="overlayProfile"
            :options="props.profileList" 
            dense 
            square
            hide-bottom-space 
            outlined 
            :dark="false" 
            :filled="false"
            bg-color="white" 
            option-label="usageProfileName"
            label-color="black" 
            :popup-content-style="{color: '#000000'}" 
            map-options
            emit-value
            @update:model-value="overlayProfileData"
            :disable="!copyProfileData"
          >
          </q-select>
        </div>
     
    </div>
    <div class="importProfile">
    </div>

    <div class="ProfileContent row" v-if="copyProfileData">
      <div class="col-12 q-py-sm q-px-md">
          <q-btn class="col-2" color="negative" @click="remove">삭제</q-btn>
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>명칭</span>
        <q-input v-model="copyProfileData.usageProfileName" placeholder="명칭" dense :dark="false" outlined square bg-color="white" label-color="#404040" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>시작시간</span>
        <q-input v-model="copyProfileData.useStartTime" placeholder="시작시간" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>종료시간</span>
        <q-input v-model="copyProfileData.useEndTime" placeholder="종료시간" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>하루 사용시간</span>

        <q-input v-model="copyProfileData.dailyUsageTime" placeholder="하루 사용시간" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>연간 사용 일수</span>
        <q-input v-model="copyProfileData.annualUseDays" placeholder="하루 연간 사용 일수" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>연간 낮 사용 시간</span>
        <q-input v-model="copyProfileData.annualUseDayHours" placeholder="연간 낮 사용 시간" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>연간 밤 사용 시간</span>
        <q-input v-model="copyProfileData.annualUseNightHours" placeholder="연간 밤 사용 시간" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>요구 조도</span>
        <q-input v-model="copyProfileData.reqIlluminance" placeholder="요구 조도" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>작업면 높이</span>
        <q-input v-model="copyProfileData.workSurfaceHeight" placeholder="작업면 높이" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>조명 감수 계수</span>
        <q-input v-model="copyProfileData.lightReductCoeff" placeholder="조명 감수 계수" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>조명 상대적 부재율</span>
        <q-input v-model="copyProfileData.lightRelativeAbsenceRate" placeholder="조명 상대적 부재율" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>실내 인덱스</span>
        <q-input v-model="copyProfileData.indoorIndex" placeholder="실내 인덱스" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>조명 운전 감소 계수</span>
        <q-input v-model="copyProfileData.lightOperReductCoeff" placeholder="조명 운전 감소 계수" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>수직면 기준 순응 계수</span>
        <q-input v-model="copyProfileData.vertifaceLightCoeff" placeholder="수직면 기준 순응 계수" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="col-3 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <span>용도 종류</span>
        <q-input v-model="copyProfileData.usageType" placeholder="용도 종류" dense :dark="false" outlined square bg-color="white" :style="{marginBottom: '0'}" />
      </div>
      <div class="row col-12 q-py-sm q-px-md" :style="{alignItems: 'center'}">
        <div class="col-6 q-py-sm q-px-md" v-if="props.isNew">
          <q-btn class="submitBtn" color="primary" @click="save">저장</q-btn>
        </div>
        <div class="col-6 q-py-sm q-px-md" v-else>
          <q-btn class="submitBtn" color="primary" @click="modify">수정</q-btn>
        </div>
        <div class="col-6 q-py-sm q-px-md">
          <q-btn class="submitBtn" color="grey" @click="reset">취소</q-btn>
        </div>
      </div>
    </div>
  </div>

    <!-- <q-drawer v-model="props.isSelect" class="ProfileFormComponent justify-between" show-if-above overlay side="right" :width="500" :breakpoint="700">
      <div class="headerLabel">
          <label>용도 프로필 수정</label>
      </div>
  
      
      <div v-if="copyProfileData !== undefined">
                <div>
                    <label>명칭</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.usageProfileName"></q-input>
                </div>
                <div>
                    <label>시작시간</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.useStartTime"></q-input>
                </div>
                <div>
                    <label>종료시간</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.useEndTime"></q-input>
                </div>
                <div>
                    <label>하루 사용시간</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.dailyUsageTime"></q-input>
                </div>
                <div>
                    <label>연간 사용 일수</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.annualUseDays"></q-input>
                </div>
                <div>
                    <label>연간 낮 사용 시간</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.annualUseDayHours"></q-input>
                </div>
                <div>
                    <label>연간 밤 사용 시간</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.annualUseNightHours"></q-input>
                </div>
                <div>
                    <label>요구 조도</label>
                    <q-input 
                    square dense outlined
                    v-model="copyProfileData.reqIlluminance"></q-input>
                </div>
                <div>
                    <label>작업면 높이</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.workSurfaceHeight"></q-input>
                </div>
                <div>
                    <label>조명 감수 계수</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.lightReductCoeff"></q-input>
                </div>
                <div>
                    <label>조명 상대적 부재율</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.lightRelativeAbsenceRate"></q-input>
                </div>
                <div>
                    <label>실내 인덱스</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.indoorIndex"></q-input>
                </div>
                <div>
                    <label>조명 운전 감소 계수</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.lightOperReductCoeff"></q-input>
                </div>
                <div>
                    <label>수직면 기준 순응 계수</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.vertifaceLightCoeff"></q-input>
                </div>
                <div>
                    <label>용도 종류</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.usageType"></q-input>
                </div>
                <div>
                    <label>조명 점등 시간</label>
                    <q-input square dense outlined
                    v-model="copyProfileData.lightingHours"></q-input>
                </div>
            </div>
    </q-drawer> -->

</template>

<style lang="scss">
.ProfileFormComponent{
  .ProfileContent{
    span{
      color: #404040
    }
    button{
      span{
        color: white !important;
      }
    }
  }

  .submitBtn{
    width: 100%;
    span{
      color: white !important
    }
  }
}
</style>
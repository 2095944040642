<script setup lang="ts">
import ImageFile from "@/models/entity/image-file";
import Site from "@/models/site";
import SiteSetting from "@/models/site-setting";
import CommonUtils from "@/utils/common-util";

import { useCommonStore } from "@/stores/common-store";
import { useSettingStore } from "@/stores/setting-store";
import { useSiteStore } from "@/stores/site-store";
import { computed, reactive, ref, onBeforeMount, onMounted, type PropType } from "vue";

import type { OptionsType, } from "@/global/types";


const props = defineProps({
  siteModal: Boolean,
  siteData: Site,
  isModify: Boolean,
  siteTypeOptions: {} as PropType<OptionsType[]>,
  districtOptions: {} as PropType<OptionsType[]>
});

const emitData = defineEmits(["update:siteModal"]);

const siteStore = useSiteStore();
const settingStore = useSettingStore();
const commonStore = useCommonStore();

const test = ref("");
const file = ref(new File([], ""));
const converFile = ref("");
const newSiteSettings = ref(new Site());
const previewImageFile = ref("");


const newSiteData = computed(() => {
  return props.siteData as Site;
});


const convertImageFilePath = computed(() => {
  return converFile.value;
});

const radioOptions = [] as OptionsType[];
const districtOptions = [] as OptionsType[];

if(newSiteData.value.imageFileSeq !== null){

}

onBeforeMount(() => {
  console.log("child before mount");
});

onMounted(() => {
  console.log("child mount");
  setRadioOptions();
  districtSelectOption();
});

function setRadioOptions(){
  const siteClsfyCode = props.siteTypeOptions as OptionsType[];

  if(siteClsfyCode !== undefined){
    for(let i = 0; i < siteClsfyCode.length; i++){
      radioOptions.push(siteClsfyCode[i]);
    }
  }
}

function districtSelectOption(){
  console.log("make districtselectoptions");
  const districts = props.districtOptions as OptionsType[];

  if(districts !== undefined){
    for(let i = 0; i < districts.length; i++){
      districtOptions.push(districts[i]);
    }
  }
}


function closeModal() {
  file.value = new File([], "");
  newSiteSettings.value = new Site();
  previewImageFile.value = "";
  // newSiteData.value = new Site();

  emitData("update:siteModal", false);
}

function inputImage() {
  const picker = document.querySelector("[name='imagePicker']") as HTMLElement;
  picker?.click();
}

function previewConvert() {

  const fileConvert = file.value;
  // 이미지 파일 blob url 변환 후 저장
  fileConvert.arrayBuffer().then((arrayBuffer) => {
    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.value.type });
    const imageSeq = Math.floor(Math.random() * 10000 + Math.random() * 100);
    const times = new Date().toISOString();

    // newSiteData.value.imageFile = new ImageFile(file.value.name, imageSeq, file.value.type, times, 7, file.value.name);
    previewImageFile.value = URL.createObjectURL(blob);
  });

}

async function addSite() {
  let isEmpty = false;
  if(newSiteData.value.siteName === "" || newSiteData.value.siteName === null || newSiteData.value.siteName === undefined){
    isEmpty = true;
  }
 
  if(newSiteData.value.districtCode === "" || newSiteData.value.districtCode === null || newSiteData.value.districtCode === undefined){
    isEmpty = true;
  }

  if(newSiteData.value.siteClsfyCode === "" || newSiteData.value.siteClsfyCode === null || newSiteData.value.siteClsfyCode === undefined){
    isEmpty = true;
  }

  if(isEmpty === true){
    alert("빈칸이 존재합니다.");
    return;
  }


  newSiteData.value.siteSeq = Math.floor(Math.random() * 10000 + Math.random() * 100);
  newSiteSettings.value = newSiteData.value;
  await siteStore.addSite(newSiteSettings.value, file.value);
  emitData("update:siteModal", false);
  // emit("updateSite");
}

async function modifySite() {
  const isFiles = file.value;

  await siteStore.modifySite(newSiteData.value, isFiles);

  emitData("update:siteModal", false);
}

function calcArea(){
  newSiteData.value.siteArea = newSiteData.value.vertiSize * newSiteData.value.horizSize;
}
</script>

<template>
  <div>
    <q-dialog class="NewSiteModal" v-model="props.siteModal">
      <q-card class="NewSiteModalCard">
        <q-card-section class="row items-center NewSiteModalTopbar">
          <div class="text-h6">사이트 추가</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal"/>
        </q-card-section>

        <q-card-section class="NewSiteModalContents">
          <div class="NewSiteModalContentsRow row">
            <label class="col-2">명칭 :</label>
            <q-input class="col-10" outlined square hide-hint dense bg-color="white" :dark="false" v-model="newSiteData.siteName" />
          </div>
          <div class="NewSiteModalContentsRow row">
            <label class="col-2">설명 :</label>
            <q-input
              class="col-10 newSite"
              type="textarea"
              outlined
              square
              hide-hint
              bg-color="white"
              :dark="false"
              v-model="newSiteData.siteDesc"
              dense
            />
          </div>
          <div class="NewSiteModalContentsRow row">
            <label class="col-2">대표 이미지 :</label>
            <div class="col-10 row" :style="{ padding: '0px', display: 'flex' }">
              <q-file
                name="imagePicker"
                class="col-10"
                outlined
                square
                hide-hint
                :dark="false"
                bg-color="white"
                v-model="file"
                dense
                @update:model-value="previewConvert"
              />
              <div class="col-2" :style="{ paddingTop: '5px', paddingBottom: '5px' }">
                <q-btn class="fit" dense outline @click="inputImage">첨부</q-btn>
              </div>
            </div>
          </div>
          <div class="NewSiteModalContentsRow row">
            <label class="col-2">미리보기 :</label>
            <q-img
              v-if="previewImageFile || newSiteData.imageFileSeq !== null"
              class="col-6"
              :src="previewImageFile !== '' ? previewImageFile :`/v1/imagefiles/${newSiteData.imageFileSeq}`"
              :ratio="16 / 9"
              img-class="previewImg"
            >
            </q-img>
            <div v-else>

            </div>
            <!-- <q-input class="col-6" v-else outlined square hide-hint bg-color="white" :dark="false" v-model="test" dense input-class="NewSiteImage" /> -->
          </div>
          <div class="NewSiteModalContentsRow row">
            <label class="col-2">지역 :</label>
            <q-select class="col-8" outlined square hide-hint options-dense options-dark 
            bg-color="white" color="dark" :dark="false" v-model="newSiteData.districtCode" :options="districtOptions" dense emit-value map-options />
            <!-- <select name="district" class="col-3 districtSelect" onchange="newSiteData.districtCode = this.value">
              <option v-for="(data, i) in districtOptions" :key="`${i}`" :value="data.value">{{data.label}}</option>
            </select> -->
          </div>
          <div class="NewSiteModalContentsRow row">
            <label class="col-2">유형 :</label>
            <q-option-group
              class="row col-9 justify-between"
              :options="radioOptions"
              
              :dark="false"
              inline
              type="radio"
              v-model="newSiteData.siteClsfyCode"
            ></q-option-group>

            <!-- <q-input class="col-10" outlined square hide-hint :dark="false" v-model="test" dense /> -->
          </div>
          <div class="NewSiteModalContentsRow row">
            <div class="row col-4">
              <label class="col-6">가로 [m] :</label>
              <q-input type="number" class="col-6" outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData.horizSize" dense @change="calcArea"/>
            </div>
            <div class="row col-4">
              <label class="col-6">세로 [m]:</label>
              <q-input type="number" class="col-6" outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData.vertiSize" dense @change="calcArea"/>
            </div>
            <div class="row col-4">
              <label class="col-6">면적 [㎡] :</label>
              <q-input type="number" class="col-6" disable outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData.siteArea" dense/>
            </div>
            <!-- <div class="row col-4">
              <label class="col-6">높이 [m] :</label>
              <q-input class="col-6" outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData." dense />
            </div> -->
          </div>
          <div class="NewSiteModalContentsRow row">
            <div class="row col-4">
              <label class="col-6">위도 :</label>
              <q-input type="number" class="col-6" outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData.centerLat" dense />
            </div>
            <div class="row col-4">
              <label class="col-6">경도 :</label>
              <q-input type="number" class="col-6" outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData.centerLng" dense />
            </div>
            <!-- <div class="row col-4">
              <label class="col-6">방위각 :</label>
              <q-input class="col-6" outlined square hide-hint bg-color="white" :dark="false" v-model="newSiteData.azimuthAngle" dense />
            </div> -->
          </div>
        </q-card-section>

        <q-card-section class="row justify-end NewSiteModalBottomBar">
          <q-btn class="col-2 NewSiteCancel" unelevated @click="closeModal">취소</q-btn>
          <q-btn v-if="props.isModify === false" class="col-2 NewSiteAdd" unelevated @click="addSite">추가</q-btn>
          <q-btn v-else class="col-2 NewSiteAdd" unelevated @click="modifySite">수정</q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<style lang="scss">
.NewSiteModal {
  .NewSiteModalCard {
    width: 700px;
    max-width: 80vw;
    .NewSiteModalTopbar {
      background-color: #ffffff;
      color: #000000;
      border-bottom: 1px solid #e4e5e9;
    }
    .NewSiteModalBottomBar {
      background-color: #ffffff;
      color: #000000;
      border-top: 1px solid #e4e5e9;

      .NewSiteCancel {
        background-color: #f2f4fb;
        border: 1px solid #e9e9eb;
        color: black;
      }

      .NewSiteAdd {
        background-color: #0f1837;
        border: 1px solid #e9e9eb;
        color: white;
      }

      button {
        margin-left: 8px;
      }
    }

    .NewSiteModalContents {
      background-color: #f2f4fb;
      color: #000000 !important;
      .NewSiteModalContentsRow {
        .districtSelect{
          margin-left: 10px;
        }
        label {
          font-weight: bold;
          align-self: center;
          text-align: right;
          padding-top: 5px;
          padding-left: 5px;
          padding-bottom: 5px;
          margin-bottom: 0;
        }
        button {
          padding: 5px;
        }
      }

      .NewSiteImage {
        width: 200px;
        height: 200px;
      }

      .previewImg {
        padding: 5px;
    }
    }
  }
}
</style>

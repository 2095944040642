
import { HttpMethods } from "@/global/enums";

import BaseApiService from "./base-api-service";
import type RestClient from "@/net/rest-client";
import type Profile from "@/models/profile";
import type { RequestParameter } from "@/net/rest-client";


class UsageProfilesApiService extends BaseApiService{
  constructor(restClient: RestClient) {
    super(restClient);    
  }

  async getUsageProfiles(){
    const res = await this.restClient.call<Profile>(HttpMethods.Get, "/v1/usage-profiles");

    return res.data;
  }
  async setUsageProfiles(profile: Profile){
    const res = await this.restClient.call<Profile>(HttpMethods.Post, "/v1/usage-profiles", {body: profile});

    return res.data;
  }
  
  async removeUsageProfiles(usageProfileCode: string){
    const res = await this.restClient.call(HttpMethods.Delete, `/v1/usage-profiles/${usageProfileCode}`);

    return res.data;
  }

  async patchUsageProfiles(patchValue: Profile, usageProfileCode: string){
    const requestParameter : RequestParameter ={
      body: patchValue,
      headers: {"Content-type": "application/merge-patch+json"}
    };
    
    const res = await this.restClient.call(HttpMethods.Patch, `/v1/usage-profiles/${usageProfileCode}`, requestParameter);
    

    return res.data;
  }

}

export default UsageProfilesApiService;
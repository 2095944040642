<script setup lang="ts">
import { computed, ref } from "vue";

import RspaceRightComponent from "@/components/SpaceLight/RightInfoForm/RspaceRightComponent.vue";
import CspaceRightComponent from "@/components/SpaceLight/RightInfoForm/CspaceRightComponent.vue";
import UspaceRightComponent from "@/components/SpaceLight/RightInfoForm/UspaceRightComponent.vue";
import LightInfoComponent from "@/components/SpaceLight/RightInfoForm/LightInfoComponent.vue";
import GroupRightComponent from "@/components/SpaceLight/RightInfoForm/GroupRightComponent.vue";
import SceneRightComponent from "@/components/SpaceLight/RightInfoForm/SceneRightComponent.vue";
import { useEditStore } from "@/stores/edit-store";

import { useMenuStore } from "@/stores/menu-store";
import { Depth2_Kind } from "@/global/enums";
// import LightSetComponent from "./RightInfoForm/LightSetComponent.vue";

const editStore = useEditStore();
const menuStore = useMenuStore();
const isRightPageExpanded = ref(false);

const emitData = defineEmits(["expaned"]);

function RightPageExpandedToggle() {
  isRightPageExpanded.value = !isRightPageExpanded.value;
  emitData("expaned");
}

const selectZoneType = computed(() => {
  if(editStore.RightSpaceInfo === null){
    return editStore.selectSpaceInfo === null ? null : editStore.selectSpaceInfo.zoneTypeCode;
  } else {
    return editStore.RightSpaceInfo.zoneTypeCode;
  }
});

/*  */
</script>

<template>
  <q-scroll-area class="fit" :dark="false">
    <!-- {{ test }} -->
    <div v-if="isRightPageExpanded" class="column SpaceLightRightComponent">
      <q-btn square class="expansionIcon" :align="'left'" :unelevated="true" @click="RightPageExpandedToggle">
        <q-icon size="12px" :name="'img:ico_property_l.png'" />
      </q-btn>
      <div class="borderTemp"></div>
    </div>

    <div v-else class="column SpaceLightRightComponent">
      <q-btn square class="expansionIcon" :align="'left'" :unelevated="true" @click="RightPageExpandedToggle">
        <q-icon size="12px" :name="'img:ico_property_r.png'" />
      </q-btn>
      <div v-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.공간편집">
        <RspaceRightComponent v-if="selectZoneType === 'R'" :selectSpace="editStore.EditSpaceSelectType === 'inZone' ? editStore.RightSpaceInfo : editStore.selectSpaceInfo"> </RspaceRightComponent>
        <CspaceRightComponent v-else-if="selectZoneType === 'C'" :selectSpace="editStore.EditSpaceSelectType === 'inZone' ? editStore.RightSpaceInfo : editStore.selectSpaceInfo">
        </CspaceRightComponent>
        <UspaceRightComponent v-else-if="selectZoneType === 'U'" :selectSpace="editStore.EditSpaceSelectType === 'inZone' ? editStore.RightSpaceInfo : editStore.selectSpaceInfo">
        </UspaceRightComponent>

        <q-card-section v-else class="NoneContents">공간을 선택해주세요.</q-card-section>
      </div>

      <div v-else-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.조명배치">
        <LightInfoComponent></LightInfoComponent>
      </div>

      <div v-else-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.그룹">
        <GroupRightComponent></GroupRightComponent>
      </div>

      <div v-else-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.씬">
        <SceneRightComponent></SceneRightComponent>
      </div>

      <!-- <div v-else-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.조명셋">
        <LightSetComponent></LightSetComponent>
      </div> -->

      <div v-if="menuStore.selectedMenu?.menuKind === Depth2_Kind.공간편집 && editStore.selectSpaceInfo?.zoneTypeCode === 'R'">
        <!-- <WallRightComponent :wallInfo="editStore.SelectWallInfo"></WallRightComponent> -->
        <!-- <WallComponentRightComponent></WallComponentRightComponent>
        <ComponentRightComponent></ComponentRightComponent>
        <ComponentMaterialRight></ComponentMaterialRight>
        <MaterialRightComponent></MaterialRightComponent> -->
      </div>

      <!-- <div v-if="EditPageState === '조명'">
        <SiteRightComponent v-if="isEditorSelectInfo?.onType === 'site'" :selectSpace="isEditorSelectInfo"> </SiteRightComponent>
        <RspaceRightComponent v-else-if="isEditorSelectInfo?.onType === 'rspace'" :selectSpace="isEditorSelectInfo"> </RspaceRightComponent>
        <CspaceRightComponent v-else-if="isEditorSelectInfo?.onType === 'cspace'" :selectSpace="isEditorSelectInfo"> </CspaceRightComponent>
        <UspaceRightComponent v-else-if="isEditorSelectInfo?.onType === 'uspace'" :selectSpace="isEditorSelectInfo"> </UspaceRightComponent>

        <q-card-section v-else>공간을 선택해주세요.</q-card-section>
      </div> -->
    </div>
  </q-scroll-area>
</template>

<style lang="scss">
.SpaceLightRightComponent {
  background-color: #f2f4fb;
  color: black;

  .NoneContents{
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    background-color: #e2e6f3;
    padding: 15px;
    height: 50px;
  }
  .expansionIcon {
    height: 19px;
    min-height: 19px;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 0px;
    font-size: 8px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .borderTemp {
    height: 60px;
    min-height: 60px;
    border-radius: 0px;
  }
}
</style>

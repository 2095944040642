<script setup lang="ts">
import { ref, onMounted, type PropType, onUnmounted, computed } from "vue";

// import type Rspace from "@/models/rspace";

import { useDialogPluginComponent } from "quasar";
import { useEditStore } from "@/stores/edit-store";
import type Site from "@/models/site";
import type Zone from "@/models/entity/zone";
import type { alertMessageType, OptionsType, stringObject } from "@/global/types";
import { useUserStore } from "@/stores/user-store";
import SVGPathCommander from "svg-path-commander";
import CommonUtils from "@/utils/common-util";
import AlertWarningComponent from "../../AlertWarningComponent.vue";
import { v4 as uuidv4 } from "uuid";

import type LightSet from "@/models/entity/light-set";
import ZoneSmlTypeLight from "@/models/entity/zone-sml-type-light";
import { useCommonStore } from "@/stores/common-store";
import LightSetInfo from "@/models/entity/light-set-info";

const editStore = useEditStore();
const commonStore = useCommonStore();

const { dialogRef } = useDialogPluginComponent();

const props = defineProps({
  spaceModal: Boolean,
  selectSite: {} as PropType<Site>,
  selectRzone: {} as PropType<Zone>,
  type: String,
  rzoneOptionType: {} as PropType<OptionsType[]>,
  azimuthTypeCode: {} as PropType<OptionsType[]>,
  isModify: Boolean
  //   modelValue: Boolean,
});

const spaceModal = computed(() => props.spaceModal);

// const radioOptions = [
//   {
//     label: "건물",
//     value: "BUILDING",
//     color: "dark",
//   },
//   {
//     label: "외부",
//     value: "PARK",
//     color: "dark",
//   },
// ];


const isModify = ref(false);

const radioOptions = [] as OptionsType[];
const azimuthOptions = [] as OptionsType[];

const rspaceForm = computed(() => {
  return props.selectRzone as Zone;
});

const newRzoneTypeCode = ref("");
const newBuildingArea = ref(0);
const isAlert = ref(false);
const alertMessage = ref({} as alertMessageType);
const lightSetData = ref([] as LightSet[]);

const newLightInfoList = ref([] as ZoneSmlTypeLight[]);


onMounted(async () => {
  // radioOptions = setRadioOptions();
  console.log("NewRspaceModal Mounted");
  
  setRadioOptions();
  setAzimuthOptions();
  setRangeOfFloor();
});

onUnmounted(()=>{
  console.log("newspacemodal unmount");
});

function setRadioOptions(){
  // const grpCodeLists = commonStore.getGrpCodeList;
  const rzoneTypeCode = props.rzoneOptionType as OptionsType[];

  for(let i = 0; i < rzoneTypeCode.length; i++){
    radioOptions.push(rzoneTypeCode[i]);
  }
}

function setAzimuthOptions(){
  console.log("make districtselectoptions");
  const districts = props.azimuthTypeCode as OptionsType[];
  if(districts !== undefined){
    for(let i = 0; i < districts.length; i++){
      azimuthOptions.push(districts[i]);
    }
  }
}

const emitData = defineEmits(["update:spaceModal"]);

const overFloor = ref(0);
const underFloor = ref(0);

// const floorArray = ref([] as string[]);

// const rspaceForm = ref({
//   area: 0 as number,
//   azimuthCode: "" as string,
//   boundsPath: "M-35,-25L-35,25L35,25L35,-25Z" as string,
//   buildingArea: 0 as number,
//   height: 0 as number,
//   horizSize: 0 as number,
//   loc: "" as string,
//   modDate: new Date(),
//   modUserId: userStore.user?.userId,
//   modelFileSeq: null,
//   originX: 0 as number,
//   originY: 0 as number,
//   originZ: 0 as number,
//   planImageFileSeq: null,
//   rangeOfFloors: "" as string,
//   regDate: new Date(),
//   regUserId: userStore.user?.userId,
//   rzoneTypeCode: "" as string,
//   totalFloorArea: 0 as number,
//   vertiSize: 0 as number,
//   zoneName: "" as string,
// } as unknown as Zone);

function isClose() {
  emitData("update:spaceModal", false);
  // emit("updateSite");
}

function setRangeOfFloor(){
  const sendData = rspaceForm.value;

  console.log(sendData);
  if(underFloor.value === undefined || underFloor.value === null || Number(underFloor.value) < 0){
    underFloor.value = 0;
  }

  if(overFloor.value === undefined || overFloor.value === null || Number(overFloor.value) < 0){
    overFloor.value = 0;
  }

  sendData.rangeOfFloors = `B${underFloor.value}-F${overFloor.value}` as string;
  // console.log((sendData.rzone as RZone).rangeOfFloors);
}

function makeBoundPath(type: string, x: number, y: number, width: number, height: number){
  const attr = {
    type: type,
    x: x,
    y: y,
    width: width,
    height: height
  };

  const boundspath = SVGPathCommander.shapeToPath(attr);

  return boundspath.getAttribute("d");
}

async function rspaceInput() {
  let rangeOfFloors;

  if(newRzoneTypeCode.value !== "BUILDING"){
    rangeOfFloors = "B0-F0";
  } else {
    rangeOfFloors = rspaceForm.value.rangeOfFloors;
  }

  // if(newLightInfoList.value.length > 0){
  //   lightSetData.value.push(new LightSet(uuidv4(), "기본 조명셋", rspaceForm.value.horizSize, rspaceForm.value.vertiSize, 0, 0));
  //   lightSetData.value[0].lightList?.push(new LightSetInfo("기본 조명 정보"));
  //   lightSetData.value[0].lightList[0].lights.push(...newLightInfoList.value);    
  // }

  
  if(useUserStore().user === null){
    useUserStore().tokenLogin();
  }

  
  const sendData = {
    area: Number(rspaceForm.value.area),
    azimuthCode: rspaceForm.value.azimuthCode,
    boundsPath: "",
    buildingArea: Number(newBuildingArea.value),
    height: Number(rspaceForm.value.height),
    horizSize: Number(rspaceForm.value.horizSize),
    loc: rspaceForm.value.loc,
    modDate: new Date(),
    modUserId: useUserStore().user?.userId,
    originX: Number(rspaceForm.value.originX), 
    originY: Number(rspaceForm.value.originY),
    originZ: Number(rspaceForm.value.originZ),
    rangeOfFloors: rangeOfFloors,
    regDate: new Date(),
    regUserId: useUserStore().user?.userId,
    rzoneTypeCode: newRzoneTypeCode.value,
    totalFloorArea: rspaceForm.value.area * (overFloor.value + underFloor.value),
    vertiSize: Number(rspaceForm.value.vertiSize),
    zoneName: rspaceForm.value.zoneName,
    zoneSeq: Math.floor(Math.random() * 100 + 300),
  };

  sendData.boundsPath = makeBoundPath("rect", sendData.originX, sendData.originY, sendData.horizSize, sendData.vertiSize);


  const isEmptyAndKey = CommonUtils.isObjectValueEmpty(sendData as unknown as stringObject);

  if(isEmptyAndKey?.isEmpty === false){
    if(isModify.value === false){
      await editStore.addRzone(props.selectSite?.siteSeq as number, sendData as unknown as Zone, lightSetData.value);

    } else {

    }

    isClose();
  }

 
}

function floorSet(){
  if(props.isModify === true){
    overFloor.value = Number(props.selectRzone?.rangeOfFloors?.split("-")[1].split("F")[1]);
    if(isNaN(overFloor.value)) overFloor.value = 0;

    underFloor.value = Number(props.selectRzone?.rangeOfFloors?.split("-")[0].split("B")[1]);
    if(isNaN(underFloor.value)) underFloor.value = 0;

  }
}


// function abc(e: { target: { value: string; }[]; }){
//   console.log(e.target[0].value);

//   const value = e.target[0].value as string;

//   if(value.toUpperCase().includes("B") !== true || value.toUpperCase().includes("F") !== true){
//     alertMessage.value.message = "형식이 잘못되었습니다.";
//     isAlert.value = true;
//     return;
//   }

//   floorArray.value.map(v => {
//     const floors = v.split("-");

//     if(floors[0] === value){
//       alertMessage.value.message = "이미 추가된 층수입니다.";
//       isAlert.value = true;

//       return;
//     }

//   });
// }

function calcArea(){
  rspaceForm.value.area = rspaceForm.value.vertiSize * rspaceForm.value.horizSize;
}

function alertFalse(value: boolean){
  isAlert.value = value;
}


function getOptionCode(code: string){
  return commonStore.getOptionTypes(code);
}

function addlightInfo(){
  newLightInfoList.value.push(new ZoneSmlTypeLight());
}

function removelightInfo(idx: number){
  newLightInfoList.value.splice(idx, 1);
}
</script>


<template>
  <div>
    <AlertWarningComponent :confirm-value="false" :static-value="isAlert" :message="alertMessage" @change="alertFalse"></AlertWarningComponent>
    <q-dialog class="NewRspaceModal" ref="dialogRef" v-model="spaceModal" @before-show="floorSet">
      <q-card class="NewRspaceModalCard">
        <q-card-section class="row items-center NewRspaceModalTopbar">
          <div class="text-h6">대표공간 추가</div>
        </q-card-section>

        <q-card-section class="NewRspaceModalContents">
          <div class="NewRspaceModalContentsRow row">
            <label class="col-2" for="">명칭 : </label>
            <q-input class="col-9" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.zoneName" />
          </div>
        
          <div class="NewRspaceModalContentsRow row">
            <label class="col-2" for="">유형 :</label>
            <q-option-group
              class="row col-9 justify-between"
              :options="radioOptions"              
              :dark="false"
              inline
              type="radio"
              v-model="newRzoneTypeCode"
            
            >
            </q-option-group>
          </div>
          <div class="NewRspaceModalContentsRow row">
            <label class="col-2" for="">주소 : </label>
            <q-input class="col-9" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.loc" />
          </div>
          <div class="NewRspaceModalContentsRow row">
            <label class="col-2">방위 :</label>
            <!-- <q-select class="col-8" outlined square hide-hint options-dense options-dark bg-color="white" color="dark" :dark="false" v-model="newSiteData.districtCode" :options="districtOptions" dense emit-value
                map-options /> -->
              <!-- <q-select
                dense
                square
                bg-color="white"
                color="dark"
                :dark="false"
                outlined
                :options="azimuthOptions"
                class="col-3 azimuth"
                v-model="rspaceForm.azimuthCode"
              >

              </q-select> -->

            <select name="azimuth" class="col-3 azimuth" v-model="rspaceForm.azimuthCode" :style="{
              fontWeight: 'bold',
              alignSelf: 'center',
              paddingTop: '5px',
              paddingLeft: '5px',
              paddingBottom: '5px',
              marginLeft: '5px',
              marginBottom: '0',
              }
            ">
              <option v-for="(data, i) in azimuthOptions" :key="`${i}`" :value="data.value">{{data.label}}</option>
            </select>
          </div>
        

          <div v-if="newRzoneTypeCode === 'BUILDING'">
            <!-- <q-form class="NewRspaceModalContentsRow row" @submit="abc">
              <label class="col-2" for="">층 추가 : </label>
              <div class="col-9">
                <q-input class="col-12" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.zoneName" />

                <div class="col-12 row">
                  <label class="col-2">추가된 층 : </label>
                  <q-chip v-for="(data, i) in floorArray" :key="`${i}`" removable :label="data"><q-tooltip>{{data}}</q-tooltip></q-chip>
                </div>
              </div>
            </q-form> -->

            <div class="boxArea">
              <div class="subjectArea">층 입력</div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row">
                  <label for="">지상</label>
                  <q-input type="number" :min="0" outlined square hide-hint bg-color="white" :dark="false" dense v-model="overFloor" @change="setRangeOfFloor"></q-input>
                  <label for="">층</label>
                </div>
                <div class="row">
                  <label for="">지하</label>
                  <q-input type="number" :min="0" outlined square hide-hint bg-color="white" :dark="false" dense v-model="underFloor" @change="setRangeOfFloor"></q-input>
                  <label for="">층</label>
                </div>
              </div>
            </div>
            <div class="boxArea">
              <div class="subjectArea">기준점</div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-4">
                  <label class="col-2" for="">X :</label>
                  <q-input class="col-8" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.originX" />
                </div>
                <div class="row col-4">
                  <label class="col-2" for="">Y :</label>
                  <q-input class="col-8" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.originY" />
                </div>
                <div class="row col-4">
                  <label class="col-2" for="">Z :</label>
                  <q-input class="col-8" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.originZ" />
                </div>
              </div>
            </div>

            <div class="boxArea">
              <div class="subjectArea">크기</div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-6">
                  <label class="col-4" for="">가로 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.horizSize" @change="calcArea"/>
                </div>
                <div class="row col-6">
                  <label class="col-4" for="">세로 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.vertiSize" @change="calcArea"/>
                </div>
              </div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-6">
                  <label class="col-4" for="">높이 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.height" />
                </div>
                <div class="row col-6">
                  <label class="col-4" for="">면적 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.area" disable />
                </div>
              </div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-6">
                  <label class="col-4" for="">건축 면적 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="newBuildingArea" />
                </div>
              </div>
            </div>

            <!-- <div class="boxArea">
              <div class="subjectArea">
                기본조명 정보
                <q-btn square color="primary" icon="add" @click="addlightInfo"
                  :style="{fontSize: '10px'}"
                />
              </div>

              
              <div v-if="newLightInfoList.length > 0" class="row" v-for="(lightInfo, i) in newLightInfoList" :key="`defaultLightInfo${i}`" :style="{alignItems: 'center'}">
                <div class="row col-11">
                  <div class="col-6 q-py-xs q-px-md">
                    <label>조명 방식</label>
                    <q-select v-model="lightInfo.lightModeCode" :options="getOptionCode('lightModeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
                  </div>
                  <div class="col-6 q-py-xs q-px-md">
                    <label>조명 종류</label>
                    <q-select v-model="lightInfo.lightTypeCode" :options="getOptionCode('lightTypeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
                  </div>
                  
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>소비 전력[W]</label>
                    <q-input type="number" v-model="lightInfo.consumpPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>대기 전력[W]</label>
                    <q-input type="number" v-model="lightInfo.standbyPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>조명 개수</label>
                    <q-input type="number" v-model="lightInfo.lightQty" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>조명 비율[%]</label>
                    <q-input type="number" v-model="lightInfo.lightRate" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                </div>
        
                <div class="col-1">
                   <q-btn square color="red" icon="remove" @click="removelightInfo(i)"
                  :style="{fontSize: '10px'}" />
                </div>
              </div>
              <div v-else :style="{padding: '20px'}">
                등록된 조명 정보가 없습니다.
              </div>

       
            </div> -->
          </div>
          <div v-else>
            <div class="boxArea">
              <div class="subjectArea">기준점</div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-4">
                  <label class="col-2" for="">X :</label>
                  <q-input class="col-8" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.originX" />
                </div>
                <div class="row col-4">
                  <label class="col-2" for="">Y :</label>
                  <q-input class="col-8" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.originY" />
                </div>
                <div class="row col-4">
                  <label class="col-2" for="">Z :</label>
                  <q-input class="col-8" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.originZ" />
                </div>
              </div>
            </div>

            <div class="boxArea">
              <div class="subjectArea">크기</div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-6">
                  <label class="col-4" for="">가로 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.horizSize" @change="calcArea"/>
                </div>
                <div class="row col-6">
                  <label class="col-4" for="">세로 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.vertiSize" @change="calcArea"/>
                </div>
              </div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-6">
                  <label class="col-4" for="">높이 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.height" />
                </div>
                <div class="row col-6">
                  <label class="col-4" for="">면적 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="rspaceForm.area" disable/>
                </div>
              </div>
              <div class="NewRspaceModalContentsRow row">
                <div class="row col-6">
                  <label class="col-4" for="">건축 면적 :</label>
                  <q-input class="col-6" type="number" square hide-hint bg-color="white" :dark="false" outlined dense v-model="newBuildingArea" />
                </div>
              </div>
            </div>
<!-- 
            <div class="boxArea">
              <div class="subjectArea">
                기본조명 정보
                <q-btn square color="primary" icon="add" @click="addlightInfo"
                  :style="{fontSize: '10px'}"
                />
              </div>

              
              <div v-if="newLightInfoList.length > 0" class="row" v-for="(lightInfo, i) in newLightInfoList" :key="`defaultLightInfo${i}`" :style="{alignItems: 'center'}">
                <div class="row col-11">
                  <div class="col-6 q-py-xs q-px-md">
                    <label>조명 방식</label>
                    <q-select v-model="lightInfo.lightModeCode" :options="getOptionCode('lightModeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
                  </div>
                  <div class="col-6 q-py-xs q-px-md">
                    <label>조명 종류</label>
                    <q-select v-model="lightInfo.lightTypeCode" :options="getOptionCode('lightTypeCode')" dense square hide-bottom-space outlined :dark="false" bg-color="white" label-color="black" :popup-content-style="{color: '#000000'}" map-options emit-value></q-select>
                  </div>
                  
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>소비 전력[W]</label>
                    <q-input type="number" v-model="lightInfo.consumpPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>대기 전력[W]</label>
                    <q-input type="number" v-model="lightInfo.standbyPower" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>조명 개수</label>
                    <q-input type="number" v-model="lightInfo.lightQty" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                  <div class="col-md-12 col-lg-3 q-py-xs q-px-md">
                    <label>조명 비율[%]</label>
                    <q-input type="number" v-model="lightInfo.lightRate" dense square hide-bottom-space outlined :dark="false" bg-color="white"></q-input>
                  </div>
                </div>
        
                <div class="col-1">
                   <q-btn square color="red" icon="remove" @click="removelightInfo(i)"
                  :style="{fontSize: '10px'}" />
                </div>
              </div>
              <div v-else :style="{padding: '20px'}">
                등록된 조명 정보가 없습니다.
              </div>

       
            </div> -->
          </div>
        </q-card-section>

        <q-card-actions class="row justify-end NewRspaceModalBottomBar" align="right">
          <q-btn class="NewRspaceCancel col-2" unelevated @click="isClose">취소</q-btn>

          <q-btn class="NewRspaceAdd col-2" unelevated @click="rspaceInput">추가</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style lang="scss">
.NewRspaceModal {
  


  .NewRspaceModalCard {
    .boxArea {
      border: 1px solid #000000;
      margin: 20px 10px;
      padding: 15px;
      .NewRspaceModalContentsRow {
        justify-content: flex-start;
      }
      .subjectArea {
        font-weight: bold;
        position: absolute;
        text-align: center;
        top: -15px;
        left: 50px;
        padding: 5px 15px;
        background-color: #f2f4fb;
      }
    }

    width: 700px;
    max-width: 80vw;
    .NewRspaceModalTopbar {
      background-color: #ffffff;
      color: #000000;
      border-bottom: 1px solid #e4e5e9;
    }

    
    .NewRspaceModalBottomBar {
      background-color: #ffffff;
      color: #000000;
      border-top: 1px solid #e4e5e9;

      .NewRspaceCancel {
        background-color: #f2f4fb;
        border: 1px solid #e9e9eb;
        color: black;
      }

      .NewRspaceAdd {
        background-color: #0f1837;
        border: 1px solid #e9e9eb;
        color: white;
      }

      button {
        margin-left: 8px;
      }
    }

    .NewRspaceModalContents {
      background-color: #f2f4fb;
      color: #000000 !important;
      label {
        margin-right: 10px;
      }

      .NewRspaceModalContentsRow {
        label {
          font-weight: bold;
          align-self: center;
          text-align: right;
          padding-top: 5px;
          padding-left: 5px;
          padding-bottom: 5px;
          margin-bottom: 0;
        }
        button {
          padding: 5px;
        }
      }
    }
  }
  .NewRspaceModalBody {
    .row {
      align-items: center;

      label {
        margin-bottom: 10px;
        align-self: center;
      }
    }
  }
}
</style>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import { useMaterialsStore } from "@/stores/materials-store";
import type { QTableProps } from "quasar";
import ProfileFormComponent from "./ProfileFormComponent.vue";
import ProfileHeaderComponent from "./Profile/ProfileHeaderComponent.vue";
import Profile from "@/models/profile";
import CommonUtils from "@/utils/common-util";


const materialStore = useMaterialsStore();

const profileList = computed(() => {
  return materialStore.profileList as Profile[];
});

const originProfileData = computed(() => {
  return materialStore.originProfileList as Profile[];
});


const isSelect = ref(false);

const isSelectProfile = ref();
const isSelectRowIndex = ref();

const profiles = computed(() => {
  return profileList.value;
});

const newProfileData = ref(null as null | Profile);
const isNewProfile = ref(false);

// function test(ev: Event, row: object, index: number){
//   console.log(ev, row);
//   if(isSelectRowIndex.value === index){
//     isSelect.value = false;
//     isSelectRowIndex.value = null;
//   } else {
//     isSelectProfile.value = row;
//     isSelect.value = true;
//     isSelectRowIndex.value = index;
//   }

// }

const column: QTableProps["columns"] = [
  {name: "usageProfileCode", label: "식별", field: "usageProfileCode", align: "left", required: false},
  {name: "usageProfileName", label: "명칭", field: "usageProfileName", align: "left", required: true},
  {name: "useStartTime", label: "시작시간", field: "useStartTime", align: "center"},
  {name: "useEndTime", label: "종료시간", field: "useEndTime", align: "center"},
  {name: "dailyUsageTime", label: "하루 사용시간", field: "dailyUsageTime", align: "center"},
  {name: "workSurfaceHeight", label: "작업면 높이", field: "workSurfaceHeight", align: "center"},
  {name: "vertifaceLightCoeff", label: "수직면 기준순응 계수", field: "vertifaceLightCoeff", align: "center"},
  {name: "annualUseDays", label: "연간사용일수", field: "annualUseDays", align: "center"},
  {name: "annualUseDayHours", label: "연간 낮 사용시간", field: "annualUseDayHours", align: "center"},
  {name: "annualUseNightHours", label: "연간 밤 사용시간", field: "annualUseNightHours", align: "center"},
  {name: "indoorIndex", label: "실내 인덱스", field: "indoorIndex", align: "center"},
  {name: "lightReductCoeff", label: "조명 감소 계수", field: "lightReductCoeff", align: "center"},
  {name: "lightRelativeAbsenceRate", label: "조명 상대적 부재율", field: "lightRelativeAbsenceRate", align: "center"},
  {name: "lightOperReductCoeff", label: "조명 운전 감소 계수", field: "lightOperReductCoeff", align: "center"},
  //   {name: "usageType", label: "용도 종류", field: "usageType", align: "center"},
  {name: "lightingHours", label: "조명 점등시간", field: "lightingHours", align: "center"},
];

const visibleColumnName = ["usageProfileName", "useStartTime", "useEndTime", "dailyUsageTime", "annualUseDays" ];

const newProfileList = ref([] as Profile[]);

async function onSave(){

  try{
    await materialStore.addProfile(newProfileData.value as Profile);

  } catch {
    alert("생성 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.");
    return;
  }

  alert("추가가 완료되었습니다.");

  await materialStore.getUsageProfileApi();

  allReset();
}

function onAdd(){
  const newProfile = new Profile();
  newProfile.usageProfileName = "신규";
  // newProfile.displayLabel = "신규";

  const lastCodeNum = profiles.value.length === 0 ? 0 : Number((profiles.value[profiles.value.length - 1].usageProfileCode)?.toString()?.split("A")[1]);

  if(lastCodeNum + 1 > 99){
    newProfile.usageProfileCode = `A${lastCodeNum + 1}`;
  } else if(lastCodeNum + 1 > 9){
    newProfile.usageProfileCode = `A0${lastCodeNum + 1}`;
  } else {
    newProfile.usageProfileCode = `A00${lastCodeNum + 1}`;
  }


  newProfileData.value = newProfile;

  isSelectProfile.value = newProfileData.value;

  isNewProfile.value = true;

  // newProfileList.value.push(newProfile);

  // isSelectProfile.value = newProfile;
}

function resetNew(){
  newProfileData.value = null;
  isNewProfile.value = false;
}

function allReset(){
  isNewProfile.value = false;
  newProfileData.value = null;
  isSelectProfile.value = undefined;
}

async function onModify(profileData: Profile){

  // const changeValue = [] as Profile[];

  // for(let i = 0; i < profileList.value.length; i++){
  //   const profileData = profileList.value[i];
  //   const originData = originProfileData.value.find(v => v.usageProfileCode === profileData.usageProfileCode);

  //   const profileKeys = Object.keys(profileData);
    
  //   for(let j = 0; j < profileKeys.length; j++){
  //     const key = profileKeys[j];
  //     if(originData[key] !== profileData[key]){
  //       changeValue.push(profileData);
  //       break;
  //     }
  //   }
  // }

  // changeValue.push(...newProfileList.value);


  try{
    // delete isSelectProfile.value.displayLabel;

    await materialStore.modifyProfile(profileData);
  } catch(e){
    alert("수정 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.");
    return;
  }

  await materialStore.getUsageProfileApi();
  // isSelectProfile.value = profileList.value.find(v => v.usageProfileCode === isSelectProfile.value.usageProfileCode);

  alert("수정이 완료되었습니다.");


}

async function onRemove(usageProfileCode: string){


  try{
    await materialStore.removeProfile(usageProfileCode);
  } catch {
    alert("삭제 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.");
    return;
  }

  isSelectProfile.value = {};
  await materialStore.getUsageProfileApi();
  
}
</script>

<template>
    <div class="ProfileComponent">
      <ProfileHeaderComponent @onSave="onSave" @onAdd="onAdd"></ProfileHeaderComponent>
      <div class="headerLabel">
        <label>용도 프로필</label>
      </div>

      <q-select 
        v-model="isSelectProfile" 
        :options="profiles"
        dense 
        square
        hide-bottom-space 
        outlined 
        :dark="false" 
        bg-color="white" 
        option-label="usageProfileName"
        label-color="black" 
        :popup-content-style="{color: '#000000'}" 
        map-options
        @update:model-value="resetNew"
        >
      </q-select>

        <!-- <q-table
            class="col-4 profileListTable"
            :rows="profileList"
            :columns="column"
            :visible-columns="visibleColumnName"
            row-key="usageProfileCode"
            :pagination="{rowsPerPage: 0, rowsNumber: 0}" 
            :rows-per-page-options="[0]" 
            :dark="false" 
            square 
            dense
            bordered
            hide-pagination
            @row-click="test"
        >

        </q-table> -->

        <ProfileFormComponent 
          :is-select="isSelect" 
          :profile-data="isSelectProfile"
          :profileList="profileList" 
          :isNew="isNewProfile"
          @save="onSave"
          @modify="onModify"
          @reset="allReset"
          @delete="onRemove"
        ></ProfileFormComponent>
    </div>
</template>

<style lang="scss">
.ProfileComponent{
  background-color: #e2e6f3;
  width: 100%;
  height: calc(100vh - 54px);
  .headerLabel{
    background-color: #f2f4fb;
    color: #404040;
    border-bottom: 1px solid #c1c1c1;
    width: 100%;
    height: 40px;
    padding: 10px 15px 10px 15px;
    label{
        font-size: 15px;
        font-weight: bold;
    }
  }
  .profileListTable{
    
  }
}
</style>